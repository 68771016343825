/* eslint-disable jsx-a11y/no-redundant-roles */
import { useEffect, useState } from "react";
import { AgencyInterface } from "../../../../interfaces/Agency.interface";
import { UserCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  agencyCollectionsName,
  generatedCodeAgentCollectionsName,
  usersCollectionName,
} from "../../../../config";
import { UseFormReturn } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useConfirm } from "../../../../hooks/useConfirm";
import { classNames, createStringGenerator } from "../../../../utils";
import { Form, FormInput } from "../../../../components/FormComponents";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { db, functions } from "../../../../firebase";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Country } from "country-state-city";

type AgencyInfos = Pick<
  AgencyInterface,
  | "displayName"
  | "adresse"
  | "email"
  | "agentCode"
  | "phoneNumber"
  | "identificationCode"
  | "lat"
  | "long"
  | "createdAt"
  | "updatedAt"
>;
type Step = {
  id: "agencyInfos";
  index: number;
  name: string;
  description: string;
  icon: any;
};

const steps: Step[] = [
  {
    id: "agencyInfos",
    index: 0,
    name: "Informations de la l'agence",
    description: "Renseigner les informations de l'agence",
    icon: UserCircleIcon,
  },
];
const collectionName = agencyCollectionsName;

const EditAgencyPage = () => {
  const navigate = useNavigate();
  const { isConfirmModal } = useConfirm();
  const [currentFormStep, setCurrentFormStep] = useState<Step>(steps[0]);
  const location = useLocation();
  const currentAgency = location.state?.entity;
  const [agencyInfosFormReturn, setAgencyInfosFormReturn] =
    useState<UseFormReturn<AgencyInfos>>();
  const [agencyInfosFormSubmitButton, setAgencyInfosFormSubmitButton] =
    useState<HTMLButtonElement | null>(null);
  const [agencyPhoneNumber, setAgencyPhoneNumber] = useState("");
  const [agentCode, setAgentCode] = useState<string>();
  const [country, setCountry] = useState<any>({
    currency: "XOF",
    flag: "🇧🇫",
    isoCode: "BF",
    latitude: "13.00000000",
    longitude: "-2.00000000",
    name: "Burkina Faso",
    phonecode: "226",
  });

  const [phoneNumberIsValid, setPhoneNumberIsValid] = useState<boolean>(false);

  const onSelectedStepChanged: any = async (step: Step) => {
    let canSwitchTab = true;
    switch (currentFormStep.index) {
      case 0:
        if (agencyInfosFormReturn?.formState.isValid) {
          if (agencyInfosFormReturn.formState.isDirty) {
            const isConfirm = await isConfirmModal(
              "Vous avez des modifications non enregistrées.",
              "Voulez vous les sauvegarder avant de quitter cet écran?",
              "Sauvegarder mes modifications",
              false,
              false,
              "Annuler" as any
            );
            if (isConfirm) {
              await agencyInfosFormReturn.trigger();
              agencyInfosFormSubmitButton &&
                agencyInfosFormSubmitButton.click();
            }
          }
        } else {
          canSwitchTab = false;
          agencyInfosFormReturn?.trigger();
        }
        break;
      default:
        break;
    }
    if (canSwitchTab) {
      setCurrentFormStep(step);
    }
  };

  const sankMoneyCreateAgencyFn = functions.httpsCallable(
    "sankMoneyCreateAgencyFn"
  );
  const sankmoneyUpdateAccountFn = functions.httpsCallable(
    "sankmoneyUpdateAccountFn"
  );

  const onSubmitAgency = async (data: any) => {
    if (currentAgency !== undefined) {
      const response = sankmoneyUpdateAccountFn({
        collectionName: collectionName,
        documentData: {
          id: currentAgency.id,
          phoneNumber: agencyPhoneNumber && agencyPhoneNumber,
          ...data,
        },
        documentId: currentAgency.id,
      })
        .then(() => {
          navigate("/agencies");
        })
        .catch((err: { details: any; message: any }) =>
          toast.error(err.details.message)
        );
      toast.promise(response, {
        error: "Une erreur s'est produite. Veuillez réessayer !",
        success: "Les informations de l'agence ont été mise à jour avec succès",
        loading: "chargement...",
      });
    } else {
      const agencySanp = await db.collection(agencyCollectionsName).get();
      const identificationCode = createStringGenerator(agencySanp.size);
      const agency = agencySanp.docs.find(
        (value) => value.data().identificationCode === identificationCode
      );
      if (!agency?.exists) {
        const agencyData = {
          adresse: data.adresse,
          city: data.city,
          email: data.email,
          lat: data.lat,
          long: data.long,
          agentCode: data.agentCode,
          identificationCode: identificationCode,
          phoneNumber: agencyPhoneNumber ?? "",
          displayName: data.displayName,
        };
        await sankMoneyCreateAgencyFn({
          documentData: {
            ...agencyData,
          },
        })
          .then(() => {
            navigate("/agencies");
          })
          .catch((err: { details: any; message: any }) =>
            toast.error(err.details.message)
          );
      }
    }
  };

  const retrivePhoneNumber = async (phoneNumber: any) => {
    if (phoneNumber) {
      setAgencyPhoneNumber(phoneNumber);
    }
  };

  const handleChange = async (e: any) => {
    const generateAgentCodeRef = db.collection(
      generatedCodeAgentCollectionsName
    );
    const agentCode = e.target.value;
    if (agentCode.length === 6) {
      const generatedagentCodeSnap = await generateAgentCodeRef
        .where("isAffected", "==", false)
        .where("code", "==", agentCode)
        .get();
      if (!generatedagentCodeSnap.empty) {
        toast.success("Le code agent que vous avez entré est correct");
        return setAgentCode(generatedagentCodeSnap.docs[0].id);
      } else {
        toast.error("Le code agent que vous avez entré est indisponible");
      }
    }
  };

  useEffect(() => {
    currentAgency && setCurrentFormStep(steps[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (isValidPhoneNumber(agencyPhoneNumber)) {
        const sankAccounts = await db
          .collection(usersCollectionName)
          .where("phoneNumber", "==", agencyPhoneNumber)
          .get();

        if (sankAccounts.empty) {
          toast.success("Le numéros de téléphone est valide.");
          setPhoneNumberIsValid(true);
        } else {
          toast.error("Le numéros de téléphone renseigner est déja utiliser.");
          setPhoneNumberIsValid(false);
        }
      }
    })();
  }, [agencyPhoneNumber]);

  return (
    <>
      {" "}
      <div className="lg:border-t lg:border-b lg:border-gray-200">
        <nav
          className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8"
          aria-label="Progress"
        >
          <ol
            role="list"
            className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
          >
            <li className="relative px-2 py-4 overflow-hidden lg:py-2">
              <button
                className="p-1 border border-transparent rounded-full hover:border-gray-200 hover:bg-gray-50"
                onClick={() => navigate("/agencies")}
              >
                <XMarkIcon className="w-8 h-8 text-gray-700" />
              </button>
            </li>
            {steps.map((step, stepIdx) => (
              <li key={stepIdx} className="relative overflow-hidden lg:flex-1">
                <div
                  className={classNames(
                    stepIdx === 0 ? "border-b-0 rounded-t-md" : "",
                    stepIdx === steps.length - 1
                      ? "border-t-0 rounded-b-md"
                      : "",
                    "border border-gray-200 overflow-hidden lg:border-0 h-full"
                  )}
                >
                  {
                    //TODO remove comment on check current user
                  }
                  <button
                    onClick={() =>
                      "currentAgency" ? onSelectedStepChanged(step) : void 0
                    }
                    className={classNames(
                      !"currentAgency" ? "cursor-not-allowed" : "",
                      "currentAgency" && step.index === 2 ? "" : "",
                      "w-full group h-full"
                    )}
                  >
                    <span
                      className={classNames(
                        step.index !== currentFormStep.index
                          ? "group-hover:bg-gray-200 bg-transparent "
                          : "bg-red-600",
                        "absolute top-0 left-0 w-1 h-full  lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? "lg:pl-9" : "",
                        "px-4 py-4 lg:py-2 flex items-start text-sm font-medium"
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span
                          className={classNames(
                            step.index < currentFormStep.index
                              ? "bg-red-600"
                              : "",
                            step.index === currentFormStep.index
                              ? "bg-white border-2 border-red-600"
                              : "",
                            step.index > currentFormStep.index
                              ? "bg-white border-2 border-gray-300"
                              : "",
                            "flex items-center justify-center w-10 h-10  rounded-full"
                          )}
                        >
                          <step.icon
                            className={classNames(
                              step.index < currentFormStep.index
                                ? "text-white"
                                : "",
                              step.index === currentFormStep.index
                                ? "text-red-600"
                                : "",
                              step.index > currentFormStep.index
                                ? "text-gray-500"
                                : "",
                              "w-6 h-6 "
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex-col flex  xl:flex">
                        <span
                          className={classNames(
                            step.index === currentFormStep.index
                              ? "text-red-600"
                              : "",
                            step.index > currentFormStep.index
                              ? "text-gray-500"
                              : "",
                            "text-xs font-semibold tracking-wide uppercase md:block lg:hidden xl:block"
                          )}
                        >
                          {step.name}
                        </span>
                        <span className="flex items-start text-sm font-medium text-gray-500">
                          {step.description}
                        </span>
                      </span>
                    </span>
                  </button>
                  <div
                    className="absolute inset-0 top-0 left-0 hidden w-3 lg:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="w-full h-full text-gray-300"
                      viewBox="0 0 12 82"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0.5 0V31L10.5 41L0.5 51V82"
                        stroke="currentcolor"
                        vectorEffect="non-scaling-stroke"
                      />
                    </svg>
                  </div>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <div className="items-center w-full md:w-11/12 lg:w-9/12 mx-auto">
        {currentFormStep.id === "agencyInfos" && (
          <Form<AgencyInfos>
            onSubmit={onSubmitAgency}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  displayName: yup
                    .string()
                    .required("Le champs est obligatoire"),
                  email: yup
                    .string()
                    .email()
                    .required("Le champs est obligatoire"),
                  city: yup.string().optional(),
                  lat: yup.string().required("Le champs est obligatoire"),
                  long: yup.string().required("Le champs est obligatoire"),
                  adresse: yup.string().required("Le champs est obligatoire"),
                  agentCode: yup.string().required("Le champ est obligatoire"),
                })
              ),
              defaultValues: {
                adresse: currentAgency?.adresse ?? "",
                email: currentAgency?.email ?? "",
                lat: currentAgency?.lat ?? "",
                long: currentAgency?.long ?? "",
                displayName: currentAgency?.displayName ?? "",
                phoneNumber: currentAgency?.phoneNumber ?? "",
                agentCode: currentAgency?.agentCode ?? "",
              },
            }}
            submitButtonLabel={`${
              currentAgency ? "Editer l'" : "Ajouter l'"
            }agence`}
            isSubmitBtnDisabled={
              phoneNumberIsValid && !currentAgency ? false : true
            }
          >
            <div className="flex align-top flex-col px-5 mt-5 md:flex-row content-center w-full">
              <div className="w-full mx-auto ">
                <FormInput
                  name="displayName"
                  label="Nom"
                  placeholder="Exemple: Agence 1"
                />
                <PhoneInput
                  international={false}
                  placeholder="Entrer un numéro de téléphone"
                  className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                  defaultCountry="BF"
                  value={
                    currentAgency
                      ? currentAgency?.phoneNumber
                      : agencyPhoneNumber
                      ? agencyPhoneNumber
                      : ""
                  }
                  onChange={retrivePhoneNumber}
                  onCountryChange={(value: any) => {
                    const country = Country.getCountryByCode(value);
                    setCountry(country);
                  }}
                />
                <FormInput
                  name="email"
                  label="E-mail"
                  placeholder="@exemple.com"
                />
                <FormInput
                  name="city"
                  label="Ma ville"
                  placeholder="Exemple: Ouagadougou"
                />
                <FormInput
                  name="adresse"
                  label="Adresse"
                  placeholder="Exemple: Avenue Kwame N'Kruma"
                />
                <FormInput
                  name="agentCode"
                  label="Saisir le code de l'agent"
                  placeholder="Ex: 123456"
                  onChange={handleChange}
                  helpLabel={`${
                    agentCode
                      ? ""
                      : "Veuillez entrer un code agent qui est disponible"
                  }`}
                  maxLength={6}
                />
                <FormInput
                  name="lat"
                  label="Latitude"
                  placeholder="Exemple: 02.012563"
                />
                <FormInput
                  name="long"
                  label="Longitude"
                  placeholder="Exemple: 12.002035"
                />
              </div>
            </div>
          </Form>
        )}
      </div>
    </>
  );
};

export default EditAgencyPage;
