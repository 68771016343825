import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { Fragment, InputHTMLAttributes, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { classNames } from '../../../utils';
import { HelpTooltip } from '../HelpTooltip';

export interface SelectProps<T>
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'id' | 'className'> {
  name: string;
  label?: string;
  tooltip?: string;
  options: T[];
  helpLabel?: string;
  condensed?: boolean;
  optionLabel?: (option: T) => string;
  optionValue?: (option: T) => unknown;
  valueToOption?: (value: any) => T;
  selectedOption: T;
}

export function FormSelect<T>({
  name,
  label,
  tooltip,
  options,
  helpLabel,
  condensed = false,
  optionLabel = (option) => `${option}`,
  optionValue = (option) => option,
  valueToOption = (value) => value,
  selectedOption,
  ...rest
}: SelectProps<T>): JSX.Element {
  const methods = useFormContext();
  const [selected, setSelected] = useState<T>(selectedOption);
  return (
    <div className={classNames(condensed ? 'my-1' : 'my-1')}>
      <Controller
        control={methods.control}
        defaultValue={selectedOption}
        name={name}
        render={({ field }) => (
          <Listbox
            value={selected}
            onChange={(e) => {
              field.onChange(optionValue(e));
              setSelected(e);
            }}
          >
            {({ open }) => (
              <>
                {label && (
                  <Listbox.Label className="flex justify-between text-sm font-medium text-gray-700">
                    <span
                      className={classNames(
                        rest.required ? 'is-required' : '',
                        'block',
                      )}
                    >
                      {label}
                    </span>
                    {tooltip && <HelpTooltip value={tooltip} place='left'/>}
                  </Listbox.Label>
                )}
                <div className="relative mt-1">
                  <Listbox.Button
                    className={classNames(
                      (methods as any).formState.errors[name]
                        ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                        : 'focus:ring-indigo-500 focus:border-indigo-500',
                      'bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 sm:text-sm',
                    )}
                  >
                    <span className="block truncate">
                      {optionLabel(selected)}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <ChevronDownIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                    {(methods as any).formState.errors[name] && (
                      <div className="absolute inset-y-0 flex items-center pr-1 pointer-events-none right-6">
                        <ExclamationCircleIcon
                          className="w-5 h-5 text-red-500"
                          aria-hidden="true"
                        />
                      </div>
                    )}
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {options.map((option) => (
                        <Listbox.Option
                          key={optionLabel(option)}
                          className={({ active }) =>
                            classNames(
                              active
                                ? 'text-white bg-indigo-600'
                                : 'text-gray-900',
                              'cursor-default select-none relative py-2 pl-3 pr-9',
                            )
                          }
                          value={option}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block truncate',
                                )}
                              >
                                {optionLabel(option)}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                  )}
                                >
                                  <CheckIcon
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        )}
      />
      {helpLabel && <p className="mt-2 text-sm text-gray-500">{helpLabel}</p>}
      {(methods as any).formState.errors[name] && (
        <p className="mt-2 text-sm text-red-600">
          {(methods as any).formState.errors[name].message}
        </p>
      )}
    </div>
  );
}

export default FormSelect;
