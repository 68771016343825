/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useContext, useEffect } from "react";

import { useState } from "react";
import {
  ArrowDownCircleIcon,
  ArrowPathIcon,
  ArrowUpCircleIcon,
  PlusSmallIcon,
} from "@heroicons/react/20/solid";
import { amountFormater, getTimestamp } from "../../utils";
import {
  usersCollectionName,
  superMerchantAndMerchantsForeingKeysCollectionsName,
  accountsCollectionName,
  transactionCollectionName,
} from "../../config";
import { db } from "../../firebase";
import { UserContext } from "../../contexts";
import { useNavigate } from "react-router-dom";
import { AccountType } from "../../interfaces";
import StatsComponent from "../../components/SuperMerchantComponent/StatsComponent";
import MerchantsComponent from "../../components/SuperMerchantComponent/MerchantsComponent";
import {
  getCountFromServer,
  query,
  collection,
  where,
} from "firebase/firestore";
import moment from "moment";
import { BanknotesIcon } from "@heroicons/react/24/outline";

const secondaryNavigation = [{ name: "Reporting", current: true }];
const statuses: any = {
  Paid: "text-green-700 bg-green-50 ring-green-600/20",
  Withdraw: "text-gray-600 bg-gray-50 ring-gray-500/10",
  Overdue: "text-red-700 bg-red-50 ring-red-600/10",
};
const days = [
  {
    date: "Today",
    dateTime: "2023-03-22",
    transactions: [
      {
        id: 1,
        invoiceNumber: "00012",
        href: "#",
        amount: "$7,600.00 USD",
        tax: "$500.00",
        status: "Paid",
        client: "Reform",
        description: "Website redesign",
        icon: ArrowUpCircleIcon,
      },
      {
        id: 2,
        invoiceNumber: "00011",
        href: "#",
        amount: "$10,000.00 USD",
        status: "Withdraw",
        client: "Tom Cook",
        description: "Salary",
        icon: ArrowDownCircleIcon,
      },
      {
        id: 3,
        invoiceNumber: "00009",
        href: "#",
        amount: "$2,000.00 USD",
        tax: "$130.00",
        status: "Overdue",
        client: "Tuple",
        description: "Logo design",
        icon: ArrowPathIcon,
      },
    ],
  },
  {
    date: "Yesterday",
    dateTime: "2023-03-21",
    transactions: [
      {
        id: 4,
        invoiceNumber: "00010",
        href: "#",
        amount: "$14,000.00 USD",
        tax: "$900.00",
        status: "Paid",
        client: "SavvyCal",
        description: "Website redesign",
        icon: ArrowUpCircleIcon,
      },
    ],
  },
];

const SuperMerchantHomePage = () => {
  const { user, userAccounts, superMerchantAffiliatedMerchants } =
    useContext(UserContext);
  const [merchants, setMerchants] = useState<any[]>([]);
  const navigate = useNavigate();
  const [retirveAllTransactions, setRetirveAllTransactions] =
    useState<number>(0);
    const [currentTransactionsAmountSum, setCurrentTransactionsAmountSum] = useState(0)
  const [seachWithStartDateValue] = useState<string>(
    `${moment(Date.now()).format("YYYY-MM-DD")}`
  );
  const [seachWithEndDateValue] = useState<string>(
    `${moment(Date.now()).format("YYYY-MM-DD")}`
  );
  const startTime = getTimestamp(seachWithStartDateValue, true);
  const endTime = getTimestamp(seachWithEndDateValue, false);

  const retriveMerchants = useCallback(async () => {
    const userRef = db.collection(usersCollectionName);
    const membershipSnapshot = await db
      .collection(superMerchantAndMerchantsForeingKeysCollectionsName)
      .where("ownerId", "==", user?.id)
      .orderBy("createdAt", "desc")
      .limitToLast(3)
      .get();
    if (!membershipSnapshot.empty) {
      const data: any[] = [];
      for (const membership of membershipSnapshot.docs) {
        const res = await userRef.doc(membership?.data()?.memberId).get();
        let memberData: any;
        if (res.exists) {
          const accountsSnap = await userRef
            .doc(res.id)
            .collection(accountsCollectionName)
            .get();
          const accounts = accountsSnap.docs
            .map((account) => account.data())
            .find((el) => el.accountType === AccountType.MAIN);
          memberData = {
            accounts,
            ...res.data(),
          };
        }
        res.exists && data.push(memberData);
      }
      setMerchants(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const retirveTransactions = useCallback(async () => {
    const nbOfAllTransactions = (
      await getCountFromServer(
        query(
          collection(db, transactionCollectionName),
          where("participantsIds", "array-contains", user?.id),
          where("createdAt", ">=", startTime),
          where("createdAt", "<=", endTime)
        )
      )
    ).data().count;

    setRetirveAllTransactions(nbOfAllTransactions);
  }, []);

  const retirveTransactionsAmontSum = useCallback(async () => {
    const transSnap = await db
      .collection(transactionCollectionName)
      .orderBy("createdAt", "desc")
      .where("participantsIds", "array-contains", user?.id)
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<=", endTime)
      .get();
      if (transSnap.empty) {
        setCurrentTransactionsAmountSum(0);
      }else {
        const amount = transSnap.docs.reduce(
          (accumulator, currentValue) =>
            accumulator + parseInt(`${currentValue.data().amount}`),
          0
        );
        setCurrentTransactionsAmountSum(amount);
      }
  }, []);

  useEffect(() => {
    // retriveMerchants();
    retirveTransactions();
    retirveTransactionsAmontSum();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="relative pt-16 overflow-hidden isolate">
        {/* Secondary navigation */}
        <header className="pt-6 pb-4 sm:pb-6">
          <div className="flex flex-wrap items-center gap-6 px-4 mx-auto max-w-7xl sm:flex-nowrap sm:px-6 lg:px-8">
            <h1 className="text-base font-semibold leading-7 text-gray-900">
              Flux
            </h1>
            <div className="flex order-last w-full text-sm font-semibold leading-6 gap-x-8 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
              {secondaryNavigation.map((item) => (
                <div
                  key={item.name}
                  className={item.current ? "text-indigo-600" : "text-gray-700"}
                >
                  {item.name}
                </div>
              ))}
            </div>
            <div className="flex items-center px-3 py-2 ml-auto text-sm font-semibold text-white gap-x-1 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm text-lime-700 border-lime-300 hover:bg-lime-50 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-offset-2"
                onClick={() => {
                  navigate("/superMerchants/withdraw_merchant");
                }}
              >
                <BanknotesIcon
                  className="self-center flex-shrink-0 w-5 h-5 mr-1 text-lime-500"
                  aria-hidden="true"
                />
                Retrait à l'agence
              </button>
            </div>
          </div>
        </header>

        {/* Stats */}
        <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
          <dl className="grid grid-cols-1 mx-auto max-w-7xl sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
            <StatsComponent
              stats={[
                {
                  name: "Compte principale",
                  value: `${amountFormater(
                    userAccounts?.find(
                      (el) => el.accountType === AccountType.MAIN
                    )?.balance as number
                  )} Fcfa`,
                  changeType: "positive",
                },
                {
                  name: "Totals marchands",
                  value: `${superMerchantAffiliatedMerchants?.length}`,
                  changeType: "negative",
                },
                {
                  name: "Totals transactions du jours",
                  value: `${retirveAllTransactions}`,
                  changeType: "positive",
                },
                {
                  name: "Total du jours",
                  value: `${amountFormater(currentTransactionsAmountSum)} Fcfa`,
                  changeType: "negative",
                },
              ]}
            />
          </dl>
        </div>

        <div
          className="absolute left-0 origin-top-left -rotate-90 translate-y-40 top-full -z-10 mt-96 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
          aria-hidden="true"
        >
          <div
            className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
            style={{
              clipPath:
                "polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
            }}
          />
        </div>
      </div>

      <div className="py-16 space-y-16 xl:space-y-20">
        {/* Recent client list*/}
        <MerchantsComponent merchants={superMerchantAffiliatedMerchants} />
        {/* Activité récente table */}
        {/* <div>
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <h2 className="max-w-2xl mx-auto text-base font-semibold leading-6 text-gray-900 lg:mx-0 lg:max-w-none">
              Activité récente
            </h2>
          </div>
          <div className="mt-6 overflow-hidden border-t border-gray-100">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="max-w-2xl mx-auto lg:mx-0 lg:max-w-none">
                <table className="w-full text-left">
                  <thead className="sr-only">
                    <tr>
                      <th>Amount</th>
                      <th className="hidden sm:table-cell">Client</th>
                      <th>More details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {days.map((day) => (
                      <Fragment key={day.dateTime}>
                        <tr className="text-sm leading-6 text-gray-900">
                          <th
                            scope="colgroup"
                            colSpan={3}
                            className="relative py-2 font-semibold isolate"
                          >
                            <time dateTime={day.dateTime}>{day.date}</time>
                            <div className="absolute inset-y-0 w-screen border-b border-gray-200 right-full -z-10 bg-gray-50" />
                            <div className="absolute inset-y-0 left-0 w-screen border-b border-gray-200 -z-10 bg-gray-50" />
                          </th>
                        </tr>
                        {day.transactions.map((transaction) => (
                          <tr key={transaction.id}>
                            <td className="relative py-5 pr-6">
                              <div className="flex gap-x-6">
                                <transaction.icon
                                  className="flex-none hidden w-5 h-6 text-gray-400 sm:block"
                                  aria-hidden="true"
                                />
                                <div className="flex-auto">
                                  <div className="flex items-start gap-x-3">
                                    <div className="text-sm font-medium leading-6 text-gray-900">
                                      {transaction.amount}
                                    </div>
                                    <div
                                      className={classNames(
                                        statuses[transaction.status],
                                        "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset"
                                      )}
                                    >
                                      {transaction.status}
                                    </div>
                                  </div>
                                  {transaction.tax ? (
                                    <div className="mt-1 text-xs leading-5 text-gray-500">
                                      {transaction.tax} tax
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="absolute bottom-0 w-screen h-px bg-gray-100 right-full" />
                              <div className="absolute bottom-0 left-0 w-screen h-px bg-gray-100" />
                            </td>
                            <td className="hidden py-5 pr-6 sm:table-cell">
                              <div className="text-sm leading-6 text-gray-900">
                                {transaction.client}
                              </div>
                              <div className="mt-1 text-xs leading-5 text-gray-500">
                                {transaction.description}
                              </div>
                            </td>
                            <td className="py-5 text-right">
                              <div className="flex justify-end">
                                <a
                                  href={transaction.href}
                                  className="text-sm font-medium leading-6 text-indigo-600 hover:text-indigo-500"
                                >
                                  View
                                  <span className="hidden sm:inline">
                                    {" "}
                                    transaction
                                  </span>
                                  <span className="sr-only">
                                    , invoice #{transaction.invoiceNumber},{" "}
                                    {transaction.client}
                                  </span>
                                </a>
                              </div>
                              <div className="mt-1 text-xs leading-5 text-gray-500">
                                Invoice{" "}
                                <span className="text-gray-900">
                                  #{transaction.invoiceNumber}
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default SuperMerchantHomePage;
