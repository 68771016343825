/* eslint-disable no-lone-blocks */
import { useContext, useEffect, useState } from "react";
import {
  AgencyStaffRole,
  UserInterface,
  UserRole,
  mapUserAvailableZone,
  mapsGender,
} from "../../interfaces";
import toast from "react-hot-toast";
import { db, functions } from "../../firebase";
import { useConfirm } from "../../hooks/useConfirm";
import { useNavigate } from "react-router-dom";
import {
  accountsCollectionName,
  alertesConfigId,
  commissionsCollectionsName,
  globalConfigCollectionsName,
  referralsCollectionName,
  transactionCollectionName,
  usersCollectionName,
} from "../../config";
import { Timestamp } from "firebase-admin/firestore";
import {
  amountFormater,
  classNames,
  getTimestamp,
  getTransactionLabel,
} from "../../utils";
import {
  ArrowPathIcon,
  ArrowPathRoundedSquareIcon,
  ArrowsUpDownIcon,
  CheckBadgeIcon,
  CheckIcon,
  NoSymbolIcon,
  PauseCircleIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import HasRoles from "../HasRoles";
import PhoneInput from "react-phone-number-input";
import Modal from "../Modal";
import { Form, FormInput } from "../FormComponents";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { userConnectedWithMail } from "../../utils";
import FormCheckToggle from "../FormComponents/FormCheckToggle";
import { UserContext } from "../../contexts";
import moment from "moment";

interface UserDetailsPageInterface {
  userDetailsData: UserInterface;
  makeDealer: boolean;
  OwnUserRole?: string;
}

const UserDetailsPageComponent = ({
  userDetailsData,
  makeDealer,
  OwnUserRole,
}: UserDetailsPageInterface) => {
  const [show, setShow] = useState(false);
  const { isConfirmModal } = useConfirm();
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState<any[]>([]);
  const [mainAmmount, setMainAmount] = useState(0);
  const [fuelAmmount, setFuelAmount] = useState(0);
  const [commissionAmount, setCommissionAmount] = useState(0);
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [showSetReferralModal, setShowSetReferralModal] = useState(false);
  const [showUpdateNumberModal, setshowUpdateNumberModal] = useState(false);
  const [showUpdateMailModal, setshowUpdateMailModal] = useState(false);
  const [showUpdateUserAccountCapModal, setShowUpdateUserAccountCapModal] =
    useState(false);
  const [userData, setUserData] = useState<any>();
  const [refererData, setRefererData] = useState<any>();
  const [dealerAgent, setDealerAgent] = useState<any>();
  const notResetPinCodeRole = [UserRole.SUPERMERCHANT, UserRole.AGENCY];
  const [alertConfig, setAlertConfig] = useState("");

  const retirveConfigValue = async () => {
    const globalConfigsRef = db.collection(globalConfigCollectionsName);
    const alertsGlobalConfigsRef = globalConfigsRef.doc(alertesConfigId);
    const alertsGlobalConfigsSnapshot = await alertsGlobalConfigsRef.get();
    const alertsGlobalConfigs = alertsGlobalConfigsSnapshot.data();
    setAlertConfig(alertsGlobalConfigs?.userLimitOnUsableBalance);
  };

  const sankmoneyUpdateAccountFn = functions.httpsCallable(
    "sankmoneyUpdateAccountFn"
  );
  const sankmoneyAdminUpdateUserPinCodeFn = functions.httpsCallable(
    "sankmoneyAdminUpdateUserPinCodeFn"
  );
  const deleteUserFn = functions.httpsCallable("sankMoneyDeleteUserAccountFn");
  const updateUserNumberOrMailFn = functions.httpsCallable(
    "sankMoneyUpdateUserNumberOrEmailFn"
  );
  const sankMoneyAgrivoucherAddDealer = functions.httpsCallable(
    "sankMoneyAgrivoucher-addAgrivoucherDealer"
  );
  const onChangePINCode = async () => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes vous sûr de vouloir réinitialiser le code pin de l'utilisateur.",
      "Oui réinitialiser",
      true
    );

    if (isConfirm) {
      const response = sankmoneyAdminUpdateUserPinCodeFn({
        userId: userDetailsData?.id,
      }).then(() => {
        navigate(`/users/${userDetailsData.id}/details`, {
          state: { entity: userDetailsData, isSearch: true },
        });
      });
      toast.promise(response, {
        error: "La réinitialisation du code pin à echoué.",
        success: "Le code pin a été réinitialiser avec success",
        loading: "chargement...",
      });
    }
  };

  const { user } = useContext(UserContext);

  const onBlockedUserAccount = async () => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes vous sûr de vouloir desactiver le compte cet utilisateur",
      "Oui desactiver",
      true
    );

    if (isConfirm) {
      const response = sankmoneyUpdateAccountFn({
        collectionName: usersCollectionName,
        documentData: {
          active: false,
        },
        documentId: userDetailsData.id,
      }).then(() => {
        navigate(`/users/`);
      });
      toast.promise(response, {
        loading: "Blocage...",
        success: "L'utilisateur a été bloqué avec succès.",
        error: "Échec ! Veuillez réessayer.",
      });
    }
  };
  const onActiveUserAccount = async () => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes vous sûr de vouloir activer le compte cet utilisateur",
      "Oui activer",
      true
    );

    if (isConfirm) {
      const response = sankmoneyUpdateAccountFn({
        collectionName: usersCollectionName,
        documentData: {
          active: true,
        },
        documentId: userDetailsData.id,
      }).then(() => {
        navigate(`/users/`);
      });
      toast.promise(response, {
        loading: "Activation...",
        success: "L'utilisateur a été activé avec succès.",
        error: "Échec ! Veuillez réessayer.",
      });
    }
  };

  const onDesactiveSellerMode = async () => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes-vous sûr de vouloir désactiver le mode commerciale de l'utilisateur.",
      "Oui désactiver",
      true
    );

    if (isConfirm) {
      const response = sankmoneyUpdateAccountFn({
        collectionName: usersCollectionName,
        documentData: {
          isSeller: false,
          sellerTag: "",
        },
        documentId: userDetailsData.id,
      }).then(() => {
        navigate(`/users/`);
      });
      toast.promise(response, {
        error: "La mise à jour a échoué",
        success: "L'utilisateur a été mise à jour avec succès",
        loading: "chargement...",
      });
    }
  };

  const onMakedSeller = async (data: any) => {
    setShowSetReferralModal(false);
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes-vous sûr de vouloir éditer l'utilisateur en commerciale.",
      "Oui éditer",
      true
    );

    if (isConfirm) {
      const response = sankmoneyUpdateAccountFn({
        collectionName: usersCollectionName,
        documentData: {
          isSeller: true,
          sellerTag: data.sellerTag,
        },
        documentId: userDetailsData.id,
      }).then(() => {
        navigate(`/users`);
      });
      toast.promise(response, {
        error: "La mise à jour a échoué",
        success: "L'utilisateur a été mise à jour avec succès",
        loading: "chargement...",
      });
    }
  };

  const makeUserToDealer = async () => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes vous sûr de vouloir éditer l'utilisateur en dealer.",
      "Oui éditer",
      true
    );
    if (userDetailsData && isConfirm) {
      const dataValue = {
        id: userDetailsData.id,
        userRole: UserRole.DEALER,
        isUserRoleGettingTransformed: true,
      };
      const response = sankmoneyUpdateAccountFn({
        collectionName: usersCollectionName,
        documentData: dataValue,
        documentId: userDetailsData.id,
      })
        .then(async () => {
          navigate(`/users/${userDetailsData.id}/details`, {
            state: { entity: userDetailsData, isSearch: true },
          });
        })
        .catch((err: { details: any; message: any }) =>
          toast.error(err.details.message)
        );
      toast.promise(response, {
        error: "La mise à jour a échoué",
        success: "L'utilisateur a été mise à jour avec succès",
        loading: "chargement...",
      });
    }
  };

  const deleteUser = async () => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes vous sûr de vouloir supprimer l'utilisateur.",
      "Oui supprimer",
      true
    );
    if (isConfirm) {
      const res = deleteUserFn({ userId: userDetailsData?.id }).then(() =>
        navigate(`/users/`)
      );
      toast.promise(res, {
        error: "Impossible de supprimer. L'utilisateur à toujour du solde.",
        loading: "Suppression ...",
        success: "L'utilisateur a été supprimer avec success.",
      });
    }
  };

  const getAllHistories = async () => {
    let transRef = db
      .collection(transactionCollectionName)
      .where("participantsIds", "array-contains", userDetailsData?.id);
    if (OwnUserRole === UserRole.SUPERVISOR)
      await transRef
        .limit(1)
        .get()
        .then((response) => {
          setTransactions(
            response.docs.map((d) => {
              return d.data();
            })
          );
        });
    else
      await transRef
        .orderBy("createdAt", "desc")
        .limit(5)
        .get()
        .then((response) => {
          setTransactions(
            response.docs.map((d) => {
              return d.data();
            })
          );
        });
  };

  const retrivePhoneNumber = async (phoneNumber: string) => {
    if (phoneNumber.length >= 12) {
      await db
        .collection(usersCollectionName)
        .where("phoneNumber", "==", phoneNumber)
        .where("userRole", "==", UserRole.DEALER)
        .get()
        .then((response) => {
          setUserData(
            response.docs.map((d) => {
              return { id: d.id, data: d.data() };
            })
          );
        });
    } else {
      setUserData([]);
    }
  };

  const getEditUserUrl = (userRole: string) => {
    switch (userRole) {
      case UserRole.AGENT:
        navigate(`/agents/${userDetailsData.id}/edit`, {
          state: { entity: userDetailsData },
        });
        break;
      case UserRole.DEALER:
        navigate(`/dealers/${userDetailsData.id}/edit`, {
          state: { entity: userDetailsData },
        });
        break;
      case UserRole.MARCHAND:
        navigate(`/merchants/${userDetailsData.id}/edit`, {
          state: { entity: userDetailsData },
        });
        break;
      default:
        navigate(`/users/${userDetailsData.id}/edit`, {
          state: { entity: userDetailsData },
        });
        break;
    }
  };

  const affiliateAgentTodealer = async (id: string) => {
    if (userDetailsData) {
      const response = sankmoneyUpdateAccountFn({
        collectionName: usersCollectionName,
        documentData: {
          dealerId: id,
        },
        documentId: userDetailsData.id,
      })
        .then((result) => {
          navigate("/agents");
        })
        .catch((err: { message: any }) => toast.error(err.message));
      toast.promise(response, {
        error: "Une erreur s'est produite. Veuillez réessayer !",
        success: "La re-affection a été effectué avec succès.",
        loading: "re-affection...",
      });
    } else
      toast.error(
        `L'indentifiant de l'agent est incorrect veuiller réessayer de nouveau !`
      );
  };

  const onUserNumberUpdate = async (data: any) => {
    setshowUpdateNumberModal(false);
    const isConfirm = await isConfirmModal(
      "Opération réversible",
      "Êtes-vous sûr de vouloir modifier le numéro de utilisateur.",
      "Oui modifié",
      true
    );

    const verifyNumber = await db
      .collection(usersCollectionName)
      .where("phoneNumber", "==", data.phoneNumber)
      .get();
    if (verifyNumber.docs.length !== 0) {
      toast.error("Le numero de téléphone existe déjà");
      return;
    }

    if (isConfirm) {
      const response = updateUserNumberOrMailFn({
        userId: userDetailsData.id,
        phoneNumber: data.phoneNumber,
      }).then(() => {
        navigate(`/users`);
      });
      toast.promise(response, {
        error: "La mise à jour a échoué",
        success: "L'utilisateur a été mise à jour avec succès",
        loading: "chargement...",
      });
    }
  };

  const onUserMailUpdate = async (data: any) => {
    setshowUpdateMailModal(false);
    const isConfirm = await isConfirmModal(
      "Opération réversible",
      "Êtes-vous sûr de vouloir modifier le mail de utilisateur.",
      "Oui modifié",
      true
    );

    const verifyMail = await db
      .collection(usersCollectionName)
      .where("email", "==", data.email)
      .get();
    if (verifyMail.docs.length !== 0) {
      toast.error("L'email existe déjà");
      return;
    }

    if (isConfirm) {
      const response = updateUserNumberOrMailFn({
        userId: userDetailsData.id,
        email: data.email,
      }).then(() => {
        navigate(`/users`);
      });
      toast.promise(response, {
        error: "La mise à jour a échoué",
        success: "L'utilisateur a été mise à jour avec succès",
        loading: "chargement...",
      });
    }
  };

  const updateUserAccountCap = async (data: any) => {
    if (data.isVipAccount) {
      const response = sankmoneyUpdateAccountFn({
        collectionName: usersCollectionName,
        documentData: {
          isVipAccount: data.isVipAccount,
          accountCapAmount: parseInt(data.accountCapAmount),
        },
        documentId: userDetailsData.id,
      })
        .then((result) => {
          setShowUpdateUserAccountCapModal(false);
        })
        .catch((err: { message: any }) => {
          toast.error(err.message);
          setShowUpdateUserAccountCapModal(false);
        });
      toast.promise(response, {
        error: "Une erreur s'est produite. Veuillez réessayer !",
        success: "L'état du compte a été changer avec succès.",
        loading: "Mise à jour...",
      });
    } else toast.error(`Veuillez activer l'option VIP`);
  };
  const changeVipAccount = async () => {
    const isConfirm = await isConfirmModal(
      "Opération réversible",
      "Êtes-vous sûr de vouloir remettre le plafond par défaut.",
      "Oui changer",
      true
    );

    if (isConfirm) {
      const response = sankmoneyUpdateAccountFn({
        collectionName: usersCollectionName,
        documentData: {
          isVipAccount: false,
          accountCapAmount: parseInt(`${alertConfig ?? "2000000"}`),
        },
        documentId: userDetailsData.id,
      }).then(() => {
        navigate(`/users`);
      });
      toast.promise(response, {
        error: "Une erreur s'est produite. Veuillez réessayer !",
        success: "L'état du compte a été changer avec succès.",
        loading: "Mise à jour...",
      });
    }
  };
  const onChangeUserToAgrivoucherDealer = async (
    isAgrivoucherDealer: boolean
  ) => {
    const isConfirm = await isConfirmModal(
      "Opération réversible",
      `Êtes-vous sûr de vouloir ${
        !isAgrivoucherDealer ? "désactiver" : "changer"
      } l'utilisateur en distributeur Agrivoucher.`,
      `${!isAgrivoucherDealer ? "Oui désactiver" : "Oui changer"}`,
      true
    );

    if (isConfirm) {
      const toastId = toast.loading("Mise à jour...");

      sankmoneyUpdateAccountFn({
        collectionName: usersCollectionName,
        documentData: {
          isAgrivoucherDealer,
        },
        documentId: userDetailsData.id,
      })
        .then(() => {
          toast.success("L'état de l'utilisateur a été changer avec succès.", {
            id: toastId,
          });
          navigate(`/users`);
        })
        .catch((e) => {
          toast.error(e.details.message, {
            id: toastId,
          });
        });
    }
  };

  const [totalReload, setTotalReload] = useState(0);
  const [totalWithdraw, setTotalWithdraw] = useState(0);
  const [userAccounts, setuserAccounts] = useState<any[]>([]);
  const userRef = db.collection(usersCollectionName);
  const [isCumulativeMonthAndDay, setIsCumulativeMonthAndDay] =
    useState<boolean>(false);
  const [startTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, true)
  );
  const firstDay = moment().startOf("month");

  const lastDay = moment().endOf("month");

  // get the totoal amount of the agent reload
  const getUserTotalReload = async () => {
    const totalReload = await db
      .collection(transactionCollectionName)
      .orderBy("createdAt", "desc")
      .where("createdAt", ">=", startTime)
      .where("participantsIds", "array-contains", userDetailsData?.id)
      .where("transactionType", "==", "TRANSFER")
      .get();
    return totalReload.docs.reduce((acc, doc) => {
      return acc + parseInt(doc.data().amount);
    }, 0);
  };

  // get the total amount of the agent withdraw
  const getUserTotalWithdraw = async () => {
    const totalWithdraw = await db
      .collection(transactionCollectionName)
      .orderBy("createdAt", "desc")
      .where("createdAt", ">=", startTime)
      .where("participantsIds", "array-contains", userDetailsData?.id)
      .where("transactionType", "==", "WITHDRAW")
      .get();
    return totalWithdraw.docs.reduce((acc, doc) => {
      return acc + parseInt(doc.data().amount);
    }, 0);
  };

  const getUserTotalWithdrawmonth = async () => {
    const totalReload = await db
      .collection(transactionCollectionName)
      .orderBy("createdAt", "desc")
      .where("createdAt", ">=", firstDay.toDate())
      .where("createdAt", "<=", lastDay.toDate())
      .where("participantsIds", "array-contains", userDetailsData?.id)
      .where("transactionType", "==", "WITHDRAW")
      .get();

    return totalReload.docs.reduce((acc, doc) => {
      return acc + parseInt(doc.data().amount);
    }, 0);
  };

  const getUserTotalReloadofmonth = async () => {
    const totalReload = await db
      .collection(transactionCollectionName)
      .orderBy("createdAt", "desc")
      .where("createdAt", ">=", firstDay.toDate())
      .where("createdAt", "<=", lastDay.toDate())
      .where("participantsIds", "array-contains", userDetailsData?.id)
      .where("transactionType", "==", "TRANSFER")
      .get();

    return totalReload.docs.reduce((acc, doc) => {
      return acc + parseInt(doc.data().amount);
    }, 0);
  };

  useEffect(() => {
    if (userDetailsData) {
      userRef
        .doc(userDetailsData?.id)
        .collection(accountsCollectionName)
        .get()
        .then((response) => {
          setuserAccounts(
            response.docs.map((d) => {
              return d.data();
            })
          );
        });
      // eslint-disable-next-line no-lone-blocks
      {
        !isCumulativeMonthAndDay
          ? getUserTotalReload().then((total) => setTotalReload(total))
          : getUserTotalReloadofmonth().then((total) => setTotalReload(total));
      }
      {
        !isCumulativeMonthAndDay
          ? getUserTotalWithdraw().then((total) => setTotalWithdraw(total))
          : getUserTotalWithdrawmonth().then((total) =>
              setTotalWithdraw(total)
            );
      }
    }
  }, [isCumulativeMonthAndDay]);

  useEffect(() => {
    retirveConfigValue();
    if (userDetailsData) {
      getAllHistories();
    }
    (async () => {
      const userRef = db
        .collection(usersCollectionName)
        .doc(userDetailsData.id);
      const referalRef = await db
        .collection(referralsCollectionName)
        .where("userId", "==", userDetailsData.id)
        .get();
      if (referalRef.docs.length > 0) {
        const referalData = referalRef.docs[0].data();
        const referer = await db
          .collection(usersCollectionName)
          .where("phoneNumber", "==", referalData.referralPhoneNumber)
          .get();
        setRefererData(referer.docs[0].data());
      }

      const sankAcountRef = userRef.collection(accountsCollectionName);
      const commissionRef = db
        .collection(commissionsCollectionsName)
        .where("userId", "==", userDetailsData.id)
        .where("commissionPaid", "==", false);
      const commissionData = await commissionRef.get();

      commissionData.docs.forEach((snapShot) => {
        const data = snapShot.data();
        setCommissionAmount((previoustate) => previoustate + data.amount);
      });

      const agentDealer = await db
        .collection(usersCollectionName)
        .doc(userDetailsData.dealerId)
        .get();
      setDealerAgent(agentDealer.data());

      const sankAcountsData = await sankAcountRef.get();

      sankAcountsData.docs.forEach((snapShot) => {
        const data = snapShot.data();

        if (data.accountType === "MAIN") {
          setMainAmount(data.balance);
        }

        if (data.accountType === "FUEL") {
          setFuelAmount(data.balance);
        }
      });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mx-auto">
      <div className="flex mx-auto space-x-10 max-md:block max-md:space-x-0 max-md:space-y-10 max-md:mx-5">
        <img
          className="w-40 mb-auto rounded-full max-md:mx-auto"
          alt={userDetailsData?.displayName}
          src={
            userDetailsData?.profilImageUrl
              ? userDetailsData?.profilImageUrl
              : `https://ui-avatars.com/api/?background=random&name=${
                  userDetailsData?.displayName || "S"
                }`
          }
        />

        <div className="w-96 max-md:w-full">
          <div className="pb-6 ">
            <p className="text-lg font-semibold leading-none text-gray-800">
              Les informations de l'utilisateur
            </p>
            <h1 className="mt-2 text-xl font-semibold leading-7 text-gray-800 lg:text-2xl lg:leading-6">
              {userDetailsData?.phoneNumber}
            </h1>
          </div>

          <HasRoles
            userRole={[
              "ADMIN",
              "TECHNICALSUPPORT",
              "DEALER",
              "AGENCYSTAFF",
            ]}
          >
            <div className="mb-5">
              <div className="flex items-center justify-between py-2 ">
                <p className="text-base font-semibold leading-4 text-gray-800">
                  Compte Principale
                </p>
                <div className="flex items-center justify-center">
                  <p className="text-lg font-semibold leading-none text-gray-800">
                    {new Intl.NumberFormat().format(mainAmmount)} Fcfa
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-between py-2 ">
                <p className="text-base font-semibold leading-4 text-gray-800">
                  Fuel
                </p>
                <div className="flex items-center justify-center">
                  <p className="text-lg font-semibold leading-none text-gray-800">
                    {new Intl.NumberFormat().format(fuelAmmount)} Fcfa
                  </p>
                </div>
              </div>
              {userDetailsData.userRole === UserRole.USER ? (
                <>
                  <hr className="my-4" />
                  <label className="relative flex justify-between cursor-pointer">
                    <span className="text-sm font-medium text-gray-900 ms-3 dark:text-gray-300">
                      {isCumulativeMonthAndDay
                        ? "Cummul des transactions du jour"
                        : "Cummul des transactions du mois"}
                    </span>
                    <input
                      type="checkbox"
                      value=""
                      className="sr-only peer"
                      checked={isCumulativeMonthAndDay}
                      onChange={(e) =>
                        setIsCumulativeMonthAndDay(e.target.checked)
                      }
                    />
                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-600"></div>
                  </label>
                  {!isCumulativeMonthAndDay ? (
                    <>
                      <div className="flex items-center justify-between py-2 ">
                        <p className="text-base font-semibold leading-4 text-gray-800">
                          Total des depots du jour
                        </p>
                        <div className="flex items-center justify-center">
                          <p className="text-lg font-semibold leading-none text-gray-800">
                            {new Intl.NumberFormat().format(totalReload)} Fcfa
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center justify-between py-2 ">
                        <p className="text-base font-semibold leading-4 text-gray-800">
                          Total des retraits du jour
                        </p>
                        <div className="flex items-center justify-center">
                          <p className="text-lg font-semibold leading-none text-gray-800">
                            {new Intl.NumberFormat().format(totalWithdraw)} Fcfa
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex items-center justify-between py-2 ">
                        <p className="text-base font-semibold leading-4 text-gray-800">
                          Total des depots du mois
                        </p>
                        <div className="flex items-center justify-center">
                          <p className="text-lg font-semibold leading-none text-gray-800">
                            {new Intl.NumberFormat().format(totalReload)} Fcfa
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center justify-between py-2 ">
                        <p className="text-base font-semibold leading-4 text-gray-800">
                          Total des retraits du mois
                        </p>
                        <div className="flex items-center justify-center">
                          <p className="text-lg font-semibold leading-none text-gray-800">
                            {new Intl.NumberFormat().format(totalWithdraw)} Fcfa
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div />
              )}
              {[
                UserRole.AGENT,
                UserRole.GASSTATION,
                UserRole.MARCHAND,
                UserRole.DEALER,
              ].includes(userDetailsData.userRole) && (
                <div className="flex items-center justify-between py-2 ">
                  <p className="text-base font-semibold leading-4 text-gray-800">
                    Commissions
                  </p>
                  <div className="flex items-center justify-center">
                    <p className="text-lg font-semibold leading-none text-gray-800">
                      {new Intl.NumberFormat().format(commissionAmount)} Fcfa
                    </p>
                  </div>
                </div>
              )}
            </div>
          </HasRoles>
          <div className="flex items-center justify-between py-2 border-b border-gray-200">
            <p className="text-base leading-4 text-gray-800">Nom/Prénom</p>
            <div className="flex items-center justify-center">
              <p className="font-mono text-sm leading-none text-gray-800">
                {userDetailsData?.displayName
                  ? userDetailsData?.displayName
                  : "-"}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between py-2 border-b border-gray-200">
            <p className="text-base leading-4 text-gray-800">Email</p>
            <div className="flex items-center justify-center">
              <p className="font-mono text-sm leading-none text-gray-800">
                {userDetailsData?.email ? userDetailsData?.email : "-"}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between py-2 border-b border-gray-200">
            <p className="text-base leading-4 text-gray-800">
              Numéro CNI/Passport
            </p>
            <div className="flex items-center justify-center">
              <p className="font-mono text-sm leading-none text-gray-800">
                {userDetailsData?.cnibNumber
                  ? userDetailsData?.cnibNumber
                  : "-"}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between py-2 border-b border-gray-200">
            <p className="text-base leading-4 text-gray-800">Ville</p>
            <div className="flex items-center justify-center">
              <p className="font-mono text-sm leading-none text-gray-800">
                {userDetailsData?.city ? userDetailsData?.city : "-"}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between py-2 border-b border-gray-200">
            <p className="text-base leading-4 text-gray-800">Profession</p>
            <div className="flex items-center justify-center">
              <p className="font-mono text-sm leading-none text-gray-800">
                {userDetailsData?.profession
                  ? userDetailsData?.profession
                  : "-"}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between py-2 border-b border-gray-200">
            <p className="text-base leading-4 text-gray-800">Genre</p>
            <div className="flex items-center justify-center">
              <p className="font-mono text-sm leading-none text-gray-800">
                {userDetailsData?.gender
                  ? mapsGender.get(userDetailsData?.gender)
                  : "-"}
              </p>
            </div>
          </div>

          {userDetailsData.userRole === UserRole.USER && refererData && (
            <div className="flex items-center justify-between py-2 border-b border-gray-200">
              <p className="text-base leading-4 text-gray-800">Affilié par</p>
              <div className="flex items-center justify-center">
                <p className="font-mono text-sm leading-none text-gray-800">
                  {refererData?.displayName}
                </p>
              </div>
            </div>
          )}
          {userDetailsData.userRole === UserRole.AGENT && dealerAgent && (
            <div className="flex items-center justify-between py-2 border-b border-gray-200">
              <p className="text-base leading-4 text-gray-800">Affilié à</p>
              <div className="flex items-center justify-center">
                <p className="font-mono text-sm leading-none text-gray-800">
                  {dealerAgent?.displayName
                    ? `${dealerAgent?.displayName}/${dealerAgent?.phoneNumber}`
                    : dealerAgent.phoneNumber}
                </p>
              </div>
            </div>
          )}
          <div className="flex items-center justify-between py-2 border-b border-gray-200">
            <p className="text-base leading-4 text-gray-800">Adresse</p>
            <div className="flex items-center justify-center">
              <p className="font-mono text-sm leading-none text-gray-800">
                {userDetailsData?.adresse ? userDetailsData?.adresse : "-"}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between py-2 border-b border-gray-200">
            <p className="text-base leading-4 text-gray-800">Zone</p>
            <div className="flex items-center justify-center">
              <p className="font-mono text-sm leading-none text-gray-800">
                {userDetailsData?.availableZone
                  ? mapUserAvailableZone.get(userDetailsData?.availableZone)
                  : "-"}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between py-2 border-b border-gray-200">
            <p className="text-base leading-4 text-gray-800">
              Personne à prévenir
            </p>
            <div className="flex items-center justify-center">
              <p className="font-mono text-sm leading-none text-gray-800">
                {userDetailsData?.personToNotify
                  ? userDetailsData?.personToNotify
                  : "-"}
              </p>
            </div>
          </div>

          <div className="flex items-center justify-between py-2 border-b border-gray-200">
            <p className="text-base leading-4 text-gray-800">
              Membre SankMoney depuis
            </p>
            <div className="flex items-center justify-center">
              <p className="font-mono text-sm leading-none text-gray-800">
                {new Date(
                  userDetailsData.createdAt.seconds * 1000 +
                    userDetailsData.createdAt.nanoseconds / 1000000
                ).toLocaleDateString()}
              </p>
            </div>
          </div>
          {userDetailsData?.agentCode && (
            <div className="flex items-center justify-between py-2 border-b border-gray-200">
              <p className="text-base leading-4 text-gray-800">Code agent</p>
              <div className="flex items-center justify-center">
                <p className="p-1 font-mono text-sm leading-none text-gray-800 border">
                  {userDetailsData?.agentCode}
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="w-72 max-md:w-full">
          {!makeDealer ? (
            <>
              <HasRoles userRole={["ADMIN", "AGENCYSTAFF", "TECHNICALSUPPORT"]}>
                {user?.userAgencySubRole === AgencyStaffRole.MANAGER ||
                user?.userRole === UserRole.TECHNICALSUPPORT ||
                user?.userRole === UserRole.ADMIN ? (
                  <button
                    onClick={() => {
                      onChangePINCode();
                    }}
                    disabled={!userDetailsData.active}
                    className={`flex items-center w-full px-4 py-2 mx-auto my-4 text-base font-semibold leading-none text-white ${
                      userDetailsData.active ? "bg-yellow-500" : "bg-gray-700"
                    } rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 hover:bg-gray-700`}
                  >
                    <ArrowPathIcon className="w-5 h-5 mr-[10%] text-white" />
                    Réinitialiser le code PIN
                  </button>
                ) : (
                  <div />
                )}
              </HasRoles>
              <HasRoles userRole={["ADMIN"]}>
                <button
                  onClick={() => getEditUserUrl(userDetailsData.userRole)}
                  disabled={!userDetailsData.active}
                  className={`flex items-center w-full px-4 py-2 mx-auto my-4 mt-2 text-base font-semibold leading-none text-white ${
                    userDetailsData.active ? "bg-blue-500" : "bg-gray-700"
                  }  rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 hover:bg-gray-700`}
                >
                  <PencilIcon className="w-5 h-5 mr-[10%] text-white" />
                  Editer l'utilisateur
                </button>
              </HasRoles>
              <HasRoles userRole={["ADMIN"]}>
                <>
                  {!notResetPinCodeRole.includes(userDetailsData.userRole) && (
                    <button
                      onClick={() => deleteUser()}
                      disabled={!userDetailsData.active}
                      className={`flex items-center w-full px-4 py-2 mx-auto my-4 mt-2 text-base font-semibold leading-none text-white ${
                        userDetailsData.active ? "bg-red-500" : "bg-gray-700"
                      } rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 hover:bg-gray-700`}
                    >
                      <TrashIcon className="w-5 h-5 mr-[10%] text-white" />
                      Supprimer l'utilisateur
                    </button>
                  )}
                </>
              </HasRoles>

              <HasRoles userRole={["ADMIN", "AGENCYSTAFF", "TECHNICALSUPPORT"]}>
                <>
                  {userDetailsData &&
                    userDetailsData.userRole === UserRole.USER && (
                      <button
                        onClick={() => {
                          if (userDetailsData.isCertify) {
                            toast.error("Cet utilisateur est déjà certifié");
                            return;
                          }
                          navigate(`/users/${userDetailsData.id}/certify`, {
                            state: { entity: userDetailsData, onCertify: true },
                          });
                        }}
                        disabled={
                          !userDetailsData.active || userDetailsData.isCertify
                        }
                        className={`flex items-center w-full px-4 py-2 mx-auto my-4 mt-2 text-base font-semibold leading-none text-white ${
                          userDetailsData.active && !userDetailsData.isCertify
                            ? "bg-green-500"
                            : "bg-gray-700"
                        } rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 hover:bg-gray-700`}
                      >
                        <CheckBadgeIcon className="w-5 h-5 mr-[10%] text-white" />
                        Certifier l'utilisateur
                      </button>
                    )}
                </>
              </HasRoles>
              <HasRoles userRole={["ADMIN"]}>
                <>
                  {userDetailsData &&
                    userDetailsData.userRole === UserRole.USER && (
                      <button
                        onClick={() =>
                          userDetailsData?.active
                            ? onBlockedUserAccount()
                            : onActiveUserAccount()
                        }
                        className={classNames(
                          userDetailsData?.active
                            ? " bg-red-500"
                            : " bg-green-500",
                          "flex items-center  rounded-md my-4 w-full py-2 font-semibold mt-2 text-base leading-none text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 hover:bg-gray-700 px-4 "
                        )}
                      >
                        {userDetailsData?.active ? (
                          <>
                            <NoSymbolIcon className="w-5 h-5 mr-[10%] text-white" />
                            <span className="md:relative right-5">
                              Bloquer le compte de l'utilisateur
                            </span>
                          </>
                        ) : (
                          <>
                            <CheckIcon className="w-5 h-5 mr-2 text-white" />
                            <span className="md:relative right-5">
                              Activer le compte de l'utilisateur
                            </span>
                          </>
                        )}
                      </button>
                    )}
                </>
              </HasRoles>
              <HasRoles userRole={["ADMIN", "AGENCYSTAFF", "TECHNICALSUPPORT"]}>
                <>
                  {userDetailsData &&
                    userDetailsData.userRole === UserRole.USER && (
                      <button
                        onClick={() =>
                          userDetailsData?.isSeller
                            ? onDesactiveSellerMode()
                            : setShowSetReferralModal(true)
                        }
                        disabled={!userDetailsData.active}
                        className={`flex items-center w-full px-4 py-2 mx-auto my-4 mt-2 text-base font-semibold leading-none text-white rounded-md ${
                          userDetailsData.active ? "bg-cyan-500" : "bg-gray-700"
                        } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 hover:bg-gray-700`}
                      >
                        {userDetailsData?.isSeller ? (
                          <>
                            <NoSymbolIcon className="w-5 h-5 mr-[10%] text-white" />
                            Desactiver le mode commerciale
                          </>
                        ) : (
                          <>
                            <CheckIcon className="w-5 h-5 mr-[10%] text-white" />
                            Editer en commerciale
                          </>
                        )}
                      </button>
                    )}
                </>
              </HasRoles>
              <HasRoles userRole={["ADMIN"]}>
                <>
                  {userDetailsData && (
                    <button
                      onClick={() =>
                        userConnectedWithMail.includes(userDetailsData.userRole)
                          ? setshowUpdateMailModal(true)
                          : setshowUpdateNumberModal(true)
                      }
                      disabled={!userDetailsData.active}
                      className={classNames(
                        `${
                          userDetailsData.active ? "bg-blue-500" : "bg-gray-700"
                        } flex items-center rounded-md my-4 w-full py-2 font-semibold mt-2 text-base leading-none text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 hover:bg-gray-700 px-4`
                      )}
                    >
                      {!userConnectedWithMail.includes(
                        userDetailsData.userRole
                      ) ? (
                        <>
                          <PencilIcon className="w-5 h-5 mr-[10%] text-white" />
                          <span className="md:relative right-5">
                            Modifier le numero de l'utilisateur
                          </span>
                        </>
                      ) : (
                        <>
                          <PencilIcon className="w-5 h-5 mr-[10%] text-white" />
                          <span className="md:relative right-10">
                            Modifier le mail de l'utilisateur
                          </span>
                        </>
                      )}
                    </button>
                  )}
                </>
              </HasRoles>
              <HasRoles userRole={["ADMIN", "AGENCYSTAFF", "TECHNICALSUPPORT"]}>
                {user?.userRole === UserRole.ADMIN ||
                user?.userRole === UserRole.TECHNICALSUPPORT || user?.userAgencySubRole === AgencyStaffRole.MANAGER ? (
                  <>
                    {userDetailsData &&
                      userDetailsData.userRole === UserRole.AGENT && (
                        <button
                          onClick={() => setShowPinCodeModal(true)}
                          disabled={!userDetailsData.active}
                          className={`flex items-center justify-center w-full px-4 py-2 mx-auto my-4 mt-2 text-base font-semibold leading-none text-white rounded-md ${
                            userDetailsData.active
                              ? "bg-fuchsia-500"
                              : "bg-gray-700"
                          } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 hover:bg-gray-700`}
                        >
                          <ArrowsUpDownIcon className="w-5 h-5 mr-2 text-white" />
                          Re-affecter à un dealer
                        </button>
                      )}
                  </>
                ) : (
                  <></>
                )}
              </HasRoles>
              <HasRoles userRole={["ADMIN", "AGENCYSTAFF", "TECHNICALSUPPORT"]}>
                {user?.userRole === UserRole.ADMIN ||
                user?.userRole === UserRole.TECHNICALSUPPORT ? (
                  <>
                    {userDetailsData &&
                      userDetailsData.userRole === UserRole.USER && (
                        <button
                          onClick={() =>
                            onChangeUserToAgrivoucherDealer(
                              userDetailsData?.isAgrivoucherDealer
                                ? false
                                : true
                            )
                          }
                          disabled={!userDetailsData.active}
                          className={`flex items-center justify-center w-full px-4 py-2 mx-auto my-4 mt-2 text-base font-semibold leading-none text-white rounded-md ${
                            userDetailsData.active
                              ? "bg-fuchsia-500"
                              : "bg-gray-700"
                          } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 hover:bg-gray-700`}
                        >
                          <ArrowPathRoundedSquareIcon className="w-5 h-5 mr-2 text-white" />
                          {userDetailsData.isAgrivoucherDealer ? (
                            <div>Désactiver l'etat du dealer agrivoucher</div>
                          ) : (
                            <div>Activé en tant que dealer agrivoucher</div>
                          )}
                        </button>
                      )}
                  </>
                ) : (
                  <></>
                )}
              </HasRoles>
              <HasRoles userRole={["ADMIN", "AGENCYSTAFF", "TECHNICALSUPPORT"]}>
                <>
                  {userDetailsData &&
                    userDetailsData.userRole === UserRole.AGENT && (
                      <button
                        onClick={() => {
                          navigate(`/agents/${userDetailsData.id}/edit`, {
                            state: {
                              entity: userDetailsData,
                              id: userDetailsData.id,
                              editLocalisation: true,
                            },
                          });
                        }}
                        disabled={!userDetailsData.active}
                        className={`flex items-center justify-center w-full px-4 py-2 mx-auto my-4 mt-2 text-base font-semibold leading-none text-white ${
                          userDetailsData.active
                            ? "bg-indigo-500"
                            : "bg-gray-700"
                        } rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 hover:bg-gray-700`}
                      >
                        <ArrowsUpDownIcon className="w-5 h-5 mr-2 text-white" />
                        Mettre a jour la localisation
                      </button>
                    )}
                </>
              </HasRoles>
              <HasRoles userRole={["ADMIN"]}>
                <>
                  {userDetailsData &&
                    userDetailsData.userRole === UserRole.USER && (
                      <button
                        onClick={() =>
                          userDetailsData?.isVipAccount
                            ? changeVipAccount()
                            : setShowUpdateUserAccountCapModal(true)
                        }
                        className={classNames(
                          userDetailsData?.isVipAccount
                            ? "bg-red-500"
                            : "bg-indigo-500",
                          "flex items-center justify-center w-full px-4 py-2 mx-auto my-4 mt-2 text-base font-semibold leading-none text-white  rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 hover:bg-gray-700 "
                        )}
                      >
                        {userDetailsData?.isVipAccount ? (
                          <>
                            <PauseCircleIcon className="w-5 h-5 mr-2 text-white" />
                            Remettre le plafond par défaut
                          </>
                        ) : (
                          <>
                            <PauseCircleIcon className="w-5 h-5 mr-2 text-white" />
                            Changer la limite du plafond de l'utilisateur
                          </>
                        )}
                      </button>
                    )}
                </>
              </HasRoles>
            </>
          ) : (
            <button
              onClick={() => makeUserToDealer()}
              disabled={!userDetailsData.active}
              className={`flex items-center justify-center w-full py-2 mx-auto my-4 mt-2 text-base font-semibold leading-none text-white ${
                userDetailsData.active ? "bg-red-500" : "bg-gray-700"
              } rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 hover:bg-red-700`}
            >
              <PencilIcon className="w-5 h-5 mr-2 text-white" />
              Editer l'utilisateur en distributeur
            </button>
          )}
        </div>
      </div>

      <div className="py-2 mx-2 border-t border-b border-gray-200 mt-7">
        <div
          onClick={() => setShow(!show)}
          className="flex items-center justify-between cursor-pointer"
        >
          <p className="text-base leading-4 text-gray-800">
            Dernières transactions
          </p>
          <button
            className="rounded cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
            aria-label="show or hide"
          >
            <svg
              className={"transform " + (show ? "rotate-180" : "rotate-0")}
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 1L5 5L1 1"
                stroke="#4B5563"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <div
          className={
            "pt-4 text-base leading-normal px-4 md:px-10 pb-5 pr-12 mt-4 text-gray-600 " +
            (show ? "block" : "hidden")
          }
          id="sect"
        >
          <div className="overflow-x-auto">
            <table className="w-full mx-auto my-4 rounded-md whi2 font-semiboldespace-nowrap">
              <tbody>
                {transactions && transactions.length > 0 ? (
                  transactions.map((transaction, index) => (
                    <tr
                      key={index}
                      className="h-16 text-lg font-semibold leading-none text-gray-800"
                    >
                      <td className="w-1/2">
                        <div className="flex items-center">
                          <div className="flex items-center justify-center w-10 h-10 bg-gray-700 rounded-sm">
                            <p className="text-xs font-bold leading-3 text-white">
                              {transaction?.transactionType.substr(0, 3)}
                            </p>
                          </div>
                          <div className="pl-2">
                            <p className="text-sm font-medium leading-none text-gray-800">
                              {getTransactionLabel(
                                userDetailsData?.id ?? "",
                                transaction.transactionType,
                                userDetailsData?.id ?? ""
                              )}
                            </p>
                            <p className="mt-2 text-xs leading-3 text-gray-600">
                              {transaction?.accountType}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="pl-16">
                        <p>{transaction?.receiverPhoneNumber} </p>
                      </td>
                      <td>
                        <p className="pl-16">
                          {transaction?.receiverDisplayName}
                        </p>
                      </td>
                      <td>
                        <p className="pl-16">{transaction?.amount}</p>
                      </td>
                      <td>
                        <p className="pl-16">
                          {(transaction.createdAt as Timestamp)
                            .toDate()
                            .toLocaleString()}
                        </p>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="border-gray-200 hover:bg-table-col bg-table-col dark:border-gray-700 ">
                    <div className="px-4 py-2 text-center">
                      <div className="p-4 m-2 text-center rounded bg-gray-50 dark:bg-gray-700">
                        <h3 className="mt-2 text-sm font-medium text-gray-600 dark:text-gray-300">
                          {`Pas de contenu disponible`}
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          La requête ne renvoie pas de donnée.
                        </p>
                        <div className="inline-flex justify-center w-full px-2 py-2 mx-auto my-4 mt-6 font-semibold rounded-md">
                          <svg className="w-40 h-40" viewBox="0 0 650 650">
                            <path
                              d="M687.3279,276.08691H512.81813a15.01828,15.01828,0,0,0-15,15v387.85l-2,.61005-42.81006,13.11a8.00676,8.00676,0,0,1-9.98974-5.31L315.678,271.39691a8.00313,8.00313,0,0,1,5.31006-9.99l65.97022-20.2,191.25-58.54,65.96972-20.2a7.98927,7.98927,0,0,1,9.99024,5.3l32.5498,106.32Z"
                              transform="translate(-276.18187 -133.91309)"
                              fill="#f2f2f2"
                            />
                            <path
                              d="M725.408,274.08691l-39.23-128.14a16.99368,16.99368,0,0,0-21.23-11.28l-92.75,28.39L380.95827,221.60693l-92.75,28.4a17.0152,17.0152,0,0,0-11.28028,21.23l134.08008,437.93a17.02661,17.02661,0,0,0,16.26026,12.03,16.78926,16.78926,0,0,0,4.96972-.75l63.58008-19.46,2-.62v-2.09l-2,.61-64.16992,19.65a15.01489,15.01489,0,0,1-18.73-9.95l-134.06983-437.94a14.97935,14.97935,0,0,1,9.94971-18.73l92.75-28.4,191.24024-58.54,92.75-28.4a15.15551,15.15551,0,0,1,4.40966-.66,15.01461,15.01461,0,0,1,14.32032,10.61l39.0498,127.56.62012,2h2.08008Z"
                              transform="translate(-276.18187 -133.91309)"
                              fill="#3f3d56"
                            />
                            <path
                              d="M398.86279,261.73389a9.0157,9.0157,0,0,1-8.61133-6.3667l-12.88037-42.07178a8.99884,8.99884,0,0,1,5.9712-11.24023l175.939-53.86377a9.00867,9.00867,0,0,1,11.24072,5.9707l12.88037,42.07227a9.01029,9.01029,0,0,1-5.9707,11.24072L401.49219,261.33887A8.976,8.976,0,0,1,398.86279,261.73389Z"
                              transform="translate(-276.18187 -133.91309)"
                              fill="#F90005"
                            />
                            <circle
                              cx="190.15351"
                              cy="24.95465"
                              r="20"
                              fill="#F90005"
                            />
                            <circle
                              cx="190.15351"
                              cy="24.95465"
                              r="12.66462"
                              fill="#fff"
                            />
                            <path
                              d="M878.81836,716.08691h-338a8.50981,8.50981,0,0,1-8.5-8.5v-405a8.50951,8.50951,0,0,1,8.5-8.5h338a8.50982,8.50982,0,0,1,8.5,8.5v405A8.51013,8.51013,0,0,1,878.81836,716.08691Z"
                              transform="translate(-276.18187 -133.91309)"
                              fill="#e6e6e6"
                            />
                            <path
                              d="M723.31813,274.08691h-210.5a17.02411,17.02411,0,0,0-17,17v407.8l2-.61v-407.19a15.01828,15.01828,0,0,1,15-15H723.93825Zm183.5,0h-394a17.02411,17.02411,0,0,0-17,17v458a17.0241,17.0241,0,0,0,17,17h394a17.0241,17.0241,0,0,0,17-17v-458A17.02411,17.02411,0,0,0,906.81813,274.08691Zm15,475a15.01828,15.01828,0,0,1-15,15h-394a15.01828,15.01828,0,0,1-15-15v-458a15.01828,15.01828,0,0,1,15-15h394a15.01828,15.01828,0,0,1,15,15Z"
                              transform="translate(-276.18187 -133.91309)"
                              fill="#3f3d56"
                            />
                            <path
                              d="M801.81836,318.08691h-184a9.01015,9.01015,0,0,1-9-9v-44a9.01016,9.01016,0,0,1,9-9h184a9.01016,9.01016,0,0,1,9,9v44A9.01015,9.01015,0,0,1,801.81836,318.08691Z"
                              transform="translate(-276.18187 -133.91309)"
                              fill="#F90005"
                            />
                            <circle
                              cx="433.63626"
                              cy="105.17383"
                              r="20"
                              fill="#F90005"
                            />
                            <circle
                              cx="433.63626"
                              cy="105.17383"
                              r="12.18187"
                              fill="#fff"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showPinCodeModal}
        maxSize="md"
        closeModal={() => {
          setShowPinCodeModal(false);
          setUserData([]);
        }}
        modalTitle="Rechercher un distributeur"
      >
        <div className="flex justify-center">
          <div className="w-full mt-10">
            <PhoneInput
              international={false}
              placeholder="Entrer un numéro de téléphone"
              className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
              defaultCountry="BF"
              onChange={retrivePhoneNumber}
            />
            <div className="flex justify-center w-full">
              {userData && userData.length > 0 && (
                <div className="w-full max-w-sm my-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <div className="flex flex-col items-center pb-10">
                    <img
                      className="w-24 h-24 mt-2 mb-3 rounded-full shadow-lg"
                      src={
                        userData[0].data.profilImageUrl
                          ? userData[0].data.profilImageUrl
                          : `https://ui-avatars.com/api/?background=random&name=${
                              userData[0].data.displayName || "S"
                            }`
                      }
                      alt=""
                    />
                    <h5 className="mb-1 text-xl font-medium text-center text-gray-900 dark:text-white">
                      {userData[0].data.displayName}
                    </h5>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {userData[0].data.phoneNumber}
                    </span>
                    <div className="flex mt-4 space-x-3 md:mt-6">
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        onClick={() => affiliateAgentTodealer(userData[0].id)}
                      >
                        Attribuer
                      </div>
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                        onClick={() => {
                          setShowPinCodeModal(false);
                          setUserData([]);
                        }}
                      >
                        Annuler
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showSetReferralModal}
        maxSize="md"
        closeModal={() => setShowSetReferralModal(false)}
      >
        <Form<{ sellerTag: string }>
          onSubmit={onMakedSeller}
          form={{
            resolver: yupResolver(
              yup.object().shape({
                sellerTag: yup.string().required("Le champs est obligatoire"),
              })
            ),
          }}
          submitButtonFullWidth
          submitButtonLabel="Ajouter"
        >
          <div className="space-y-4 text-black md:space-y-6">
            <FormInput
              name="sellerTag"
              label="Ajouter un tag"
              placeholder="OUAGADOUGOU"
              type="text"
            />
          </div>
        </Form>
      </Modal>
      <Modal
        isOpen={showUpdateNumberModal}
        maxSize="md"
        closeModal={() => setshowUpdateNumberModal(false)}
      >
        <Form<{ phoneNumber: string }>
          onSubmit={onUserNumberUpdate}
          form={{
            resolver: yupResolver(
              yup.object().shape({
                phoneNumber: yup
                  .string()
                  .required(`Le numéro est obligatoire`)
                  .matches(
                    /^\+226[0567][0123456789]{7}$/,
                    `Numéro invalide ou incomplet ! (Ex: +22650123456)`
                  ),
              })
            ),
          }}
          submitButtonFullWidth
          submitButtonLabel="Modifier"
        >
          <div className="space-y-4 text-black md:space-y-6">
            <FormInput
              name="phoneNumber"
              label="Modifier le numéro de téléphone"
              placeholder={userDetailsData.phoneNumber}
              type="text"
            />
          </div>
        </Form>
      </Modal>
      <Modal
        isOpen={showUpdateMailModal}
        maxSize="md"
        closeModal={() => setshowUpdateMailModal(false)}
      >
        <Form<{ email: string }>
          onSubmit={onUserMailUpdate}
          form={{
            resolver: yupResolver(
              yup.object().shape({
                email: yup.string().required("Le champs est obligatoire"),
              })
            ),
          }}
          submitButtonFullWidth
          submitButtonLabel="Modifier"
        >
          <div className="space-y-4 text-black md:space-y-6">
            <FormInput
              name="email"
              label="Modifier l'adresse mail"
              placeholder=""
              type="text"
            />
          </div>
        </Form>
      </Modal>
      <Modal
        isOpen={showUpdateUserAccountCapModal}
        maxSize="md"
        closeModal={() => setShowUpdateUserAccountCapModal(false)}
      >
        <Form<{ accountCapAmount: string; isVipAccount: boolean }>
          onSubmit={updateUserAccountCap}
          form={{
            resolver: yupResolver(
              yup.object().shape({
                isVipAccount: yup.boolean().optional(),
                accountCapAmount: yup
                  .string()
                  .required("Le champs est obligatoire"),
              })
            ),
            defaultValues: {
              accountCapAmount: userDetailsData?.accountCapAmount
                ? userDetailsData?.accountCapAmount
                : `${alertConfig}`,
              isVipAccount: userDetailsData?.isVipAccount ?? false,
            },
          }}
          submitButtonFullWidth
          submitButtonLabel="Modifier"
        >
          <div className="space-y-4 text-black md:space-y-6">
            <FormCheckToggle name="isVipAccount" label="Active compte VIP" />
            <FormInput
              name="accountCapAmount"
              label="Le plafond du compte"
              placeholder="10 000 000"
              type="text"
            />
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default UserDetailsPageComponent;
