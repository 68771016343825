// import { UserDto } from '@';

import { createContext } from "react";
import {
  AccountInterface,
  UserInterface,
  UserNotificationInterface,
} from "../interfaces";
export const UserContext = createContext<{
  user: UserInterface | undefined;
  agencyAgent: UserInterface | undefined;
  userAccounts: AccountInterface[] | undefined;
  userNotification: UserNotificationInterface[] | undefined;
  superMerchantAffiliatedMerchants: any[] | undefined;
  gasStationInfo: any | undefined;
  company: any | undefined;
  agentCreateUserParams: any;
  setUser: any;
  setAgencyAgent: any;
  setUserAccounts: any;
  setUserNotification: any;
  isLoading: boolean;
  setIsLoading: any;
  merchantAffiliatedToSuperMerchant: boolean;
  setMerchantAffiliatedToSuperMerchant: any;
  setGasStationInfo: any;
  setCompany: any;
  setAgentCreateUserParams: any;
  setSuperMerchantAffiliatedMerchants: any;
}>({
  user: undefined,
  agencyAgent: undefined,
  userAccounts: undefined,
  userNotification: undefined,
  gasStationInfo: undefined,
  company: undefined,
  agentCreateUserParams: undefined,
  superMerchantAffiliatedMerchants: undefined,
  setUser: null,
  setAgencyAgent: null,
  setUserAccounts: null,
  setUserNotification: null,
  isLoading: false,
  merchantAffiliatedToSuperMerchant: true,
  setIsLoading: null,
  setMerchantAffiliatedToSuperMerchant: null,
  setGasStationInfo: null,
  setCompany: null,
  setAgentCreateUserParams: null,
  setSuperMerchantAffiliatedMerchants: null,
});
