import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  FormInput,
  Form,
  FormTextArea,
} from "../../../../components/FormComponents";
import CodePinComponent from "../../../../components/CodePinComponent";
import Modal from "../../../../components/Modal";
import { functions } from "../../../../firebase";
import toast from "react-hot-toast";
import { defaultAmount } from "../../../../config";
import { isNumeric } from "../../../../utils";

const MoveFeesToSankMainAccount = () => {
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [isCodePinBtnDesabled, setIsCodePinBtnDesabled] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [operationData, setOperationData] = useState<any>();

  const adminMoveFeesToSankMainAccountFn = functions.httpsCallable(
    "adminMoveFeesToSankMainAccountFn"
  );

  const onSubmit = (pincode: any) => {
    if (!isNumeric(operationData.amount)) {
      setShowPinCodeModal(false);
      setIsLoad(false);
      setIsCodePinBtnDesabled(false);
      return toast.error(`Le montant n'est pas bien formatté`);
    }
    if (parseInt(operationData.amount) < parseInt(defaultAmount)) {
      setShowPinCodeModal(false);
      setIsLoad(false);
      setIsCodePinBtnDesabled(false);
      return toast.error(
        `Le montant doit être suppérieur à ${defaultAmount} Fcfa`
      );
    }
    setIsCodePinBtnDesabled(true);
    setIsLoad(true);
    const paiementData = {
      description: operationData.description ?? "",
      amount: operationData.amount,
      pinCode: pincode,
    };
    adminMoveFeesToSankMainAccountFn(paiementData)
      .then(() => {
        setIsLoad(false);
        setShowPinCodeModal(false);
        setIsCodePinBtnDesabled(false);
        toast.success("Le transfer a été effectuer avec succès.");
      })
      .catch((error) => {
        toast.error("Une erreur s'est produite lors du transfer.");
        setIsCodePinBtnDesabled(false);
        setIsLoad(false);
      });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-5 sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h4 className="py-2 text-xl font-semibold text-gray-900">
            Effectuer le transfert des frais de transactions sur le compte
            principal de SankMoney
          </h4>
        </div>
      </div>
      <div className="inline-block py-2">
        <Form<{
          description: string;
          amount: string;
        }>
          onSubmit={(data) => {
            setOperationData(data);
            setShowPinCodeModal(true);
          }}
          submitButtonLabel="Transférer"
          form={{
            resolver: yupResolver(
              yup.object().shape({
                description: yup.string().optional(),
                amount: yup.string().required("Entrez un montant"),
              })
            ),
          }}
        >
          <div className="px-1 bg-white border border-gray-200 rounded-lg">
            <FormInput
              type="text"
              name="amount"
              placeholder="30000000"
              label="Le montant à Transférer."
              helpLabel="Le montant correspond au montant que vous souhaitez transférer sur le compte principal."
            />
            <FormTextArea name="description" nbCols={50} label="Description." />
          </div>
        </Form>
      </div>
      <Modal
        isOpen={showPinCodeModal}
        maxSize="md"
        closeModal={() => setShowPinCodeModal(false)}
      >
        <div className="flex justify-center">
          <CodePinComponent
            onSubmit={onSubmit}
            disabled={isCodePinBtnDesabled}
            isLoad={isLoad}
          />
        </div>
      </Modal>
    </div>
  );
};

export default MoveFeesToSankMainAccount;
