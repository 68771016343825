import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../contexts";
import {
  accountsCollectionName,
  usersCollectionName,
  transactionCollectionName,
} from "../../config";
import { db } from "../../firebase";
import GenerateQrcode from "../../components/Qrcode/GenerateQrcode";
import HasRoles from "../../components/HasRoles";
import UserDetailsPageComponent from "../../components/UserDetailsComponent";
import FirestoreTable, {
  IColumn,
  IRow,
  RowRenderFunction,
} from "../../components/FirestoreTable";
import Moment from "react-moment";
import { get } from "http";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { XCircleIcon } from "@heroicons/react/24/outline";

const columns: IColumn[] = [
  {
    field: "isComplete",
    label: "Statut",
  },
  {
    field: "amount",
    label: "Montant",
  },
  {
    field: "senderDisplayName",
    label: "Nom de l'expéditeur",
  },
  {
    field: "senderPhoneNumber",
    label: "Expéditeur",
  },
  {
    field: "receiverPhoneNumber",
    label: "Destinataire",
  },
  {
    field: "transactionType",
    label: "Type d'opération",
  },
  {
    field: "createdAt",
    label: "Date de création",
  },
];

// create a map of transaction type to display name
const transactionTypeMap = new Map([
  ["WITHDRAW", "Retrait"],
  ["RELOAD", "Dépôt"],
]);

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "senderPhoneNumber":
      return <span>{value}</span>;
    case "receiverPhoneNumber":
      return <span>{value}</span>;
    case "amount":
      return <span>{new Intl.NumberFormat().format(value)} Fcfa</span>;
    case "transactionType":
      return <span>{transactionTypeMap.get(value)} </span>;
    case "isComplete":
      return value ? (
        <CheckCircleIcon className="h-6 w-6 text-green-500" />
      ) : (
        <XCircleIcon className="h-6 w-6 text-red-500" />
      );
    default:
      return value as string;
  }
};

const AgentDetailsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = location.state?.entity;
  const isSearch = location.state?.isSearch;
  const userRef = db.collection(usersCollectionName);
  const [agentAccounts, setagentAccounts] = useState<any[]>([]);
  const [dealer, setDealer] = useState<any>();
  const [showQrcodeGenerateModal, setShowQrcodeGenerateModal] = useState(false);
  const { user } = useContext(UserContext);
  const [totalReload, setTotalReload] = useState(0);
  const [totalWithdraw, setTotalWithdraw] = useState(0);

  const retriveDealerInfos = async () => {
    if (currentUser?.dealerId) {
      await userRef
        .doc(currentUser?.dealerId)
        .get()
        .then((response) => {
          setDealer({ id: response.id, data: response.data() });
        });
    }
  };

  // get the totoal amount of the agent reload
  const getAgentTotalReload = async () => {
    const totalReload = await db
      .collection(transactionCollectionName)
      .where("participantsIds", "array-contains", currentUser?.id)
      .where("transactionType", "==", "RELOAD")
      .get();
    return totalReload.docs.reduce((acc, doc) => {
      return acc + parseInt(doc.data().amount);
    }, 0);
  };

  // get the total amount of the agent withdraw
  const getAgentTotalWithdraw = async () => {
    const totalWithdraw = await db
      .collection(transactionCollectionName)
      .where("participantsIds", "array-contains", currentUser?.id)
      .where("transactionType", "==", "WITHDRAW")
      .get();
    return totalWithdraw.docs.reduce((acc, doc) => {
      return acc + parseInt(doc.data().amount);
    }, 0);
  };

  useEffect(() => {
    if (currentUser) {
      userRef
        .doc(currentUser?.id)
        .collection(accountsCollectionName)
        .get()
        .then((response) => {
          setagentAccounts(
            response.docs.map((d) => {
              return d.data();
            })
          );
        });
      retriveDealerInfos();
      getAgentTotalReload().then((total) => setTotalReload(total));
      getAgentTotalWithdraw().then((total) => setTotalWithdraw(total));
    }
  }, []);

  return isSearch ? (
    <UserDetailsPageComponent
      userDetailsData={currentUser}
      makeDealer={false}
      OwnUserRole={user?.userRole}
    />
  ) : (
    <>
      <div className="p-16">
        <div className="p-8 mt-24 bg-white shadow">
          <div className="grid grid-cols-1 md:grid-cols-3">
            <div className="grid order-last grid-cols-3 mt-20 text-center md:order-first md:mt-0">
              <HasRoles userRole={["ADMIN", "AGENCYSTAFF", "SUPERVISOR"]}>
                <>
                  {agentAccounts &&
                    agentAccounts.map((account, index) => (
                      <div key={index}>
                        <p className="text-xl font-bold text-gray-700">
                          {new Intl.NumberFormat().format(account.balance ?? 0)}
                          Fcfa
                        </p>
                        <p className="text-gray-400">{account.accountType} </p>
                      </div>
                    ))}
                </>
              </HasRoles>
              <HasRoles userRole={["DEALER"]}>
                <>
                  {agentAccounts && (
                    <div>
                      <p className="text-xl font-bold text-gray-700">
                        {new Intl.NumberFormat().format(
                          agentAccounts[0]?.balance ?? 0
                        )}
                        Fcfa
                      </p>
                      <p className="text-gray-400">
                        {agentAccounts[0]?.accountType}{" "}
                      </p>
                    </div>
                  )}
                </>
              </HasRoles>
            </div>
            <div className="relative">
              <div className="absolute inset-x-0 top-0 flex items-center justify-center w-48 h-48 mx-auto -mt-24 text-indigo-500 bg-indigo-100 rounded-full shadow-2xl">
                <img
                  className="w-24 h-24 p-1 border rounded-full"
                  src={
                    currentUser?.profilImageUrl
                      ? currentUser?.profilImageUrl
                      : `https://ui-avatars.com/api/?background=random&name=${
                          currentUser?.displayName || "S"
                        }`
                  }
                  alt=""
                />
              </div>{" "}
            </div>{" "}
            <div className="flex justify-between mt-32 space-x-8 md:mt-0 md:justify-center">
              <button
                onClick={() => {
                  navigate("/transfer/reload");
                }}
                className="items-center px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition transform hover:-translate-y-0.5"
              >
                {" "}
                Recharger
              </button>{" "}
              <button
                onClick={() => {
                  setShowQrcodeGenerateModal(true);
                }}
                className="items-center px-4 py-2 text-sm font-medium text-green-700 bg-white border border-green-300 rounded-md shadow-sm hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition transform hover:-translate-y-0.5"
              >
                Qrcode
              </button>{" "}
            </div>{" "}
          </div>{" "}
          <div className="pb-12 mt-20 text-center border-b">
            {" "}
            <h1 className="text-4xl font-medium text-gray-700">
              {currentUser.displayName},
              <span className="font-light text-gray-500">
                {currentUser.phoneNumber}
              </span>{" "}
            </h1>{" "}
            <p className="mt-3 font-light text-gray-600">{currentUser.email}</p>{" "}
            {dealer && (
              <p className="mt-8 text-gray-500">
                L'agent est affilié à{" "}
                <em>
                  <b>{dealer.data?.displayName}</b>
                </em>
              </p>
            )}
            <p className="mt-2 text-gray-500">
              Date de création du compte:{" "}
              {new Date(
                currentUser.createdAt.seconds * 1000 +
                  currentUser.createdAt.nanoseconds / 1000000
              ).toLocaleDateString()}
            </p>{" "}
            <p className="mt-2 text-gray-500">
              Total des depots: {new Intl.NumberFormat().format(totalReload)}{" "}
              Fcfa
            </p>{" "}
            <p className="mt-2 text-gray-500">
              Total des retraits:{" "}
              {new Intl.NumberFormat().format(totalWithdraw)} Fcfa
            </p>{" "}
          </div>{" "}
        </div>
      </div>
      <div className="shadow-sm ring-1 ring-black ring-opacity-5">
        <FirestoreTable
          collectionRef={db.collection(transactionCollectionName)}
          collectionQueriesWhere={[
            {
              fieldPath: "participantsIds",
              opStr: "array-contains",
              value: currentUser?.id,
            },
          ]}
          condensed
          columns={columns}
          rowRender={renderRow}
          paginate={{
            pageIndex: 0,
            limit: 100,
            sort: "createdAt:desc",
          }}
        />
      </div>
      <GenerateQrcode
        showQrcodeReadeModal={showQrcodeGenerateModal}
        setShowQrcodeReadeModal={setShowQrcodeGenerateModal}
        currentUser={currentUser}
        currentUserAccount={agentAccounts}
      />
    </>
  );
};

export default AgentDetailsPage;
