import { ArrowSmallDownIcon, QrCodeIcon } from "@heroicons/react/24/outline";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import CodePinComponent from "../../../components/CodePinComponent";
import Modal from "../../../components/Modal";
import ReadeQrcode from "../../../components/Qrcode/ReadeQrcode";
import {
  usersCollectionName,
  accountsCollectionName,
  defaultAmount,
  PhoneNumberDefaultLength,
  userCardRelationCollection,
} from "../../../config";
import { UserContext } from "../../../contexts";
import { db, functions } from "../../../firebase";
import { useRouter } from "../../../hooks/useRouter";
import { AccountType, TransactionType, UserRole } from "../../../interfaces";
import { classNames, isNumeric } from "../../../utils";
import PhoneInput from "react-phone-number-input";

const TransfertUserToUser = () => {
  const [receiverData, setreceiverData] = useState<any>([]);
  const [qrcodeScannedData, setQrcodeScannedData] = useState<string[]>([]);
  const [isDataGet, setIsDataGet] = useState(false);
  const [amount, setAmount] = useState<string>(defaultAmount);
  const { user, setUserAccounts } = useContext(UserContext);
  const [showQrcodeReadeModal, setShowQrcodeReadeModal] = useState(false);
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [userTypedNumber, setUserTypedNumber] = useState("");
  const [receiverDataGetWithPhoneNumber, setreceiverDataGetWithPhoneNumber] =
    useState<any>(null);
  const router = useRouter();
  const [description, setDescription] = useState("");
  const [isCodePinBtnDesabled, setIsCodePinBtnDesabled] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [isNotExistOnSystem, setIsNotExistOnSystem] = useState(true);
  const [withCard, setWithCard] = useState<boolean>(false);

  const userRef = db.collection(usersCollectionName);
  const senderAccountRef = userRef
    .doc(user?.id)
    .collection(accountsCollectionName);
  const sankMoneyUserTransfertMoneyToUserFn = functions.httpsCallable(
    "sankMoneyUserTransfertMoneyToUserFn"
  );
  const handleChangeAmountValue = (e: any) => {
    e.preventDefault();
    setAmount(`${e.target.value}` ?? defaultAmount);
  };

  const handleDescriptionChange = (e: any) => {
    e.preventDefault();
    const value = e.target.value;
    setDescription(value);
  };

  const getReceiverData = async () => {
    setShowQrcodeReadeModal(false);
    if (qrcodeScannedData.length > 0 && !isDataGet) {
      const response = await db
        .collection(usersCollectionName)
        .doc(qrcodeScannedData[0])
        .get();

      if (response.exists) {
        setIsDataGet(true);
        const data = response.data();
        setreceiverData([data]);
        return data;
      } else {
        await db
          .collection(userCardRelationCollection)
          .where("cardId", "==", qrcodeScannedData[0])
          .get()
          .then(async (response) => {
            setIsDataGet(true);
            if (response.empty) {
              setreceiverData([]);
              return;
            } else {
              setWithCard(true);
              await db
                .collection(usersCollectionName)
                .doc(response.docs[0].data()?.userId)
                .get()
                .then((res) => {
                  setIsDataGet(true);
                  const data = res.data();
                  setreceiverData([data]);
                  return data;
                });
            }
          });
      }
    }
  };

  const getFilterdByPhoneNumber = async (phoneNumber: any) => {
    if (phoneNumber !== undefined) {
      setUserTypedNumber(phoneNumber);
      if (phoneNumber?.length >= PhoneNumberDefaultLength + 4) {
        const destUserSnapshot = await db
          .collection(usersCollectionName)
          .where("phoneNumber", "==", phoneNumber)
          .where("userRole", "==", UserRole.USER)
          .get();
        if (destUserSnapshot.empty) {
          setIsNotExistOnSystem(true);
          toast.error("Le numéro n'est pas un utilisateur");
        } else {
          const destreceiverData = [];
          for (const destUserDoc of destUserSnapshot.docs) {
            const destUserdAllata = destUserDoc.data();
            const destUserAccountSnapshot = await destUserDoc.ref
              .collection(accountsCollectionName)
              .get();
            const destUserAccountData = destUserAccountSnapshot.docs
              .map((accountDoc) => accountDoc.data())
              .find((data) => data.accountType === AccountType.MAIN);
            const destUserWithAccounts = {
              account: destUserAccountData,
              ...destUserdAllata,
            };
            destreceiverData.push(destUserWithAccounts);
          }
          return setreceiverDataGetWithPhoneNumber(destreceiverData[0]);
        }
      }
    } else {
      setreceiverDataGetWithPhoneNumber(null);
    }
  };

  const onSubmit = async (pinCode: string) => {
    setIsCodePinBtnDesabled(true);
    if (!isNumeric(amount)) {
      setIsCodePinBtnDesabled(false);
      setShowPinCodeModal(false);
      return toast.error(`Le montant n'est pas bien formatté`);
    }
    if (amount < defaultAmount) {
      setIsCodePinBtnDesabled(false);
      setShowPinCodeModal(false);
      return toast.error(
        `Le montant doit être suppérieur à ${defaultAmount} Fcfa`
      );
    }
    if (userTypedNumber.length < PhoneNumberDefaultLength + 4) {
      setIsCodePinBtnDesabled(false);
      setShowPinCodeModal(false);
      return toast.error(
        `Le numéro de l'utilisateur n'est pas valide. ${userTypedNumber}`
      );
    }
    if (
      (user && (receiverDataGetWithPhoneNumber || receiverData.length > 0)) ||
      isNotExistOnSystem
    ) {
      if (
        user?.id === (receiverDataGetWithPhoneNumber || receiverData[0])?.id
      ) {
        toast.error("Vous ne pouvez pas effectuer cet type de transaction");
        setIsCodePinBtnDesabled(false);
      } else {
        setIsLoad(true);
        await sankMoneyUserTransfertMoneyToUserFn({
          pinCode: pinCode,
          amount: amount.replace(/\s/g, "").trim(),
          senderPhoneNumber: user?.phoneNumber,
          receiverPhoneNumber:
            receiverData.length > 0
              ? receiverData[0]?.phoneNumber
              : receiverDataGetWithPhoneNumber
              ? receiverDataGetWithPhoneNumber?.phoneNumber
              : isNotExistOnSystem && userTypedNumber,
          description: description ?? "",
          transactionType: TransactionType.TRANSFER,
          isCardPayment: withCard ? true : false,
          cardId: qrcodeScannedData[0] ?? "",
          appPlatform: "web",
          withConnection: true,
        })
          .then(async (result) => {
            setWithCard(false);
            setShowPinCodeModal(false);
            setIsLoad(false);
            router.push("/dashboard");
            const accounts = await senderAccountRef.get();
            setUserAccounts(accounts.docs.map((account) => account.data()));
            return toast.success("Le transfert a été effectuer avec succès");
          })
          .catch((err: { details: any; message: any }) => {
            setShowPinCodeModal(false);
            setIsCodePinBtnDesabled(false);
            setIsLoad(false);
            setWithCard(false);
            toast.error(err.message);
          });
      }
    }
  };

  const onQrcodeResult = (result: any) => {
    setShowQrcodeReadeModal(false);
    if (result && !isDataGet) {
      setQrcodeScannedData(result);
      setShowQrcodeReadeModal(false);
      getReceiverData();
    }
  };
  const onQrcodeError = (error: any) => {
    setShowQrcodeReadeModal(false);
  };
  return (
    <div className="flex justify-center w-full">
      <div className="w-full max-w-md px-2 py-16 sm:px-0">
        <div className="flex items-center justify-center w-full font-manrope">
          <div className="box-border w-full p-4 mx-auto bg-white border">
            <div className="flex justify-end text-red-500">
              <QrCodeIcon
                className="w-6 h-6 cursor-pointer text-red-red-500"
                aria-hidden="true"
                onClick={() => {
                  setShowQrcodeReadeModal(true);
                }}
              />
            </div>
            {receiverData.length > 0 ? (
              <div></div>
            ) : (
              <div className="mt-6">
                <div className="font-semibold">
                  Entrez le numéro du destinataire
                </div>
                <div>
                  <PhoneInput
                    international={false}
                    placeholder="Entrez un numéro de téléphone"
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                    defaultCountry="BF"
                    onChange={getFilterdByPhoneNumber}
                  />

                  {!receiverDataGetWithPhoneNumber ? (
                    receiverData.length > 0 ? (
                      <div></div>
                    ) : (
                      userTypedNumber.length !== PhoneNumberDefaultLength + 4 &&
                      userTypedNumber.length > 0 && (
                        <p className="mt-1 text-xs text-red-500">
                          Numéro invalide
                        </p>
                      )
                    )
                  ) : (
                    <p className="mt-1 text-xs text-green-500">Numéro valide</p>
                  )}
                </div>
              </div>
            )}
            <div className="mt-6">
              <div className="font-semibold">
                Combien souhaitez-vous envoyer ?
              </div>
              <div>
                <input
                  className="mt-1 w-full rounded-[4px] border border-[#A0ABBB] p-2"
                  type="text"
                  placeholder="Ex: 1000"
                  pattern="[0-9]{9}"
                  min={0}
                  onChange={handleChangeAmountValue}
                />
              </div>
            </div>
            <div className="mt-6">
              <div className="font-semibold">
                Entrez une descriptions de la transaction
              </div>
              <div>
                <textarea
                  id="message"
                  rows={4}
                  onChange={handleDescriptionChange}
                  maxLength={200}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Écrivez votre description ici..."
                ></textarea>
              </div>
            </div>
            <div className="mt-6">
              <div className="flex items-center gap-x-[10px] bg-neutral-100 p-3 mt-2 rounded-[4px]">
                <img
                  className="w-10 h-10 rounded-full"
                  src={
                    user?.profilImageUrl
                      ? user?.profilImageUrl
                      : `https://ui-avatars.com/api/?background=random&name=${
                          user?.displayName || "S"
                        }`
                  }
                  alt=""
                />
                <div>
                  <div className="font-semibold">{user?.displayName}</div>
                </div>
              </div>
            </div>
            {(receiverDataGetWithPhoneNumber || receiverData.length > 0) && (
              <>
                <div className="flex justify-center mt-2">
                  <ArrowSmallDownIcon className="h-6 text-red-500" />
                </div>
                <div className="mt-2">
                  <div className="flex items-center gap-x-[10px] bg-neutral-100 p-3 mt-2 rounded-[4px]">
                    <img
                      className="w-10 h-10 rounded-full"
                      src={
                        (receiverDataGetWithPhoneNumber || receiverData[0])
                          ?.profilImageUrl
                          ? (receiverDataGetWithPhoneNumber || receiverData[0])
                              ?.profilImageUrl
                          : `https://ui-avatars.com/api/?background=random&name=${
                              (
                                receiverDataGetWithPhoneNumber ||
                                receiverData[0]
                              )?.displayName || "S"
                            }`
                      }
                      alt=""
                    />
                    <div>
                      <div className="font-semibold">
                        {
                          (receiverDataGetWithPhoneNumber || receiverData[0])
                            ?.displayName
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="mt-6">
              <button
                className={classNames(
                  amount ? "bg-red-600  cursor-pointer" : "bg-gray-600",
                  "w-full rounded-[4px]  px-3 py-[6px] text-center font-semibold text-white"
                )}
                onClick={() => amount && setShowPinCodeModal(true)}
              >
                Recharger :{" "}
                {new Intl.NumberFormat().format(parseInt(amount ?? "0"))} Fcfa
              </button>
            </div>
          </div>
        </div>
      </div>
      <ReadeQrcode
        onQrcodeResult={(result: any) => onQrcodeResult(result)}
        onQrcodeError={(error: any) => onQrcodeError(error)}
        showQrcodeReadeModal={showQrcodeReadeModal}
        setShowQrcodeReadeModal={setShowQrcodeReadeModal}
      />
      <Modal
        isOpen={showPinCodeModal}
        maxSize="md"
        closeModal={() => setShowPinCodeModal(false)}
      >
        <div className="flex justify-center">
          <CodePinComponent
            onSubmit={onSubmit}
            disabled={isCodePinBtnDesabled}
            isLoad={isLoad}
          />
        </div>
      </Modal>
    </div>
  );
};

export default TransfertUserToUser;
