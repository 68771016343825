import { ArrowSmallDownIcon } from "@heroicons/react/24/outline";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import CodePinComponent from "../../../components/CodePinComponent";
import Modal from "../../../components/Modal";
import {
  defaultAmount,
  companyCollectionName,
  usersCollectionName,
  accountsCollectionName,
} from "../../../config";
import { UserContext } from "../../../contexts";
import { db, functions } from "../../../firebase";
import { useRouter } from "../../../hooks/useRouter";
import { isNumeric, classNames, amountFormater } from "../../../utils";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

const AgencyReloadCompaniesPage = () => {
  const [amount, setAmount] = useState<string>(defaultAmount);
  const { user, setUserAccounts, agencyAgent } = useContext(UserContext);
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [company, setCompany] = useState<any>(null);
  const router = useRouter();
  const [description, setDescription] = useState("");
  const [isCodePinBtnDesabled, setIsCodePinBtnDesabled] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const userRef = db.collection(usersCollectionName);
  const senderAccountRef = userRef
    .doc(agencyAgent ? agencyAgent?.id : user?.id)
    .collection(accountsCollectionName);
  const sankMoneyReloadCompanyAccountFn = functions.httpsCallable(
    "sankMoneyReloadCompanyAccountFn"
  );

  const handleChangeAmountValue = (e: any) => {
    e.preventDefault();
    setAmount(`${e.target.value}` ?? defaultAmount);
  };

  const handleDescriptionChange = (e: any) => {
    e.preventDefault();
    const value = e.target.value;
    setDescription(value);
  };

  const onSubmit = async (pinCode: string) => {
    setIsCodePinBtnDesabled(true);
    if (!isNumeric(amount)) {
      setShowPinCodeModal(false);
      return toast.error(`Le montant n'est pas bien formatté`);
    }
    if (amount < defaultAmount) {
      setShowPinCodeModal(false);
      return toast.error(
        `Le montant doit être suppérieur à ${defaultAmount} Fcfa`
      );
    }
    if (user && company) {
      if (user?.id === company?.id) {
        toast.error("Vous ne pouvez pas effectuer cet type de transaction");
        setIsCodePinBtnDesabled(false);
      } else {
        setIsLoad(true);
        await sankMoneyReloadCompanyAccountFn({
          pinCode: pinCode,
          amount: amount,
          agencyAccountId: agencyAgent?.id,
          currentUserId: user?.id,
          companyId: company && company?.id,
          description: description ?? "",
          withConnection: true,
        })
          .then(async () => {
            setShowPinCodeModal(false);
            setIsLoad(false);
            router.push("/dashboard");
            const accounts = await senderAccountRef.get();
            setUserAccounts(accounts.docs.map((account) => account.data()));
            return toast.success("La recharge a été effectuer avec succès");
          })
          .catch((err: { details: any; message: any }) => {
            setShowPinCodeModal(false);
            setIsCodePinBtnDesabled(false);
            setIsLoad(false);
            toast.error(err.message);
          });
      }
    }
  };

  const retriveCompanyData = async (phoneNumber: any) => {
    if (phoneNumber !== undefined) {
      if (isValidPhoneNumber(phoneNumber)) {
        const destUserSnapshot = await db
          .collection(companyCollectionName)
          .where("phoneNumber", "==", phoneNumber)
          .get();
        if (destUserSnapshot.empty) {
          return toast.error("La company n'existe pas.");
        }
        setCompany(
          destUserSnapshot.docs.map((doc) => {
            return { id: doc.id, data: doc.data() };
          })[0]
        );
      } else {
        setCompany(null);
        return toast.error("Le numéro est invalide.");
      }
    }
  };
  return (
    <div className="flex justify-center w-full">
      <div className="w-full max-w-md px-2 py-16 sm:px-0">
        <div className="flex items-center justify-center w-full font-manrope">
          <div className="box-border w-full p-4 mx-auto bg-white border">
            <div className="mt-6">
              <div className="font-semibold">
                Recherche par le numéro de l'entreprise
              </div>
              <div>
                <div className="items-center justify-between flex-1 max-w-sm">
                  <PhoneInput
                    international={false}
                    placeholder="Entrez un numéro de téléphone"
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                    defaultCountry="BF"
                    onChange={retriveCompanyData}
                  />
                </div>

                {!company ? (
                  <p className="mt-1 text-xs text-red-500">numéro invalide</p>
                ) : (
                  <p className="mt-1 text-xs text-green-500">numéro valide</p>
                )}
              </div>
            </div>
            <div className="mt-6">
              <div className="font-semibold">
                Combien souhaitez-vous envoyer ?
              </div>
              <div>
                <input
                  className="mt-1 w-full rounded-[4px] border border-[#A0ABBB] p-2"
                  type="text"
                  placeholder="Ex: 1000"
                  pattern="[0-9]{9}"
                  min={0}
                  onChange={handleChangeAmountValue}
                />
              </div>
            </div>
            <div className="mt-6">
              <div className="font-semibold">
                Entrez une descriptions de la transaction
              </div>
              <div>
                <textarea
                  id="message"
                  rows={4}
                  onChange={handleDescriptionChange}
                  maxLength={200}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  placeholder="Écrivez votre description ici..."
                ></textarea>
              </div>
            </div>
            <div className="mt-6">
              <div className="flex items-center gap-x-[10px] bg-neutral-100 p-3 mt-2 rounded-[4px]">
                <img
                  className="w-10 h-10 rounded-full"
                  src={
                    agencyAgent?.profilImageUrl
                      ? agencyAgent?.profilImageUrl
                      : `https://ui-avatars.com/api/?background=random&name=${
                          agencyAgent?.displayName || "S"
                        }`
                  }
                  alt=""
                />
                <div>
                  <div className="font-semibold">
                    {agencyAgent?.displayName}
                  </div>
                </div>
              </div>
            </div>
            {company && (
              <>
                <div className="flex justify-center mt-2">
                  <ArrowSmallDownIcon className="h-6 text-red-500" />
                </div>
                <div className="mt-2">
                  <div className="flex items-center gap-x-[10px] bg-neutral-100 p-3 mt-2 rounded-[4px]">
                    <img
                      className="w-10 h-10 rounded-full"
                      src={
                        company?.data?.profilImageUrl
                          ? company?.data?.profilImageUrl
                          : `https://ui-avatars.com/api/?background=random&name=${
                              company?.data?.displayName || "S"
                            }`
                      }
                      alt=""
                    />
                    <div>
                      <div className="font-semibold">
                        {company?.data?.displayName}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="mt-6">
              <div
                className={classNames(
                  amount ? "bg-red-600  cursor-pointer" : "bg-gray-600",
                  "w-full rounded-[4px]  px-3 py-[6px] text-center font-semibold text-white"
                )}
                onClick={() => amount && setShowPinCodeModal(true)}
              >
                Recharger: {amountFormater(parseInt(amount ?? "0"))} Fcfa
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showPinCodeModal}
        maxSize="md"
        closeModal={() => setShowPinCodeModal(false)}
      >
        <div className="flex justify-center">
          <CodePinComponent
            onSubmit={onSubmit}
            disabled={isCodePinBtnDesabled}
            isLoad={isLoad}
          />
        </div>
      </Modal>
    </div>
  );
};

export default AgencyReloadCompaniesPage;
