import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../../contexts";
import imageSrc from "../../../sankQrCodeImg.png";
import CardImage from "../../../340_x_200_1.png";
import SankMoneyIcon from "../../../CFA.png";
import { transactionCollectionName } from "../../../config";
import { db } from "../../../firebase";
import { BoltIcon, CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { Timestamp } from "firebase-admin/firestore";
import { QRCodeCanvas } from "qrcode.react";
import Pagination from "../../../components/Pagination/Pagination";
import NoContent from "../../../components/TableNoContentPage/NoContent";
import { parseObjectDate, classNames, getTransactionLabel } from "../../../utils";

const tableHeader = [
  "N°",
  "Nom destinataire",
  "Numéro destinataire",
  "Montant",
  "Type de transaction",
  "Compte",
  "Frais",
  "Status",
  "Date de création",
];

const CardTranactionHistories = () => {
  const location = useLocation();
  const currentCard = location.state?.entity;
  const [histories, setHistories] = useState<any[]>([]);
  const [defaultLoadHistories, setDefaultLoadHistories] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { user } = useContext(UserContext);
  const imageOptions = {
    src: imageSrc,
    x: undefined,
    y: undefined,
    height: 30,
    width: 30,
    excavate: true,
  };

  const getAllHistories = async (pageLimit: number) => {
    await db
      .collection(transactionCollectionName)
      .orderBy("createdAt", "desc")
      .where("participantsIds", "array-contains", currentCard?.id)
      .limit(pageLimit)
      .get()
      .then((response) => {
        setDefaultLoadHistories(response?.size);
        setHistories(
          response.docs.map((d) => {
            return { id: d.id, data: d.data() };
          })
        );
        setPage(1);
      });
  };

  useEffect(() => {
    currentCard && getAllHistories(10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Histroiques de transaction de la carte{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r to-red-600 from-red-900">
              {currentCard?.id}
            </span>
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Les transactions de la carte
          </p>
        </div>
      </div>
      <div className="lg:border-t lg:border-gray-200">
        <div className="mt-3 main-form">
          <div
            className="relative flex items-center justify-between w-full sm:w-[240px] sm:h-[150px] px-6 py-4 space-x-6 bg-cover rounded-md"
            style={{
              backgroundImage: `url(${CardImage})`,
              width: "340px",
              height: "200px",
            }}
          >
            <div className="absolute top-0 right-0 m-2 text-sm font-semibold text-white">
              SankMoney
            </div>
            <div className="absolute bottom-0 m-2 text-sm font-semibold text-white right-2">
              {currentCard?.id}
            </div>
            <div className="absolute top-0 left-0 m-2 text-sm font-light text-white">
              Debit
            </div>
            <div className="absolute top-10 right-3">
              <QRCodeCanvas
                className="w-full"
                id="qrCode"
                value={`${currentCard?.id}`}
                size={100}
                level={"L"}
                includeMargin={false}
                bgColor="red"
                fgColor="white"
                imageSettings={imageOptions}
              />
            </div>
            <div className="absolute bottom-0 m-2 text-xs font-light text-white left-2">
              Exp : {parseObjectDate(currentCard?.expiryDate)}
            </div>
          </div>

          <div className="flex flex-col mt-8">
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                  <table
                    className="min-w-full border-separate"
                    style={{ borderSpacing: 0 }}
                  >
                    <thead className="bg-gray-50">
                      <tr>
                        {tableHeader.map((th, index) => (
                          <th
                            key={index}
                            scope="col"
                            className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                          >
                            {th}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {histories && histories.length > 0 ? (
                        histories.map((history: any, historyIdx: number) => (
                          <tr key={historyIdx}>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {(historyIdx as number) + 1}
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {user?.id === history.data.senderID
                                ? history.data.receiverDisplayName
                                  ? history.data.receiverDisplayName
                                  : "-"
                                : history.data.senderDisplayName
                                ? history.data.senderDisplayName
                                : "-"}
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {user?.id === history.data.senderID
                                ? (history.data.receiverPhoneNumber as string)
                                : (history.data.senderPhoneNumber as string)}
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {new Intl.NumberFormat().format(
                                history.data.amount
                              )}{" "}
                              Fcfa
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {user?.id === history.data.senderID
                                ? getTransactionLabel(
                                    user?.id ?? "",
                                    history.data.transactionType,
                                    history.data.senderID
                                  )
                                : getTransactionLabel(
                                    user?.id ?? "",
                                    history.data.transactionType,
                                    history.data.receiverID
                                  )}
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {history.data.accountType === "MAIN" ? (
                                <img
                                  className="w-auto h-5"
                                  src={SankMoneyIcon}
                                  alt="Sank"
                                />
                              ) : (
                                // <CurrencyDollarIcon className="w-6 h-6 text-red-500" />
                                <BoltIcon className="w-6 h-6 text-red-500" />
                              )}
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {history.data.fees as string} Fcfa
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {history.data.isComplete ? (
                                <CheckCircleIcon className="w-6 h-6 text-green-500" />
                              ) : (
                                <XCircleIcon className="w-6 h-6 text-red-500" />
                              )}
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {(history.data.createdAt as Timestamp)
                                .toDate()
                                .toLocaleString()}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NoContent />
                      )}
                    </tbody>
                  </table>
                  {histories && histories.length > 0 && (
                    <Pagination
                      skip={defaultLoadHistories}
                      take={defaultLoadHistories}
                      total={defaultLoadHistories}
                      collectionName={transactionCollectionName}
                      isChangedPage={false}
                      onPageChange={(pageIndex: number) => pageIndex * page}
                      onPageLimitChange={(pageLimit: number) =>
                        getAllHistories(pageLimit)
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardTranactionHistories;
