import React, { useCallback, useEffect, useState } from "react";
import {
  agencyCollectionsName,
  gasStationCollectionName,
  gasStationMembershipCollectionName,
  usersCollectionName,
} from "../../../config";
import {
  UserIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import FirestoreTable, {
  IColumn,
  RowRenderFunction,
  IRow,
} from "../../../components/FirestoreTable";
import { db, functions } from "../../../firebase";
import useCrudDocument from "../../../hooks/useCrudDocument";
import { UserInterface, UserRole } from "../../../interfaces";
import Modal from "../../../components/Modal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {Form, FormInput } from "../../../components/FormComponents";


const columns: IColumn[] = [
  {
    field: "createdAt",
    label: "Date de création",
  },
  {
    field: "profilImageUrl",
    label: "Profil",
  },
  {
    field: "displayName",
    label: "Nom/prénom",
  },
  {
    field: "adresse",
    label: "Adresse",
  },
  {
    field: "phoneNumber",
    label: "Téléphone",
  },
  {
    field: "city",
    label: "Ville",
  },
  {
    field: "gasStationCode",
    label: "Code",
  },
  {
    field: "lat",
    label: "Latitude",
  },
  {
    field: "long",
    label: "Longitude",
  },
  {
    field: "active",
    label: "Active",
  },
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "displayName":
      return <span>{value ? value : "-"}</span>;
    case "profilImageUrl":
      return (
        <span>
          {" "}
          {value ? (
            <img
              className="w-10 h-10 p-1 border rounded-full"
              src={
                value
                  ? value
                  : `https://ui-avatars.com/api/?background=random&name=${
                      value || "S"
                    }`
              }
              alt=""
            />
          ) : (
            <UserIcon className="w-10 h-10 p-1 border rounded-full" />
          )}
        </span>
      );
    case "adresse":
      return <span>{value ?? "-"}</span>;
    case "gasStationCode":
      return <span>{value}</span>;
    case "city":
      return <span>{value ? value : "-"}</span>;
    case "phoneNumber":
      return <span>{value ? value : "-"}</span>;
    case "lat":
      return <span>{value ? value : "-"}</span>;
    case "long":
      return <span>{value ? value : "-"}</span>;
    case "active":
      return (
        <span>
          {value ? (
            <CheckCircleIcon className="w-6 h-6 text-green-500" />
          ) : (
            <XCircleIcon className="w-6 h-6 text-red-500" />
          )}
        </span>
      );
    default:
      return value ? value : "-";
  }
};
const AllGasStationListPage = () => {
  let refreshDataFn: (() => void) | undefined = undefined;
  const [code, setCode] = useState<string>("");
  const [changeManager, setChangeManager] = useState<boolean>(false);
  const [gasStationMemberId, setGasStationMemberId] = useState<any[]>([])
  const [managerPhoneNumber, setManagerPhoneNumber] = useState<any[]>([])

  const onRowChangeManager = async (id: number, entity: any)=>{
    const snap = await db
    .collection( gasStationMembershipCollectionName)
    .where("gasStationId", "==", entity.id)
    .get()
    if(snap.empty){
      toast.error("Cette station ne possède pas de manager")
      setChangeManager(false)
      navigate("/gas-stations")
    }else{
      setChangeManager(true)  
      gasStationMemberId.push(snap.docs[0].data()) 
    }
  }


  const navigate = useNavigate();
  const { deleteDocument } = useCrudDocument();

  const onEditRow = (id: number, entity: any) => {
    if (entity) {
      navigate(`/gas-stations/${entity.id}/edit`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };
  const onAddManager = async (id: number, entity: any) => {
    const snap = await db
    .collection( gasStationMembershipCollectionName)
    .where("gasStationId", "==", entity.id)
    .get()
    if(!snap.empty){
      toast.error("Cette station possède deja un manager")
      navigate("/gas-stations")
    }else{
      if (entity) {
        navigate(`/gas-stations/${entity.id}/edit`, {
        state: { entity: entity, id: entity.id, affiliate: true },
        });
      }
    }
  };
  const onRowDetails = (id: number, entity: any) => {
    if (entity) {
      navigate(`/gas-stations/${entity.id}/details`, {
        state: { entity: entity, id: entity.id },
      });    
    }
  };
  const onDeleteRow = (id: number, row: any) => {
    if (row) {
      toast.promise(
        deleteDocument(row.id, agencyCollectionsName),
        {
          loading: "Suppression...",
          success: "L'agent a été supprimé avec succès.",
          error: "Échec ! Veuillez réessayer.",
        }
      );
    }
  };
  const sankMoneyUpdateUserNumberOrEmailFn = functions.httpsCallable(
    "sankMoneyUpdateUserNumberOrEmailFn"
  );

  const adminResetPasswordFn = functions.httpsCallable(
    "adminResetPasswordFn"
  );

  const onResetPassword = async () => {  
    const snap = await db
    .collection(usersCollectionName)
    .where("id", "==", gasStationMemberId[0].memberId)
    .get()
    if(!snap.empty){
      managerPhoneNumber.push(snap.docs[0].data());
    }
  };

  const onSubmit = useCallback(async (data: any)=>{ 
      onResetPassword()
     const response = sankMoneyUpdateUserNumberOrEmailFn({
      userId: gasStationMemberId[0].memberId,
      email: data.email,
    })
    setChangeManager(false)
    navigate("/gas-stations")
     toast.promise(response
      .then(()=>{
      const responses = adminResetPasswordFn({
        email: data.email,
        phoneNumber: managerPhoneNumber[0].phoneNumber,
      });
      toast.promise(responses, {
        error: (err) => `${err}`,
        success: "Le mot de passe a été reinitialiser avec succès.",
        loading: "chargement...",
      });
    }), {
      error: "Une erreur s'est produite. Veuillez réessayer !",
      success:
        "Les informations du manager ont été mise à jour avec succès.",
      loading: "chargement...",
    });
    
  }, [])
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-5 sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Les stations-services
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Vous trouverez ici l'ensemble des stations-services.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="relative inline-flex items-center justify-center p-0.5 mb-1 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-purple-200 "
            onClick={() => {
              navigate("/gas-stations/new");
            }}
          >
            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
              Ajouter une station-service
            </span>
          </button>
        </div>
        <div className="my-auto">
              <input
                type="search"
                id="search"
                className="block w-full p-4 pl-10 text-sm h-11 text-gray-900 border border-gray-300 rounded-md bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                placeholder="code"
                onChange={(e)=> setCode(e.target.value)}
                value={code}
              />
            </div>
        </div>
      <div className="flex flex-col mt-8">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="shadow-sm ring-1 ring-black ring-opacity-5">
              <FirestoreTable
                collectionRef={db.collection(gasStationCollectionName)}
                collectionQueriesWhere={[
                  {
                    fieldPath: "userRole",
                    opStr: "==",
                    value: UserRole.GASSTATION,
                  },
                  {
                    fieldPath: "gasStationCode",
                    opStr: "==",
                    value: code,
                  },
                ]}
                condensed
                columns={columns}
                rowRender={renderRow}
                rowEditHandler={onEditRow}
                rowDeleteHandler={onDeleteRow}
                rowDetailsHandler={onRowDetails}
                rowAddManagerHandler={onAddManager}
                rowChangeManagerHandler={onRowChangeManager}
                shouldAddEditColumn
                displayAddManagerBtn
                displayChangeManagerBtn
                displayDetails
                displayEditBtn
                paginate={{ pageIndex: 0, limit: 100, sort: "createdAt:desc" }}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={changeManager}
        maxSize="md"
        closeModal={() => setChangeManager(false)}
        modalTitle="Changer un manager"
      >
        <Form<{email: string}>
          onSubmit={onSubmit}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  email: yup.string().required("Le champs est obligatoire"),
                  })
                  ),
                }}
              submitButtonFullWidth
              submitButtonLabel="Modifier"
        >
          <div className="space-y-4 text-black md:space-y-6">
            <FormInput name="email" label="Email" />
          </div>

        </Form>
           
      </Modal>
    </div>
  );
};

export default AllGasStationListPage;
