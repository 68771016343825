import { useContext, useEffect, useState } from "react";
import {
  auditLogCollectionName,
  gasStationMembershipCollectionName,
} from "../../../config";
import { db } from "../../../firebase";
import { UserContext } from "../../../contexts";
import Moment from "react-moment";
import FirestoreTable, {
  IColumn,
  RowRenderFunction,
  IRow,
} from "../../../components/FirestoreTable";

const columns: IColumn[] = [
  {
    field: "createdAt",
    label: "Date de création",
  },
  {
    field: "date",
    label: "Date d'initiation",
  },
  {
    field: "senderPhoneNumber",
    label: "Expéditeur",
  },
  {
    field: "receiverPhoneNumber",
    label: "Destinataire",
  },
  {
    field: "transactionType",
    label: "Type d'opération",
  },
  {
    field: "amount",
    label: "Montant",
  },
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "date":
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "senderPhoneNumber":
      return <span>{value}</span>;
    case "receiverPhoneNumber":
      return <span>{value}</span>;
    case "amount":
      return <span>{new Intl.NumberFormat().format(value)} Fcfa</span>;
    case "transactionType":
      return <span>{value} </span>;
    default:
      return value as string;
  }
};

const AuditLogs = () => {
  const { user, agencyAgent, company } = useContext(UserContext);
  const [gasStationRelationId, setGasStationRelationId] = useState("");

  const retriveGasStationId = async () => {
    const membershipRef = db.collection(gasStationMembershipCollectionName);
    const membershipSnaphot = await membershipRef
      .where("memberId", "==", user?.id)
      .get();
    if (membershipSnaphot.empty) {
      return undefined;
    }
    const membershipData = membershipSnaphot.docs[0].data();
    if (membershipData) {
      setGasStationRelationId(membershipData?.gasStationId);
      return membershipData?.gasStationId as string;
    }
  };

  useEffect(() => {
    retriveGasStationId();
  }, []);

  return (
    <>
      <div className="px-4 mt-10 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="py-2 text-xl font-semibold text-gray-900">
              Les audit logs
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Vous trouverez ci-dessous tous les audit logs affiliés à votre
              compte.
            </p>
          </div>
        </div>
        <div className="flex flex-col mt-8">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                <FirestoreTable
                  collectionRef={db.collection(auditLogCollectionName)}
                  collectionQueriesWhere={[
                    {
                      fieldPath: "participantsIds",
                      opStr: "array-contains",
                      value: (user?.userAgencySubRole
                        ? agencyAgent?.id
                        : user?.attendantRole
                        ? gasStationRelationId
                        : company
                        ? company?.id
                        : user?.id) as string,
                    },
                  ]}
                  condensed
                  columns={columns}
                  rowRender={renderRow}
                  paginate={{
                    pageIndex: 0,
                    limit: 100,
                    sort: "createdAt:desc",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuditLogs;
