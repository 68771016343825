/* eslint-disable jsx-a11y/no-redundant-roles */
import {
  companiesMembershipCollectionName,
  companyCollectionName,
  usersCollectionName,
} from "../../../../config";
import {
  UserCircleIcon,
  LockClosedIcon,
  AtSymbolIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  CompanyAttendantRole,
  UserInterface,
  UserRole,
  mapUserAvailableZone,
  mapsGender,
  userAvailableZonesOptions,
} from "../../../../interfaces";
import { CompanyInterface } from "../../../../interfaces/Company";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { db, functions, storage } from "../../../../firebase";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormInput,
  FormSelect,
  Form,
} from "../../../../components/FormComponents";
import { classNames } from "../../../../utils";
import * as yup from "yup";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

type CompanyInfos = Pick<
  CompanyInterface,
  "displayName" | "adresse" | "city" | "phoneNumber" | "email"
>;
type CompanyAttendantMinInfos = Pick<
  UserInterface,
  "displayName" | "email" | "password" | "confirmPassword"
>;

type CompanyAttendantInfos = Pick<
  UserInterface,
  | "displayName"
  | "email"
  | "active"
  | "agence"
  | "city"
  | "adresse"
  | "cnibNumber"
  | "createdAt"
  | "gender"
  | "phoneNumber"
  | "profession"
  | "userRole"
  | "userAgencySubRole"
  | "updatedAt"
>;

type Step = {
  id: "companyInfos" | "companyAttendantMinInfos" | "companyAttendantInfos";
  index: number;
  name: string;
  description: string;
  icon: any;
};

const steps: Step[] = [
  {
    id: "companyInfos",
    index: 0,
    name: "Informations de l'entreprise",
    description: "Renseigner les informations de l'entreprise",
    icon: UserCircleIcon,
  },
  {
    id: "companyAttendantMinInfos",
    index: 1,
    name: "Les informations de connexion",
    description: "Renseigner les informations de connexion du manager",
    icon: LockClosedIcon,
  },
  {
    id: "companyAttendantInfos",
    index: 2,
    name: "Informations du manager",
    description: "Renseigner les informations du manager",
    icon: AtSymbolIcon,
  },
];
const collectionName = companyCollectionName;

const CompanyEditPage = () => {
  const navigate = useNavigate();
  const [currentFormStep, setCurrentFormStep] = useState<Step>(steps[0]);
  const location = useLocation();
  const currentCompany = location.state?.entity;
  const isAffilated = location.state?.affiliate;
  const [memberId, setMemberId] = useState<string>();
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [rccmFileUrl, setRccmFileUrl] = useState<string>();
  const [ifuFileUrl, setIfuFileUrl] = useState<string>();
  const [rccmFileName, setRccmFileName] = useState<any>();
  const [ifuFileName, setIfuFileName] = useState<any>();

  const sankMoneyCreateCompanyFn = functions.httpsCallable(
    "sankMoneyCreateCompanyFn"
  );

  const sankmoneyUpdateAccountFn = functions.httpsCallable(
    "sankmoneyUpdateAccountFn"
  );

  const sankmoneyCreateSankStaffFn = functions.httpsCallable(
    "sankmoneyCreateSankStaffFn"
  );

  const sankMoneyCreateMembershipFn = functions.httpsCallable(
    "sankMoneyCreateMembershipFn"
  );

  const retrivePhoneNumber = async (phoneNumber: any) => {
    if (phoneNumber) {
      setCompanyPhoneNumber(phoneNumber);
    }
  };

  const onSubmitCompany = async (data: any) => {
    if (currentCompany) {
      const response = sankmoneyUpdateAccountFn({
        collectionName: collectionName,
        documentData: {
          id: currentCompany.id,
          phoneNumber: companyPhoneNumber && companyPhoneNumber,
          ...data,
        },
        documentId: currentCompany.id,
      })
        .then(() => {
          navigate("/companies");
        })
        .catch((err: { details: any; message: any }) =>
          toast.error(err.details.message)
        );
      toast.promise(response, {
        error: "Une erreur s'est produite. Veuillez réessayer !",
        success:
          "Les informations de la station-service ont été mise à jour avec succès",
        loading: "chargement...",
      });
    } else {
      if (ifuFileUrl && rccmFileUrl) {
        const companyData: any = {
          adresse: data.adresse,
          city: data.city,
          email: data.email,
          lat: data.lat,
          long: data.long,
          phoneNumber: companyPhoneNumber ?? "",
          displayName: data.displayName,
          userRole: UserRole.COMPANY,
          applyFee: false,
          fee: 0,
          rccmFileUrl,
          ifuFileUrl,
        };
        const response = sankMoneyCreateCompanyFn({
          documentData: {
            ...companyData,
          },
        })
          .then((result) => {
            setCompanyId(result.data.id);
            setCurrentFormStep(steps[1]);
          })
          .catch((err: { details: any; message: any }) =>
            toast.error(err.details.message)
          );
        toast.promise(response, {
          error: "Une erreur s'est produite. Veuillez réessayer !",
          success:
            "Les informations de l'entreprise ont été ajouté avec succès",
          loading: "chargement...",
        });
      }
    }
  };

  const onSubmitMemberMinInfos = (data: any) => {
    data.confirmPassword && delete data.confirmPassword;
    const response = sankmoneyCreateSankStaffFn({
      collectionName: usersCollectionName,
      email: data.email,
      password: data.password,
      userRole: UserRole.COMPANYSTAFF,
    })
      .then(async (result) => {
        setMemberId(result.data.success.uid);
        setCurrentFormStep(steps[2]);
        if (result.data.success.uid) {
          const memberShipData = {
            companyId: currentCompany? currentCompany.id : companyId,
            memberId: result.data.success.uid,
          };
          await sankMoneyCreateMembershipFn({
            collectionName: companiesMembershipCollectionName,
            documentData: memberShipData,
          }).catch((err: { details: any; message: any }) =>
            toast.error(err.details.message)
          );
        }
      })
      .catch((err: { details: any; message: any }) =>
        toast.error(err.details.message)
      );
    toast.promise(response, {
      error: "Une erreur s'est produite. Veuillez réessayer !",
      success: "Les informations du membre ont été enregistrer avec succès",
      loading: "chargement...",
    });
  };

  const onSubmitDefaultInfos = (data: any) => {
    if (memberId && companyPhoneNumber) {
      data.phoneNumber = companyPhoneNumber;
      const response = sankmoneyUpdateAccountFn({
        collectionName: usersCollectionName,
        documentData: {
          id: memberId,
          ...data,
          companyAttendantRole: CompanyAttendantRole.MANAGER,
        },
        documentId: memberId,
      })
        .then((result) => {
          navigate("/companies");
        })
        .catch((err: { details: any; message: any }) =>
          toast.error(err.details.message)
        );
      toast.promise(response, {
        error: "Une erreur s'est produite. Veuillez réessayer !",
        success: "Les informations du membre ont été enregistrées avec succès",
        loading: "chargement...",
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (isValidPhoneNumber(companyPhoneNumber)) {
        const sankAccounts = await db
          .collection(usersCollectionName)
          .where("phoneNumber", "==", companyPhoneNumber)
          .get();

        if (sankAccounts.empty) {
          toast.success("Le numéros de téléphone est valide.");
        } else {
          toast.error("Le numéros de téléphone renseigner est déja utiliser.");
        }
      }
    })();
  }, [companyPhoneNumber]);

  useEffect(() => {
    currentCompany  && isAffilated && setCurrentFormStep(steps[1]);
  }, []);

  const handlechangeRccmInputFile = (value: any) => {
    const file = value.target.files[0];
    setRccmFileName(file.name);
    if (file) {
      const imageRef = ref(
        storage,
        `company/${companyPhoneNumber}/rccm/${file.lastModified}`
      );
      uploadBytes(imageRef, file)
        .then(() => {
          getDownloadURL(imageRef)
            .then((url) => {
              setRccmFileUrl(url);
            })
            .catch((error) =>
              toast.error(
                `Une erreur s'est produit lors du chargement de l'url du fichier ${error.message}`
              )
            );
          setRccmFileUrl("");
        })
        .catch((error) =>
          toast.error(
            `Une erreur s'est produit lors de l'upload du fichier ${error.message}`
          )
        );
    }
  };
  const handlechangeIFUInputFile = (value: any) => {
    const file = value.target.files[0];
    setIfuFileName(file.name);
    if (file) {
      const imageRef = ref(
        storage,
        `company/${companyPhoneNumber}/ifu/${file.lastModified}`
      );
      uploadBytes(imageRef, file)
        .then(() => {
          getDownloadURL(imageRef)
            .then((url) => {
              setIfuFileUrl(url);
            })
            .catch((error) =>
              toast.error(
                `Une erreur s'est produit lors du chargement de l'url du fichier ${error.message}`
              )
            );
          setIfuFileUrl("");
        })
        .catch((error) =>
          toast.error(
            `Une erreur s'est produit lors de l'upload du fichier ${error.message}`
          )
        );
    }
  };

  return (
    <>
      <div className="lg:border-t lg:border-b lg:border-gray-200">
        <nav
          className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8"
          aria-label="Progress"
        >
          <ol
            role="list"
            className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
          >
            <li className="relative px-2 py-4 overflow-hidden lg:py-2">
              <button
                className="p-1 border border-transparent rounded-full hover:border-gray-200 hover:bg-gray-50"
                onClick={() => navigate("/companies")}
              >
                <XMarkIcon className="w-8 h-8 text-gray-700" />
              </button>
            </li>
            {steps.map((step, stepIdx) => (
              <li key={stepIdx} className="relative overflow-hidden lg:flex-1">
                <div
                  className={classNames(
                    stepIdx === 0 ? "border-b-0 rounded-t-md" : "",
                    stepIdx === steps.length - 1
                      ? "border-t-0 rounded-b-md"
                      : "",
                    "border border-gray-200 overflow-hidden lg:border-0 h-full"
                  )}
                >
                  {
                    //TODO remove comment on check current user
                  }
                  <button
                    className={classNames(
                      !"currentGasStation" ? "cursor-not-allowed" : "",
                      "currentGasStation" && step.index === 2 ? "" : "",
                      "w-full group h-full"
                    )}
                  >
                    <span
                      className={classNames(
                        step.index !== currentFormStep.index
                          ? "group-hover:bg-gray-200 bg-transparent "
                          : "bg-red-600",
                        "absolute top-0 left-0 w-1 h-full  lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? "lg:pl-9" : "",
                        "px-4 py-4 lg:py-2 flex items-start text-sm font-medium"
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span
                          className={classNames(
                            step.index < currentFormStep.index
                              ? "bg-red-600"
                              : "",
                            step.index === currentFormStep.index
                              ? "bg-white border-2 border-red-600"
                              : "",
                            step.index > currentFormStep.index
                              ? "bg-white border-2 border-gray-300"
                              : "",
                            "flex items-center justify-center w-10 h-10  rounded-full"
                          )}
                        >
                          <step.icon
                            className={classNames(
                              step.index < currentFormStep.index
                                ? "text-white"
                                : "",
                              step.index === currentFormStep.index
                                ? "text-red-600"
                                : "",
                              step.index > currentFormStep.index
                                ? "text-gray-500"
                                : "",
                              "w-6 h-6 "
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex-col flex  xl:flex">
                        <span
                          className={classNames(
                            step.index === currentFormStep.index
                              ? "text-red-600"
                              : "",
                            step.index > currentFormStep.index
                              ? "text-gray-500"
                              : "",
                            "text-xs font-semibold tracking-wide uppercase md:block lg:hidden xl:block"
                          )}
                        >
                          {step.name}
                        </span>
                        <span className="flex items-start text-sm font-medium text-gray-500">
                          {step.description}
                        </span>
                      </span>
                    </span>
                  </button>
                  <div
                    className="absolute inset-0 top-0 left-0 hidden w-3 lg:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="w-full h-full text-gray-300"
                      viewBox="0 0 12 82"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0.5 0V31L10.5 41L0.5 51V82"
                        stroke="currentcolor"
                        vectorEffect="non-scaling-stroke"
                      />
                    </svg>
                  </div>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <div className="items-center w-full mx-auto md:w-11/12 lg:w-9/12">
        {currentFormStep.id === "companyInfos" && (
          <Form<CompanyInfos>
            onSubmit={onSubmitCompany}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  displayName: yup
                    .string()
                    .required("Le champ est obligatoire"),
                  email: yup
                    .string()
                    .email()
                    .required("Le champ est obligatoire"),
                  city: yup.string().optional(),
                  availableZone: yup.string().optional(),
                  adresse: yup.string().required("Le champ est obligatoire"),
                })
              ),
              defaultValues: {
                adresse: currentCompany?.adresse ?? "",
                email: currentCompany?.email ?? "",
                city: currentCompany?.city ?? "",
                displayName: currentCompany?.displayName ?? "",
                phoneNumber: currentCompany?.phoneNumber ?? "",
              },
            }}
            submitButtonLabel={`${
              currentCompany ? "Editer " : "Enregistrer "
            }l'entreprise`}
            isSubmitBtnDisabled={
              currentCompany
                ? false
                : !rccmFileName || !ifuFileName
                ? true
                : false
            }
          >
            <div className="flex flex-col content-center w-full px-5 mt-5 align-top md:flex-row">
              <div className="md:w-2/3">
                <FormInput
                  name="displayName"
                  label="Nom"
                  placeholder="Exemple: Sank Business"
                />
                <PhoneInput
                  international={false}
                  placeholder="Entrer un numéro de téléphone"
                  className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                  defaultCountry="BF"
                  value={
                    companyPhoneNumber || currentCompany
                      ? companyPhoneNumber || currentCompany?.phoneNumber
                      : ""
                  }
                  onChange={retrivePhoneNumber}
                />
                <FormInput
                  name="email"
                  label="E-mail"
                  placeholder="@exemple.com"
                />
                <FormInput
                  name="city"
                  label="Ma ville"
                  placeholder="Exemple: Ouagadougou"
                />
                <FormInput
                  name="adresse"
                  label="Adresse"
                  placeholder="Exemple: Avenue Kwame N'Kruma"
                />
                <FormSelect<string>
                  label={"Zone"}
                  name="availableZone"
                  selectedOption={"Choisir..."}
                  options={userAvailableZonesOptions}
                  optionLabel={(option) =>
                    (mapUserAvailableZone.get(option) as string) ?? "Choisir..."
                  }
                />
                <div className="my-4">
                  <input
                    type="file"
                    id="file"
                    className="sr-only"
                    accept="application/pdf,.csv,.txt,.xlsx"
                    onChange={handlechangeRccmInputFile}
                  />
                  <label
                    htmlFor="file"
                    className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                  >
                    <div>
                      {rccmFileName ? (
                        <div>{rccmFileName}</div>
                      ) : (
                        <span className="inline-flex rounded border border-[#e0e0e0] cursor-pointer py-2 px-7 text-base font-medium text-[#07074D]">
                          Uploader le RCCM
                        </span>
                      )}
                    </div>
                  </label>
                </div>
                <div className="my-4">
                  <input
                    type="file"
                    id="file-2"
                    accept="application/pdf,.csv,.txt,.xlsx"
                    className="sr-only"
                    onChange={handlechangeIFUInputFile}
                  />
                  <label
                    htmlFor="file-2"
                    className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                  >
                    <div>
                      {ifuFileName ? (
                        <div>{ifuFileName}</div>
                      ) : (
                        <span className="inline-flex rounded border border-[#e0e0e0] cursor-pointer py-2 px-7 text-base font-medium text-[#07074D]">
                          Uploader le IFU
                        </span>
                      )}
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </Form>
        )}
        {currentFormStep.id === "companyAttendantMinInfos" && (
          <Form<CompanyAttendantMinInfos>
            onSubmit={onSubmitMemberMinInfos}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  email: yup.string().required("Le champ est obligatoire"),
                  password: yup
                    .string()
                    .required("Le champ est obligatoire")
                    .min(6, "6 charactère minimum"),
                  confirmPassword: yup
                    .string()
                    .required("Le champ est obligatoire")
                    .oneOf(
                      [yup.ref("password")],
                      "Les mots de passe ne sont pas compatibles"
                    ),
                })
              ),
              defaultValues: {
                email: "",
                password: "",
                confirmPassword: "",
              },
            }}
            submitButtonLabel="Ajouter le manager"
          >
            <div className="flex flex-col content-center w-full px-5 mt-5">
              <div className="w-full mx-auto ">
                <FormInput
                  name="email"
                  label="E-mail"
                  placeholder="@exemple.com"
                />
                <FormInput
                  name="password"
                  label="Mot de passe"
                  type="password"
                  placeholder="Votre mot de passe en six (06) caractères"
                />
                <FormInput
                  type="password"
                  name="confirmPassword"
                  label="Confirmez votre mot de passe"
                  placeholder="Votre mot de passe"
                />
              </div>
            </div>
          </Form>
        )}
        {currentFormStep.id === "companyAttendantInfos" && (
          <Form<CompanyAttendantInfos>
            onSubmit={onSubmitDefaultInfos}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  displayName: yup
                    .string()
                    .required("Le champ est obligatoire"),
                  city: yup.string().optional(),
                  adresse: yup.string().required("Le champ est obligatoire"),
                  cnibNumber: yup.string().required("Le champ est obligatoire"),
                  gender: yup.string().required("Le champ est obligatoire"),
                })
              ),
            }}
            submitButtonLabel={"Enregistrer"}
          >
            <div className="flex flex-col content-center w-full px-5 mt-5">
              <div className="md:w-1/2">
                <FormInput
                  name="displayName"
                  label="Nom/Prénom"
                  placeholder="John Doe"
                />
                <PhoneInput
                  international={false}
                  placeholder="Entrer un numéro de téléphone"
                  className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                  defaultCountry="BF"
                  onChange={retrivePhoneNumber}
                />
                <FormInput
                  name="city"
                  label="Ville"
                  optional
                  placeholder="Ouagadougou"
                />
                <FormInput
                  name="adresse"
                  optional
                  label="Adresse"
                  placeholder="Rue 10.34, secteur 30, Ouaga2000"
                />
                <FormInput
                  name="cnibNumber"
                  label="Numéro de CNIB"
                  placeholder="BXXXXXXX"
                />
                <FormSelect<string>
                  label={"Genre"}
                  name="gender"
                  selectedOption={"Choisir..."}
                  options={["FEMALE", "MALE", "NONBINARY", "UNKNOWN"]}
                  optionLabel={(option) =>
                    (mapsGender.get(option) as string) ?? "Choisir..."
                  }
                />
              </div>
            </div>
          </Form>
        )}
      </div>
    </>
  );
};

export default CompanyEditPage;
