/* eslint-disable @typescript-eslint/no-empty-interface */
import { useContext, useState } from "react";
import { UserContext } from "../../../contexts/UserContext";
import { UseFormReturn } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import {
  Form,
  FormInput,
  FormSelect,
} from "../../../components/FormComponents";
import { UserInterface } from "../../../interfaces";
import { useNavigate } from "react-router-dom";
import AvatarInput from "../../../components/AvatarInput/AvatarInput";
import { db, functions, storage } from "../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { usersCollectionName } from "../../../config";
import { userConnectedWithMail } from "../../../utils";
import HasRoles from "../../../components/HasRoles";
import { ClipboardDocumentCheckIcon } from "@heroicons/react/24/outline";

export interface ProfileProps {}

const mapsGender = new Map<string, string>([
  ["MALE", "Homme"],
  ["FEMALE", "Femme"],
  ["NONBINARY", "Non binaire"],
  ["UNKNOWN", "Inconnu"],
]);

const editableRolesList = ["ADMIN", "AGENCYSTAFF", "SUPERVISOR"];

export const Profile = () => {
  const { user, setUser } = useContext(UserContext);
  const [, setFormReturn] = useState<UseFormReturn<UserInterface>>();
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState<string>();
  const sankmoneyUpdateUserProfileFn = functions.httpsCallable(
    "sankmoneyUpdateUserProfileFn"
  );
  const onSubmit = async (data: UserInterface) => {
    if (!editableRolesList.includes(user?.userRole as string)) {
      toast.error("Vous ne pouvez pas modifier ces informations");
      return;
    }
    if (user) {
      imageUrl && ((user as UserInterface).profilImageUrl = imageUrl);
      const response = sankmoneyUpdateUserProfileFn({
        collectionName: usersCollectionName,
        documentData: {
          profilImageUrl: imageUrl
            ? imageUrl
            : (user as UserInterface)?.profilImageUrl,
          ...data,
        },
        documentId: user?.id,
      }).then(async () => {
        const userSnap = await db
          .collection(usersCollectionName)
          .doc(user?.id)
          .get();
        if (userSnap.exists) {
          setUser(userSnap.data() as UserInterface);
        }
        navigate("/account/profile");
      });
      toast.promise(response, {
        error: "La mis à jours du profil echoué",
        success: "Profile mis à jours avec success",
        loading: "chargement...",
      });
    } else toast.error("Profil mis à jours echoué");
  };

  const handlechange = (value: any) => {
    const image = value.target.files[0];
    if (!editableRolesList.includes(user?.userRole as string)) {
      toast.error("Vous ne pouvez pas modifier ces informations");
      return;
    }
    if (image && user) {
      const imageRef = ref(
        storage,
        `users/${user?.id}/profilImage/${image.lastModified}`
      );
      uploadBytes(imageRef, image)
        .then(() => {
          toast.loading("Execution...");
          getDownloadURL(imageRef)
            .then((url) => {
              setImageUrl(url);
            })
            .catch((error) =>
              toast.error(
                `Une erreur s'est produit lors du chargement de l'url du fichier ${error.message}`
              )
            );
          setImageUrl("");
        })
        .catch((error) =>
          toast.error(
            `Une erreur s'est produit lors de l'upload du fichier ${error.message}`
          )
        );
    }
  };
  return (
    <div className="grid grid-cols-1 mx-9 md:grid-cols-6">
      <div className="col-span-3 mr-10">
        <Form<UserInterface>
          onSubmit={onSubmit}
          formReturnAware={setFormReturn}
          form={{
            resolver: yupResolver(
              yup.object().shape({
                displayName: yup.string().required("Votre prénom est requis"),
                email: yup
                  .string()
                  .email("E-mail non valide. Veuillez saisir un bon format."),
                gender: yup.string().required("Votre genre est requis"),
                phoneNumber: yup.string().required("Votre numéro est requis"),
                city: yup.string().required("Votre ville est requise"),
                cnibNumber: yup
                  .string()
                  .required("Votre carte d'identité est requise"),
                profession: yup.string().optional(),
                adresse: yup.string().required("Le champs est obligatoire"),
              })
            ),
            defaultValues: {
              displayName: user?.displayName ?? "",
              email: user?.email ?? "",
              gender: user?.gender ?? "",
              phoneNumber: user?.phoneNumber ?? "",
              city: user?.city ?? "",
              cnibNumber: user?.cnibNumber ?? "",
              profession: user ? user.profession : "",
              adresse: user ? user.adresse : "",
            },
          }}
          submitButtonLabel="Mêttre à jours vos informations"
          isSubmitBtnDisabled={
            !editableRolesList.includes(user?.userRole as string)
          }
        >
          <div className="px-4 py-2 sm:p-2 lg:pb-8">
            <div>
              <h2 className="text-lg font-medium leading-6 text-gray-900">
                Profil
              </h2>
              {editableRolesList.includes(user?.userRole as string) ? (
                <p className="mt-1 text-sm text-gray-500">
                  Ces informations seront affichées publiquement, alors faites
                  attention à ce que vous partagez.
                </p>
              ) : (
                <p className="mt-1 text-red-500 text-md">
                  Vous ne pouvez pas modifier ces informations
                </p>
              )}
              <HasRoles userRole={["ADMIN", "MERCHANT"]}>
                <div className="flex mt-1 text-sm text-gray-500">
                  <div>Copier votre identifiant sank</div>
                  <div
                    className="text-red-500 cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(user?.id ?? "").then(() => {
                        toast.success("Copier avec succès !");
                      });
                    }}
                  >
                    <ClipboardDocumentCheckIcon className="h-6" />
                  </div>
                </div>
              </HasRoles>
            </div>
            <div className="flex flex-col-reverse mt-2 lg:flex-row">
              <div className="w-full">
                <FormInput
                  type="text"
                  name="displayName"
                  label={"Nom/Prénom"}
                  disabled={
                    !editableRolesList.includes(user?.userRole as string)
                  }
                />
                <FormInput
                  type="text"
                  name="email"
                  label={"Adresse email"}
                  optional={
                    (user && !userConnectedWithMail.includes(user.userRole)) ||
                    !editableRolesList.includes(user?.userRole as string)
                  }
                  disabled={
                    (user && userConnectedWithMail.includes(user.userRole)) ||
                    !editableRolesList.includes(user?.userRole as string)
                  }
                />
                <FormInput
                  type="text"
                  name="phoneNumber"
                  label={"Téléphone"}
                  optional={
                    (user && userConnectedWithMail.includes(user.userRole)) ||
                    !editableRolesList.includes(user?.userRole as string)
                  }
                  disabled={
                    (user && !userConnectedWithMail.includes(user.userRole)) ||
                    !editableRolesList.includes(user?.userRole as string)
                  }
                />
                <FormInput
                  type="text"
                  name="city"
                  label={"Ville"}
                  disabled={
                    !editableRolesList.includes(user?.userRole as string)
                  }
                />
                <FormInput
                  name="profession"
                  label="Profession"
                  optional
                  disabled={
                    !editableRolesList.includes(user?.userRole as string)
                  }
                />
                <FormInput
                  name="adresse"
                  label="Adresse"
                  disabled={
                    !editableRolesList.includes(user?.userRole as string)
                  }
                />
                <FormInput
                  type="text"
                  name="cnibNumber"
                  label={"Numéro CNIB"}
                  disabled={
                    !editableRolesList.includes(user?.userRole as string)
                  }
                />
                <FormSelect<string>
                  label={"Genre"}
                  name="gender"
                  selectedOption={(user?.gender as string) ?? "Choisir..."}
                  options={
                    !editableRolesList.includes(user?.userRole as string)
                      ? []
                      : ["FEMALE", "MALE", "NONBINARY", "UNKNOWN"]
                  }
                  optionLabel={(option) =>
                    !editableRolesList.includes(user?.userRole as string)
                      ? "Vous ne pouvez pas modifier ces informations"
                      : (mapsGender.get(option) as string) ?? "Choisir..."
                  }
                  disabled={
                    !editableRolesList.includes(user?.userRole as string)
                  }
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div className="flex items-center justify-center w-48 h-48 col-span-3 text-red-500">
        <AvatarInput
          imageUrl={user?.profilImageUrl ? user?.profilImageUrl : imageUrl}
          displayName={user?.displayName}
          handleChange={(value) => handlechange(value)}
          inputLabel={
            user?.profilImageUrl || imageUrl
              ? "Modifier la photo profil"
              : "Ajouter une photo de profil"
          }
        />
      </div>
    </div>
  );
};
