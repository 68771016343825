import {
  ArrowDownTrayIcon,
    ArrowPathIcon,
    BanknotesIcon,
    FunnelIcon,
  } from "@heroicons/react/24/outline";
  import { Timestamp } from "firebase-admin/firestore";
  import moment from "moment";
  import { useContext, useEffect, useRef, useState } from "react";
  import toast from "react-hot-toast";
  import Pagination from "../../../../components/Pagination/Pagination";
  import NoContent from "../../../../components/TableNoContentPage/NoContent";
  import {
    transactionCollectionName,
  } from "../../../../config";
  import { db, functions } from "../../../../firebase";
  import {TransactionType } from "../../../../interfaces";
  import { getTimestamp, classNames, parseDate } from "../../../../utils";
  import TableRowEditMenu from "../../../../components/TableRowEditMenu/TableRowEditMenu";
  import Modal from "../../../../components/Modal";
  import { UserContext } from "../../../../contexts";
import { jsPDF } from "jspdf";
  
  const tableHeader = [
    "N°",
    "Date de paiement",
    "Id Transaction",
    "Montant",
    "Période",
    "Nom/Prénom",
    "Action",
  ];
  
  const PostPaidInvoicesManageInvoices = () => {
    const [paginatdlistData, setPaginatdListData] = useState<any[]>([]);
    const [invoices, setInvoices] = useState<any[]>([]);
    const [invoice, setInvoice] = useState<any>();
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [numPolicy, setNumPolicy] = useState<string>("")
    const { user } = useContext(UserContext);

    const [seachWithStartDateValue, setSetSeachWithStartDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
    const [seachWithEndDateValue, setSetSeachWithEndDateValue] = useState<string>(
    `${moment(Date.now()).format("YYYY-MM-DD")}`
    );
    const handleChangeStartDateValue = (value: any) => {
      value.preventDefault();
      setSetSeachWithStartDateValue(value.target.value);
    };
    const handleChangeEndDtaeValue = (value: any) => {
      value.preventDefault();
      setSetSeachWithEndDateValue(value.target.value);
    };

    const [startTime, setStartTime] = useState<any>(
      getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, true)
    );
    const [endTime, setEndTime] = useState<any>(
      getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, false)
    );
  
    const retriveInvoices = async (
      startTime: any,
      endTime: any,
      pageLimit?: number
    ) => {
      if (startTime && endTime) {
        const invoicesSnap = await db
          .collection(transactionCollectionName)
          .orderBy("createdAt", "desc")
          .where("createdAt", ">=", startTime)
          .where("createdAt", "<=", endTime)
          .where("transactionType", "==",TransactionType.ELECTRIC_BILL_POSTPAID)
          .limitToLast(pageLimit ?? 25)
          .get();
        if (!invoicesSnap.empty) {
          const data = invoicesSnap.docs.map((d) => {
            const invData = d.data();
            const data = {
              id: d.id,
              transId: invData.snblTransId,
              periode: invData.snblInvoicePeriod,
              amount: invData.amount,
              fee: invData.fees + " FCFA",
              meterNum: invData.snblElectricMeterExplNumber,
              customerName: invData.receiverDisplayName,
              receiverPhoneNumber: invData.receiverPhoneNumber,
              createdAt: d.data()?.createdAt,
              senderPhoneNumber: invData.senderPhoneNumber,
              senderDisplayName: invData.senderDisplayName,

            };          
            return { id: d.id, data };
          });
          setInvoices(data);
          setPaginatdListData(data);
        }
        } 
        if(numPolicy){
          const invoicesSnap = await db
          .collection(transactionCollectionName)
          .where("transactionType", "==",TransactionType.ELECTRIC_BILL_POSTPAID)
          .where("receiverPhoneNumber", "==" , numPolicy)
          .get();
          if (!invoicesSnap.empty) {
            const data = invoicesSnap.docs.map((d) => {
              const invData = d.data();
              const data = {
                id: d.id,
                transId: invData.snblTransId,
                periode: invData.snblInvoicePeriod,
                amount: invData.amount,
                fee: invData.fees + " FCFA",
                meterNum: invData.snblElectricMeterExplNumber,
                customerName: invData.receiverDisplayName,
                receiverPhoneNumber: invData.receiverPhoneNumber,
                createdAt: d.data()?.createdAt,
                senderPhoneNumber: invData.senderPhoneNumber,
                senderDisplayName: invData.senderDisplayName,

              };          
              return { id: d.id, data };
            });
            setInvoices(data);
            setPaginatdListData(data);
          }
        }
    };
  
    const getFilteredByDate = async () => {
      // const startTime = new Date(seachWithStartDateValue).getTime();
      const startTime = getTimestamp(seachWithStartDateValue, true);
      const endTime = getTimestamp(seachWithEndDateValue, false);
      setEndTime(endTime);
      setStartTime(startTime);
      retriveInvoices(startTime, endTime);
    };

    const divRef = useRef<HTMLDivElement>(null);

    const exportToPDFData = ()=>{
      const doc = new jsPDF('l', 'mm', [750, 700]);
      const pdfjs = divRef.current;
      if (pdfjs) {
        doc.setFontSize(8);
        // Insérer le contenu HTML dans le document PDF
        doc.html(pdfjs, {
          callback: function (doc) {
            // Enregistrer le document PDF une fois que l'insertion est terminée
            doc.save(`${invoice.customerName}.pdf`);
          },
          x: 100,
          y: 5,
        });
      }
    }

    const getAllinvoicesOfDay = async (pageLimit?: number)=>{ 
      const invoicesSnap = await db
        .collection(transactionCollectionName)
        .orderBy("createdAt", "desc")
        .where("createdAt", ">=", startTime)
        .where("transactionType", "==",TransactionType.ELECTRIC_BILL_POSTPAID)
        .limitToLast(pageLimit ?? 25)
        .get();
      if (!invoicesSnap.empty) {
        const data = invoicesSnap.docs.map((d) => {
          const invData = d.data();
          const data = {
            id: d.id,
            transId: invData.snblTransId,
            periode: invData.snblInvoicePeriod,
            amount: invData.amount,
            fee: invData.fees + " FCFA",
            meterNum: invData.snblElectricMeterExplNumber,
            customerName: invData.receiverDisplayName,
            receiverPhoneNumber: invData.receiverPhoneNumber,
            createdAt: d.data()?.createdAt,
            senderPhoneNumber: invData.senderPhoneNumber,
            senderDisplayName: invData.senderDisplayName,

          };          
          return { id: d.id, data };
        });
        setInvoices(data);
        setPaginatdListData(data);
      }
       
    }
    useEffect(()=>{
      getAllinvoicesOfDay(50)
    }, [])
  
    return (
      <>
        <div className="px-4 mt-10 sm:px-6 lg:px-8">
          <div className="flex flex-col">
            <div className="">
              <h1 className="py-2 text-xl font-semibold text-gray-900">
                Gestions des factures postpayé.
              </h1>
            </div>
            <div className="h-8" />
            <div className="grid grid-cols-1 gap-5 sm:mt-10 md:grid-cols-3">
              <div className="my-auto">
                <input
                  type="search"
                  id="search"
                  className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  placeholder="N° de Police"
                  onChange={(e: any) => setNumPolicy(e.target.value)}
                  value={numPolicy}
                />
              </div>
              <div className="flex px-1 bg-white rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
                <div className="mr-1">
                  <label
                    htmlFor="dateDebut"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Date début
                  </label>
                  <input
                    type="date"
                    id="dateDebut"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                    onChange={handleChangeStartDateValue}
                  />
                </div>
                <div className="mr-1">
                  <label
                    htmlFor="dateFin"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Date fin
                  </label>
                  <input
                    type="date"
                    id="dateFin"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                    onChange={handleChangeEndDtaeValue}
                  />
                </div>
                <div className="flex items-center justify-center mt-5 h-14">
                  <div className="flex">
                    <button
                      type="button"
                      className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                      onClick={() => {
                        getFilteredByDate();
                      }}
                    >
                      <FunnelIcon
                        className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                        aria-hidden="true"
                      />
                      Filtrer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-8">
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                  <table
                    className="min-w-full border-separate"
                    style={{ borderSpacing: 0 }}
                  >
                    <thead className="bg-gray-50">
                      <tr>
                        {tableHeader.map((th, index) => (
                          <th
                            key={index}
                            scope="col"
                            className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                          >
                            {th}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {invoices && invoices.length > 0 ? (
                        (paginatdlistData.length > 0
                          ? paginatdlistData
                          : invoices
                        ).map((invoice: any, invoiceIdx: number) => (
                          <tr key={invoiceIdx}>
                            <td
                              className={classNames(
                                invoiceIdx !== invoices.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {(invoiceIdx as number) + 1}
                            </td>
                            <td
                              className={classNames(
                                invoiceIdx !== invoices.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {parseDate(invoice.data?.createdAt as Timestamp)}
                            </td>
                            <td
                              className={classNames(
                                invoiceIdx !== invoices.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {invoice.data.transId ? invoice.data.transId : "-"}
                            </td>
                            <td
                              className={classNames(
                                invoiceIdx !== invoices.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {invoice.data.amount ? invoice.data.amount : "-"}
                            </td>
                            <td
                              className={classNames(
                                invoiceIdx !== invoices.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {invoice.data.periode ? invoice.data.periode : "-"}
                            </td>
                            <td
                              className={classNames(
                                invoiceIdx !== invoices.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {invoice.data.customerName
                                ? invoice.data.customerName
                                : "-"}
                            </td>
                            <td
                              className={classNames(
                                invoiceIdx !== invoices.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                              )}
                            >
                              <TableRowEditMenu
                                rowDetailsHandler={() => {
                                  setInvoice(invoice.data);
                                  setShowDetailsModal(true);
                                }}
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NoContent />
                      )}
                    </tbody>
                  </table>
                  {invoices && invoices.length > 0 && (
                    <Pagination
                      skip={invoices.length}
                      take={invoices.length}
                      total={invoices.length}
                      collectionName={transactionCollectionName}
                      onPageChange={(pageIndex: number) => pageIndex}
                      onPageLimitChange={(pageLimit: number) =>
                        retriveInvoices(startTime, endTime, pageLimit)
                      }
                      isChangedPage={false}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={showDetailsModal}
          maxSize="md"
          closeModal={() => setShowDetailsModal(false)}
        >
          {invoice && (
            <div className="flex justify-center">
              <div className="w-full">
                <div className="m-3 sm:max-w-lg sm:w-full sm:mx-auto">
                  <div className="relative flex flex-col bg-white shadow-lg pointer-events-auto rounded-xl dark:bg-gray-800" ref={divRef}>
                    <div className="p-4 overflow-y-auto sm:p-7">
                      <div className="text-center">
                        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                          Facture Sonabel
                        </h3>
                        <p className="text-sm text-gray-500">
                          Facture #
                          <span className="font-bold">{invoice.transId}</span>
                        </p>
                      </div>
  
                      <div className="grid grid-cols-2 gap-5 mt-5 sm:mt-10 sm:grid-cols-2">
                        <div>
                          <span className="block text-xs text-gray-500 uppercase">
                            Nom du client:
                          </span>
                          <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                            {invoice.customerName}
                          </span>
                        </div>
  
                        <div>
                          <span className="block text-xs text-gray-500 uppercase">
                            N° du compteur:
                          </span>
                          <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                            {invoice.receiverPhoneNumber}
                          </span>
                        </div>

                        <div>
                          <span className="block text-xs text-gray-500 uppercase">
                            N° d'exploitation:
                          </span>
                          <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                            {invoice.meterNum}
                          </span>
                        </div>
                      </div>
  
                      <div className="mt-5 sm:mt-10">
                        <h4 className="text-xs font-semibold text-gray-800 uppercase dark:text-gray-200">
                          Facture
                        </h4>
  
                        <ul className="flex flex-col mt-3">
                          <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                            <div className="flex items-center justify-between w-full">
                              <span>Date de paiement</span>
                              <span>
                              {parseDate(invoice.createdAt as Timestamp)}
                              </span>
                            </div>
                          </li>
                          <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                            <div className="flex items-center justify-between w-full">
                              <span>Période</span>
                              <span>{invoice.periode}</span>
                            </div>
                          </li>
                          <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                            <div className="flex items-center justify-between w-full">
                              <span>N° du receveur</span>
                              <span>{invoice.senderPhoneNumber}</span>
                            </div>
                          </li>
                          <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                            <div className="flex items-center justify-between w-full">
                              <span>Nom du receveur</span>
                              <span>{invoice.senderDisplayName}</span>
                            </div>
                          </li>
                          <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                            <div className="flex items-center justify-between w-full">
                              <span>Frais</span>
                              <span>
                                {invoice.fee}
                               
                              </span>
                            </div>
                          </li>
                          <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                            <div className="flex items-center justify-between w-full">
                              <span>Montant total</span>
                              <span>{invoice.amount}F</span>
                            </div>
                          </li>
                        </ul>
                      </div>
  
                      
  
                      <div className="mt-5 sm:mt-10">
                        <p className="text-sm text-gray-500">
                          Si vous avez des questions, veuillez nous contacter à
                          l'adresse{" "}
                          <a
                            className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium"
                            href="mailto:contact@sankbusiness.com"
                          >
                            contact@sankbusiness.com
                          </a>{" "}
                          ou nous appeler au{" "}
                          <a
                            className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium"
                            href="tel:+22668202034"
                          >
                            +226 68-20-20-34
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end mt-5 gap-x-2">
                        <button
                          className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-red-600 border border-transparent rounded-lg gap-x-2 hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                          onClick={() => exportToPDFData()}
                        >
                          <ArrowDownTrayIcon
                            className="self-center flex-shrink-0 w-6 h-6 mr-1 text-white"
                            aria-hidden="true"
                          />
                          Imprimer
                        </button>
                      </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
      </>
    );
  };
  
  export default PostPaidInvoicesManageInvoices;
  