import {
  ArrowPathIcon,
  BanknotesIcon,
  FunnelIcon,
} from "@heroicons/react/24/outline";
import { Timestamp } from "firebase-admin/firestore";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import Pagination from "../../../../components/Pagination/Pagination";
import NoContent from "../../../../components/TableNoContentPage/NoContent";
import {
  snblUsersInvoicesCollectionName,
  usersCollectionName,
} from "../../../../config";
import { db, functions } from "../../../../firebase";
import { SNBLSUBSCIPTIONTYPE } from "../../../../interfaces";
import { getTimestamp, classNames, parseDate } from "../../../../utils";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import TableRowEditMenu from "../../../../components/TableRowEditMenu/TableRowEditMenu";
import Modal from "../../../../components/Modal";
import { UserContext } from "../../../../contexts";

const tableHeader = [
  "N°",
  "Date de création",
  "Id Transaction",
  "ID facture",
  "TOKEN",
  "Compteur N°",
  "Montant Payer",
  "Nom/Prénom",
  "Action",
];

const PrePaidInvoicesManageInvoices = () => {
  const collectionName = usersCollectionName;
  const [paginatdlistData, setPaginatdListData] = useState<any[]>([]);
  const [retriveUserByPhoneNumber, setRetriveUserByPhoneNumber] = useState<
    any[]
  >([]);
  const [isBtnDesabled, setIsBtnDesabled] = useState(false);
  const [invoice, setInvoice] = useState<any>();
  const [invoices, setInvoices] = useState<any>();
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [meterNumber, setMeterNumber] = useState<string>("");
  const { user } = useContext(UserContext);

  const sankMoneySnblUpdatePrePaidInvoiceInfoFn = functions.httpsCallable(
    "sankMoneySnblUpdatePrePaidInvoiceInfoFn"
  );

  const retrivePhoneNumber = async (phoneNumber: string) => {
    if (phoneNumber && isValidPhoneNumber(phoneNumber, "BF")) {
      const userSnap = await db
        .collection(collectionName)
        .where("phoneNumber", "==", phoneNumber)
        .get();

      !userSnap.empty
        ? setRetriveUserByPhoneNumber([userSnap.docs[0].data()])
        : toast.error("Utilisateur introuvable/Numéro incorrect.");
    } else {
      setPaginatdListData([]);
    }
  };

  const retriveInvoices = async (
    startTime: any,
    endTime: any,
    pageLimit?: number
  ) => {
    if (retriveUserByPhoneNumber.length > 0) {
      const invoicesSnap = await db
        .collection(snblUsersInvoicesCollectionName)
        .orderBy("createdAt", "desc")
        .where("createdAt", ">=", startTime)
        .where("createdAt", "<=", endTime)
        .where("subscritionType", "==", SNBLSUBSCIPTIONTYPE.PREPAID)
        .where("userId", "==", retriveUserByPhoneNumber[0].id)
        .limitToLast(pageLimit ?? 25)
        .get();
      if (!invoicesSnap.empty) {
        const data = invoicesSnap.docs.map((d) => {
          const invData = d.data()?.data;
          const snblFees = invData.fee[0].item.map((val: any) => {
            return {
              name: val["$"].name,
              amt: val["$"].amt,
            };
          });

          const formattedFees = snblFees.reduce((acc: any, current: any) => {
            acc[current.name] = current.amt;
            return acc;
          }, {});
          const data = {
            id: d.id,
            transId: invData.transID,
            invoice: invData.invoice,
            code: invData.code,
            token: invData.token,
            meterNum: invData.meterNum,
            vendQty: invData.vendQty,
            vendAMT: invData.vendAMT,
            customerName: invData.customerName,
            fee: formattedFees,
            AMT: invData.AMT,
            createdAt: d.data()?.createdAt,
          };
          return { id: d.id, data };
        });
        setPaginatdListData(data);
        setInvoices(data);
      } else {
        setPaginatdListData([]);
      }
    } else toast.error("Veuillez saisir un numéro de téléphone valide.");
  };

  const resendInvoiceInfosToUser = async () => {
    setIsBtnDesabled(true);
    const response = sankMoneySnblUpdatePrePaidInvoiceInfoFn({
      currentUserId: user?.id,
      withConnection: true,
      appVersion: "web",
      appPlatform: "web",
      invoiceId: invoice.id,
    })
      .then(() => {
        setShowDetailsModal(false);
        setInvoice(undefined);
        setIsBtnDesabled(false);
      })
      .catch(() => {
        setIsBtnDesabled(false);
      });
    toast.promise(response, {
      error: (e) => e.message,
      success: "Message renvoyé avec succès",
      loading: "Renvoie en cours...",
    });
  };

  const handleMetterNumberChange = (meterNum: string) => {
    const data = paginatdlistData.filter((val) =>
      (val.data.meterNum as string).includes(meterNum)
    );
    if (meterNum.length !== 0) {
      setPaginatdListData(data);
    } else {
      setPaginatdListData(invoices);
    }
  };

  const handleInputChange = (event: any) => {
    const { value } = event.target;
    setMeterNumber(value);
    handleMetterNumberChange(value);
  };

  const handleChangeStartDateValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithEndDateValue(value.target.value);
  };

  const [seachWithStartDateValue, setSetSeachWithStartDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
  const [seachWithEndDateValue, setSetSeachWithEndDateValue] = useState<string>(
    `${moment(Date.now()).format("YYYY-MM-DD")}`
  );

  const [startTime, setStartTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, true)
  );
  const [endTime, setEndTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, false)
  );

  const getFilteredByDate = async () => {
    // const startTime = new Date(seachWithStartDateValue).getTime();
    const startTime = getTimestamp(seachWithStartDateValue, true);
    const endTime = getTimestamp(seachWithEndDateValue, false);
    setEndTime(endTime);
    setStartTime(startTime);
    retriveInvoices(startTime, endTime);
  };

  const getAllinvoicesOfDay = async (pageLimit?: number) => {
    const invoicesSnap = await db
      .collection(snblUsersInvoicesCollectionName)
      .orderBy("createdAt", "desc")
      .where("subscritionType", "==", SNBLSUBSCIPTIONTYPE.PREPAID)
      .limitToLast(pageLimit ?? 25)
      .get();
    if (!invoicesSnap.empty) {
      const data = invoicesSnap.docs.map((d) => {
        const invData = d.data()?.data;
        const snblFees = invData.fee[0].item.map((val: any) => {
          return {
            name: val["$"].name,
            amt: val["$"].amt,
          };
        });

        const formattedFees = snblFees.reduce((acc: any, current: any) => {
          acc[current.name] = current.amt;
          return acc;
        }, {});
        const data = {
          id: d.id,
          transId: invData.transID,
          invoice: invData.invoice,
          code: invData.code,
          token: invData.token,
          meterNum: invData.meterNum,
          vendQty: invData.vendQty,
          vendAMT: invData.vendAMT,
          customerName: invData.customerName,
          fee: formattedFees,
          AMT: invData.AMT,
          createdAt: d.data()?.createdAt,
        };
        return { id: d.id, data };
      });
      setPaginatdListData(data);
    }
  };
  useEffect(() => {
    getAllinvoicesOfDay();
  }, []);

  return (
    <>
      <div className="px-4 mt-10 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="">
            <h1 className="py-2 text-xl font-semibold text-gray-900">
              Gestions des factures prépayé.
            </h1>
          </div>
          <div className="h-8" />
          <div className="grid grid-cols-1 gap-5 sm:mt-10 md:grid-cols-3">
            <div className="my-auto">
              <PhoneInput
                international={false}
                placeholder="Numéro de téléphone"
                className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                defaultCountry="BF"
                onChange={retrivePhoneNumber}
              />
            </div>
            <div className="my-auto">
              <input
                type="search"
                id="search"
                className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                placeholder="55443322155"
                onChange={handleInputChange}
                value={meterNumber}
              />
            </div>
            <div className="flex px-1 bg-white rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
              <div className="mr-1">
                <label
                  htmlFor="dateDebut"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Date début
                </label>
                <input
                  type="date"
                  id="dateDebut"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  onChange={handleChangeStartDateValue}
                />
              </div>
              <div className="mr-1">
                <label
                  htmlFor="dateFin"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Date fin
                </label>
                <input
                  type="date"
                  id="dateFin"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  onChange={handleChangeEndDtaeValue}
                />
              </div>
              <div className="flex items-center justify-center mt-5 h-14">
                <div className="flex">
                  <button
                    type="button"
                    className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    onClick={() => {
                      getFilteredByDate();
                    }}
                  >
                    <FunnelIcon
                      className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                      aria-hidden="true"
                    />
                    Filtrer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-8">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                <table
                  className="min-w-full border-separate"
                  style={{ borderSpacing: 0 }}
                >
                  <thead className="bg-gray-50">
                    <tr>
                      {tableHeader.map((th, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                        >
                          {th}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {paginatdlistData && paginatdlistData.length > 0 ? (
                      paginatdlistData.map(
                        (invoice: any, invoiceIdx: number) => (
                          <tr key={invoiceIdx}>
                            <td
                              className={classNames(
                                invoiceIdx !== paginatdlistData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {(invoiceIdx as number) + 1}
                            </td>
                            <td
                              className={classNames(
                                invoiceIdx !== paginatdlistData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {parseDate(invoice.data?.createdAt as Timestamp)}
                            </td>
                            <td
                              className={classNames(
                                invoiceIdx !== paginatdlistData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {invoice.data.transId
                                ? invoice.data.transId
                                : "-"}
                            </td>
                            <td
                              className={classNames(
                                invoiceIdx !== paginatdlistData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {invoice.data.invoice
                                ? invoice.data.invoice
                                : "-"}
                            </td>
                            <td
                              className={classNames(
                                invoiceIdx !== paginatdlistData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {invoice.data.token ? invoice.data.token : "-"}
                            </td>
                            <td
                              className={classNames(
                                invoiceIdx !== paginatdlistData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {invoice.data.meterNum
                                ? invoice.data.meterNum
                                : "-"}
                            </td>
                            <td
                              className={classNames(
                                invoiceIdx !== paginatdlistData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {invoice.data.meterNum ? invoice.data.AMT : "-"}
                            </td>
                            <td
                              className={classNames(
                                invoiceIdx !== paginatdlistData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {invoice.data.customerName
                                ? invoice.data.customerName
                                : "-"}
                            </td>
                            <td
                              className={classNames(
                                invoiceIdx !== paginatdlistData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                              )}
                            >
                              <TableRowEditMenu
                                rowDetailsHandler={() => {
                                  setInvoice(invoice.data);
                                  setShowDetailsModal(true);
                                }}
                              />
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <NoContent />
                    )}
                  </tbody>
                </table>
                {paginatdlistData && paginatdlistData.length > 0 && (
                  <Pagination
                    skip={paginatdlistData.length}
                    take={paginatdlistData.length}
                    total={paginatdlistData.length}
                    collectionName={snblUsersInvoicesCollectionName}
                    onPageChange={(pageIndex: number) => pageIndex}
                    onPageLimitChange={(pageLimit: number) => {
                      retriveUserByPhoneNumber.length > 0
                        ? retriveInvoices(startTime, endTime, pageLimit)
                        : getAllinvoicesOfDay(pageLimit);
                    }}
                    isChangedPage={false}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showDetailsModal}
        maxSize="md"
        closeModal={() => setShowDetailsModal(false)}
      >
        {invoice && (
          <div className="flex justify-center">
            <div className="w-full">
              <div className="m-3 sm:max-w-lg sm:w-full sm:mx-auto">
                <div className="relative flex flex-col bg-white shadow-lg pointer-events-auto rounded-xl dark:bg-gray-800">
                  <div className="p-4 overflow-y-auto sm:p-7">
                    <div className="text-center">
                      <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                        Facture Sonabel
                      </h3>
                      <p className="text-sm text-gray-500">
                        Facture #
                        <span className="font-bold">{invoice.invoice}</span>
                      </p>
                    </div>

                    <div className="grid grid-cols-2 gap-5 mt-5 sm:mt-10 sm:grid-cols-2">
                      <div>
                        <span className="block text-xs text-gray-500 uppercase">
                          Nom du client:
                        </span>
                        <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                          {invoice.customerName}
                        </span>
                      </div>

                      <div>
                        <span className="block text-xs text-gray-500 uppercase">
                          N° du compteur:
                        </span>
                        <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                          {invoice.meterNum}
                        </span>
                      </div>
                    </div>

                    <div className="mt-5 sm:mt-10">
                      <h4 className="text-xs font-semibold text-gray-800 uppercase dark:text-gray-200">
                        Facture
                      </h4>

                      <ul className="flex flex-col mt-3">
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Token</span>
                            <span>{invoice.token}</span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Coût de l'energie</span>
                            <span>{invoice.vendAMT}F</span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Tsdaae</span>
                            <span>
                              {invoice.fee["Tsdaae"] !== undefined
                                ? invoice.fee["Tsdaae"]
                                : 0}
                              F
                            </span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Tde</span>
                            <span>
                              {invoice.fee["Tde"] !== undefined
                                ? invoice.fee["Tde"]
                                : 0}
                              F
                            </span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Redevance</span>
                            <span>
                              {invoice.fee["Redevance"] !== undefined
                                ? invoice.fee["Redevance"]
                                : 0}
                              F
                            </span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>PRIME FIXE</span>
                            <span>
                              {invoice.fee["PRIME FIXE"] !== undefined
                                ? invoice.fee["PRIME FIXE"]
                                : 0}
                              F
                            </span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>TVA</span>
                            <span>
                              {invoice.fee["TVA"] !== undefined
                                ? invoice.fee["TVA"]
                                : 0}
                              F
                            </span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Montant total</span>
                            <span>{invoice.AMT}F</span>
                          </div>
                        </li>
                        <li className="inline-flex items-center px-4 py-3 -mt-px text-sm font-semibold text-gray-800 border gap-x-2 bg-gray-50 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-slate-800 dark:border-gray-700 dark:text-gray-200">
                          <div className="flex items-center justify-between w-full">
                            <span>Total quantité energie</span>
                            <span>{invoice.vendQty}Kwh</span>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div className="flex justify-end mt-5 gap-x-2">
                      <button
                        className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-red-600 border border-transparent rounded-lg gap-x-2 hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                        onClick={() => resendInvoiceInfosToUser()}
                        disabled={isBtnDesabled}
                      >
                        {isBtnDesabled ? (
                          <div className="flex items-center justify-center">
                            <button
                              type="button"
                              className="w-full text-white bg-transparent border border-red-500 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-transparent dark:focus:ring-red-800"
                              disabled={true}
                            >
                              <svg
                                className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                              <span className="text-white">
                                Recharge en cours...
                              </span>
                            </button>
                          </div>
                        ) : (
                          <>
                            <ArrowPathIcon
                              className="self-center flex-shrink-0 w-6 h-6 mr-1 text-white"
                              aria-hidden="true"
                            />
                            Renvoyé le code
                          </>
                        )}
                      </button>
                    </div>

                    {/* <div className="mt-5 sm:mt-10">
                      <p className="text-sm text-gray-500">
                        Si vous avez des questions, veuillez nous contacter à
                        l'adresse{" "}
                        <a
                          className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium"
                          href="mailto:contact@sankbusiness.com"
                        >
                          contact@sankbusiness.com
                        </a>{" "}
                        ou nous appeler au{" "}
                        <a
                          className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium"
                          href="tel:+22668202034"
                        >
                          +226 68-20-20-34
                        </a>
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default PrePaidInvoicesManageInvoices;
