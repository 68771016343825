import {
  ArrowPathIcon,
  ArrowUpIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/outline";
import { useContext } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { classNames } from "../../utils";
import { UserContext } from "../../contexts";
import { AgencyStaffRole, UserRole } from "../../interfaces";
import HasRoles from "../../components/HasRoles";

const TransferPage = () => {
  const { user } = useContext(UserContext);
  return (
    <div className="bg-white relativ">
      <div className="divide-y lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
        <aside className="lg:col-span-2 xl:col-span-2">
          <nav className="">
            <HasRoles userRole={["DEALER", "AGENCYSTAFF"]}>
              <>
                {(user?.userRole === UserRole.DEALER ||
                  user?.userAgencySubRole === AgencyStaffRole.CASHIER) && (
                  <NavLink
                    to={{
                      pathname: "/transfer/reload",
                    }}
                    className={({ isActive }) =>
                      classNames(
                        isActive
                          ? "bg-slate-300 text-red-500"
                          : "text-black hover:bg-gray hover:text-red-200",
                        "group w-full p-3 flex text-md font-medium"
                      )
                    }
                  >
                    {({ isActive }) => (
                      <>
                        <RectangleGroupIcon
                          className={classNames(
                            isActive
                              ? "text-red-500"
                              : "text-black group-hover:text-red-200",
                            "mr-3 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        <span className="">Recharger un agent</span>
                      </>
                    )}
                  </NavLink>
                )}
              </>
            </HasRoles>
            <HasRoles userRole={["AGENT", "AGENCYSTAFF"]}>
              <>
                {user?.userAgencySubRole !== AgencyStaffRole.MANAGER && (
                  <NavLink
                    to={{
                      pathname: "/transfer/reload_user",
                    }}
                    className={({ isActive }) =>
                      classNames(
                        isActive
                          ? "bg-slate-300 text-red-500"
                          : "text-black hover:bg-gray hover:text-red-200",
                        "group w-full p-3 flex text-md font-medium"
                      )
                    }
                  >
                    {({ isActive }) => (
                      <>
                        <ArrowPathIcon
                          className={classNames(
                            isActive
                              ? "text-red-500"
                              : "text-black group-hover:text-red-200",
                            "mr-3 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        <span className="">Recharger un utilisateur</span>
                      </>
                    )}
                  </NavLink>
                )}
              </>
            </HasRoles>
            <HasRoles userRole={["USER"]}>
              <NavLink
                to={{
                  pathname: "/transfer/transfer_to_user",
                }}
                className={({ isActive }) =>
                  classNames(
                    isActive
                      ? "bg-slate-300 text-red-500"
                      : "text-black hover:bg-gray hover:text-red-200",
                    "group w-full p-3 flex text-md font-medium"
                  )
                }
              >
                {({ isActive }) => (
                  <>
                    <ArrowUpIcon
                      className={classNames(
                        isActive
                          ? "text-red-500"
                          : "text-black group-hover:text-red-200",
                        "mr-3 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    <span className="">Transfert</span>
                  </>
                )}
              </NavLink>
            </HasRoles>
            <HasRoles userRole={["ADMIN"]}>
              <NavLink
                to={{
                  pathname: "/transfer/reload_agency",
                }}
                className={({ isActive }) =>
                  classNames(
                    isActive
                      ? "bg-slate-300 text-red-500"
                      : "text-black hover:bg-gray hover:text-red-200",
                    "group w-full p-3 flex text-md font-medium"
                  )
                }
              >
                {({ isActive }) => (
                  <>
                    <ArrowPathIcon
                      className={classNames(
                        isActive
                          ? "text-red-500"
                          : "text-black group-hover:text-red-200",
                        "mr-3 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    <span className="">Recharger une agence</span>
                  </>
                )}
              </NavLink>
            </HasRoles>
            <HasRoles userRole={["AGENCYSTAFF"]}>
              <NavLink
                to={{
                  pathname: "/transfer/reload_company",
                }}
                className={({ isActive }) =>
                  classNames(
                    isActive
                      ? "bg-slate-300 text-red-500"
                      : "text-black hover:bg-gray hover:text-red-200",
                    "group w-full p-3 flex text-md font-medium"
                  )
                }
              >
                {({ isActive }) => (
                  <>
                    <ArrowPathIcon
                      className={classNames(
                        isActive
                          ? "text-red-500"
                          : "text-black group-hover:text-red-200",
                        "mr-3 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    <span className="">Recharger une entreprise</span>
                  </>
                )}
              </NavLink>
            </HasRoles>
          </nav>
        </aside>
        <div className="divide-y lg:col-span-9 xl:col-span-10">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default TransferPage;
