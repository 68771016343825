import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useState } from "react";
import {
  FormInput,
  Form,
  FormTextArea,
} from "../../../../components/FormComponents";
import * as yup from "yup";
import { db, functions } from "../../../../firebase";
import toast from "react-hot-toast";
import {
  PhoneNumberDefaultLength,
  accountsCollectionName,
  agentCreateUsersParams,
  globalConfigCollectionsName,
  usersCollectionName,
} from "../../../../config";
import moment from "moment";
import CodePinComponent from "../../../../components/CodePinComponent";
import Modal from "../../../../components/Modal";
import PhoneInput from "react-phone-number-input";
import { UserRole, AccountType } from "../../../../interfaces";
import FormCheckToggle from "../../../../components/FormComponents/FormCheckToggle";
import { UseFormReturn } from "react-hook-form";
import { UserContext } from "../../../../contexts";

type ParamsType = {
  minimum_deposit: string;
};

const AgentCreateUserParams = () => {
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [isCodePinBtnDesabled, setIsCodePinBtnDesabled] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [, setFormReturn] = useState<UseFormReturn<ParamsType>>();
  const [operationData, setOperationData] = useState<any>();
  const { user } = useContext(UserContext);
  const [paidAllAgent, setPaidAllAgent] = useState<boolean>(true);
  const [receiverDataGetWithPhoneNumber, setreceiverDataGetWithPhoneNumber] =
    useState<any>(null);
  const [showMinimumDepotValue, setShowMinimumDepotValue] =
    useState<boolean>(true);
  const { agentCreateUserParams, setAgentCreateUserParams } =
    useContext(UserContext);

  const agent_create_users_params_ref = db
    .collection(globalConfigCollectionsName)
    .doc(agentCreateUsersParams);

  const onSubmitParams = async (data: ParamsType) => {
    const creatData = {
      minimum_deposit: data.minimum_deposit,
    };
    await agent_create_users_params_ref.set(creatData).then((result) => {
      toast.success("Les paramètres ont bien été mis à jours");
      setAgentCreateUserParams(creatData);
    });
  };

  const sankMoneyPayAgentCreateUserCommissonsFn = functions.httpsCallable(
    "sankMoneyPayAgentCreateUserCommissonsFn"
  );
  const sankMoneyPayAllAgentCreateUserCommissonsFn = functions.httpsCallable(
    "sankMoneyPayAllAgentCreateUserCommissonsFn"
  );

  const onSubmit = (pincode: any) => {
    if (!paidAllAgent) {
      onSubmitPaidAgentCommissions(pincode);
    } else {
      onSubmitPaidAllAgentCommissions(pincode);
    }
  };

  const onSubmitPaidAgentCommissions = (pincode: any) => {
    if (receiverDataGetWithPhoneNumber?.phoneNumber) {
      const paiementData = {
        senderPhoneNumber: user?.phoneNumber,
        receiverPhoneNumber: receiverDataGetWithPhoneNumber?.phoneNumber,
        description: operationData.description,
        startDate: operationData.startDate,
        amount: operationData.amount,
        endDate: operationData.endDate,
        pinCode: pincode,
      };
      sankMoneyPayAgentCreateUserCommissonsFn(paiementData)
        .then(() => {
          setIsLoad(false);
          setShowPinCodeModal(false);
          setIsCodePinBtnDesabled(false);
          toast.success("Le paiement a été effectuer avec succès.");
        })
        .catch((error) => {
          toast.error("Une erreur s'est produite lors du paiement.");
          setIsCodePinBtnDesabled(false);
          setIsLoad(false);
        });
    } else {
      toast.error("Veuillez renseigner un numéro de téléphone.");
      setIsCodePinBtnDesabled(false);
      setIsLoad(false);
      setShowPinCodeModal(false);
    }
  };
  const onSubmitPaidAllAgentCommissions = (pincode: any) => {
    setIsCodePinBtnDesabled(true);
    setIsLoad(true);
    const paiementData = {
      senderPhoneNumber: user?.phoneNumber,
      description: operationData.description,
      startDate: operationData.startDate,
      amount: operationData.amount,
      endDate: operationData.endDate,
      pinCode: pincode,
    };
    sankMoneyPayAllAgentCreateUserCommissonsFn(paiementData)
      .then(() => {
        setIsLoad(false);
        setShowPinCodeModal(false);
        setIsCodePinBtnDesabled(false);
        toast.success("Le paiement a été effectuer avec succès.");
      })
      .catch((error) => {
        toast.error("Une erreur s'est produite lors du paiement.");
        setIsCodePinBtnDesabled(false);
        setIsLoad(false);
      });
  };

  const getFilterdByPhoneNumber = async (phoneNumber: any) => {
    if (phoneNumber !== undefined) {
      if (phoneNumber?.length >= PhoneNumberDefaultLength + 4) {
        const destUserSnapshot = await db
          .collection(usersCollectionName)
          .where("phoneNumber", "==", phoneNumber)
          .where("userRole", "==", UserRole.AGENT)
          .get();
        if (destUserSnapshot.empty) {
          toast.error("Le numéro n'existe pas");
          setreceiverDataGetWithPhoneNumber(null);
        } else {
          const destreceiverData = [];
          for (const destUserDoc of destUserSnapshot.docs) {
            const destUserdAllata = destUserDoc.data();
            const destUserAccountSnapshot = await destUserDoc.ref
              .collection(accountsCollectionName)
              .get();
            const destUserAccountData = destUserAccountSnapshot.docs
              .map((accountDoc) => accountDoc.data())
              .find((data) => data.accountType === AccountType.MAIN);
            const destUserWithAccounts = {
              account: destUserAccountData,
              ...destUserdAllata,
            };
            destreceiverData.push(destUserWithAccounts);
          }
          return setreceiverDataGetWithPhoneNumber(destreceiverData[0]);
        }
      } else {
        setreceiverDataGetWithPhoneNumber(null);
      }
    } else {
      setreceiverDataGetWithPhoneNumber(null);
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-5 sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h4 className="py-2 text-xl font-semibold text-gray-900">
            Le payement des commissions pour chaque utilisateur créé.
          </h4>
        </div>
      </div>
      <div className="justify-between w-4/5 mt-4 sm:flex-wrap lg:flex md::flex-wrap">
        <div className="-mx-1 -my-2 divide-x sm:-mx-2 lg:-mx-2">
          <div className="inline-block py-2">
            <div className="divide-x sm:flex-auto">
              <Form<{
                description: string;
                startDate: any;
                endDate: any;
                amount: string;
              }>
                onSubmit={(data) => {
                  setOperationData(data);
                  setShowPinCodeModal(true);
                }}
                submitButtonLabel="Payer les commissions"
                form={{
                  resolver: yupResolver(
                    yup.object().shape({
                      description: yup.string().optional(),
                      amount: yup.string().optional(),
                      startDate: yup
                        .date()
                        .required("Veillez choisir une date de debut"),
                      endDate: yup
                        .date()
                        .required("Veillez choisir une date de fin"),
                    })
                  ),
                }}
              >
                <div className="px-1 bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
                  <div className="px-1 mt-1 border rounded-md">
                    <FormCheckToggle
                      type="boolean"
                      name="isSimulation"
                      label="Payer tout les agents."
                      checked={paidAllAgent}
                      onValueChanged={(isChecked) => setPaidAllAgent(isChecked)}
                    />
                  </div>
                  {!paidAllAgent && (
                    <div className="mt-1">
                      <PhoneInput
                        international={false}
                        placeholder="Entrez un numéro de téléphone"
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                        defaultCountry="BF"
                        onChange={getFilterdByPhoneNumber}
                      />

                      {!receiverDataGetWithPhoneNumber ? (
                        <p className="mt-1 text-xs text-red-500">
                          Renseignez un numéro
                        </p>
                      ) : (
                        <p className="mt-1 text-xs text-green-500">
                          Numéro valide
                        </p>
                      )}
                    </div>
                  )}

                  <FormInput
                    type="text"
                    name="amount"
                    placeholder="5000"
                    label="Le montant de commission par utilisateur créé."
                    helpLabel="Definir un montant minimum pour chaque utilisateur créer."
                  />
                  <FormInput
                    type="date"
                    name="startDate"
                    label="Date de début"
                    defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  />
                  <FormInput
                    type="date"
                    name="endDate"
                    label="Date de fin"
                    defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  />
                  <FormTextArea name="description" label="Description." />
                </div>
              </Form>
            </div>
          </div>
        </div>
        <div className="-mx-1 -my-2 divide-x divide-x-reverse sm:-mx-2 lg:-mx-2">
          <div className="inline-block py-2">
            <div className="sm:flex-auto">
              <Form<ParamsType>
                onSubmit={onSubmitParams}
                submitButtonLabel="Enregistrer les paramètres"
                isSubmitBtnDisabled={showMinimumDepotValue}
                formReturnAware={setFormReturn}
                form={{
                  resolver: yupResolver(
                    yup.object().shape({
                      minimum_deposit: yup
                        .string()
                        .required("Le champ est requis"),
                    })
                  ),
                  defaultValues: {
                    minimum_deposit:
                      agentCreateUserParams?.minimum_deposit ?? 0,
                  },
                }}
              >
                <FormCheckToggle
                  type="boolean"
                  name="useCustomSMSC"
                  label="Mettre à jour la valeur du dépôt minimum."
                  checked={showMinimumDepotValue}
                  onValueChanged={(isChecked) =>
                    setShowMinimumDepotValue(isChecked)
                  }
                />
                <FormInput
                  type="text"
                  name="minimum_deposit"
                  label="Dépôt minimum"
                  disabled={showMinimumDepotValue}
                  helpLabel="Le dépôt minimum que l'agent doit recharger le client lorsqu'il crée son compte."
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showPinCodeModal}
        maxSize="md"
        closeModal={() => setShowPinCodeModal(false)}
      >
        <div className="flex justify-center">
          <CodePinComponent
            onSubmit={onSubmit}
            disabled={isCodePinBtnDesabled}
            isLoad={isLoad}
          />
        </div>
      </Modal>
    </div>
  );
};

export default AgentCreateUserParams;
