import { companyCollectionName, companiesMembershipCollectionName, usersCollectionName } from "../../../config";
import {
  UserIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import FirestoreTable, {
  IColumn,
  RowRenderFunction,
  IRow,
} from "../../../components/FirestoreTable";
import { db, functions } from "../../../firebase";
import Modal from "../../../components/Modal";
import { Form, FormInput } from "../../../components/FormComponents";
import * as yup from "yup";
import { useCallback, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import FormCheckToggle from "../../../components/FormComponents/FormCheckToggle";
import toast from "react-hot-toast";

const columns: IColumn[] = [
  {
    field: "createdAt",
    label: "Date de création",
  },
  {
    field: "profilImageUrl",
    label: "Profil",
  },
  {
    field: "displayName",
    label: "Nom/prénom",
  },
  {
    field: "email",
    label: "Email",
  },
  {
    field: "phoneNumber",
    label: "Téléphone",
  },
  {
    field: "city",
    label: "Ville",
  },
  {
    field: "active",
    label: "Active",
  },
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "displayName":
      return <span>{value}</span>;
    case "profilImageUrl":
      return (
        <span>
          {" "}
          {value ? (
            <img
              className="w-10 h-10 p-1 border rounded-full"
              src={
                value
                  ? value
                  : `https://ui-avatars.com/api/?background=random&name=${
                      value || "S"
                    }`
              }
              alt=""
            />
          ) : (
            <UserIcon className="w-10 h-10 p-1 border rounded-full" />
          )}
        </span>
      );
    case "email":
      return <span>{value}</span>;
    case "city":
      return <span>{value}</span>;
    case "phoneNumber":
      return <span>{value ? value : ""}</span>;
    case "active":
      return (
        <span>
          {value ? (
            <CheckCircleIcon className="w-6 h-6 text-green-500" />
          ) : (
            <XCircleIcon className="w-6 h-6 text-red-500" />
          )}
        </span>
      );
    default:
      return value as string;
  }
};
const AllCompaniesPage = () => {
  const navigate = useNavigate();
  const [showUpdateFeeModal, setShowUpdateFeeModal] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [changeManager, setChangeManager] = useState<boolean>(false);
  const [companyMember, setCompanyMember] = useState<any[]>([])
  const [managerPhoneNumber, setManagerPhoneNumber] = useState<any[]>([])

  const onEditRow = (id: number, entity: any) => {
    if (entity) {
      navigate(`/companies/${entity.id}/edit`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };
  const onRowDetails = (id: number, entity: any) => {
    if (entity) {
      navigate(`/companies/${entity.id}/details`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };
  const onRowChangeFees = (id: number, entity: any) => {
    if (entity) {
      setCompanyId(entity.id);
      setShowUpdateFeeModal(true);
    }
  };
  const onRowChangeManager = async (id: number, entity: any)=>{
    const snap = await db
    .collection( companiesMembershipCollectionName)
    .where("companyId", "==", entity.id)
    .get()
    if(snap.empty){
      toast.error("Cette compagnie ne possède pas de manager")
      setChangeManager(false)
      navigate("/companies")
    }else{
      setChangeManager(true)  
      companyMember.push(snap.docs[0].data()) 
    }
  };
  const onAddManager = async (id: number, entity: any) => {
    const snap = await db
    .collection( companiesMembershipCollectionName)
    .where("companyId", "==", entity.id)
    .get()
    if(!snap.empty){
      toast.error("Cette compagnie possède deja un manager")
      navigate("/companies")
    }else{
      if (entity) {
        navigate(`/companies/${entity.id}/edit`, {
          state: { entity: entity, id: entity.id, affiliate: true },
        });
      }
    }
  };
  const sankmoneyUpdateAccountFn = functions.httpsCallable(
    "sankmoneyUpdateAccountFn"
  );
  const sankMoneyUpdateUserNumberOrEmailFn = functions.httpsCallable(
    "sankMoneyUpdateUserNumberOrEmailFn"
  );

  const adminResetPasswordFn = functions.httpsCallable(
    "adminResetPasswordFn"
  );

  const onResetPassword = async () => {  
    const snap = await db
    .collection(usersCollectionName)
    .where("id", "==", companyMember[0].memberId)
    .get()
    if(!snap.empty){
      managerPhoneNumber.push(snap.docs[0].data());
    }
  };

  const onSubmitInfo = useCallback(async (data: any)=>{ 
    onResetPassword()
   const response = sankMoneyUpdateUserNumberOrEmailFn({
    userId: companyMember[0].memberId,
    email: data.email,
  })
  setChangeManager(false)
  navigate("/companies")
   toast.promise(response
    .then(()=>{
    const responses = adminResetPasswordFn({
      email: data.email,
      phoneNumber: managerPhoneNumber[0].phoneNumber,
    });
    toast.promise(responses, {
      error: (err) => `${err}`,
      success: "Le mot de passe a été reinitialiser avec succès.",
      loading: "chargement...",
    });
  }), {
    error: "Une erreur s'est produite. Veuillez réessayer !",
    success:
      "Les informations du manager ont été mise à jour avec succès.",
    loading: "chargement...",
  });
  
}, [])

  const onSubmit = (data: any) => {
    const feeData = {
      applyFee: data.applyFee,
      fee: parseInt(data.fee),
    };
    const response = sankmoneyUpdateAccountFn({
      collectionName: companyCollectionName,
      documentData: feeData,
      documentId: companyId,
    });
    toast.promise(response, {
      error: "Une erreur s'est produite. Veuillez réessayer !",
      success:
        "Les informations des frais on été mise à jour avec succès.",
      loading: "chargement...",
    });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-5 sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Les entreprises
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Vous trouverez ici l'ensemble des entreprises.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-purple-200 "
            onClick={() => {
              navigate("/companies/new");
            }}
          >
            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
              Ajouter une entreprise
            </span>
          </button>
        </div>
      </div>
      <div className="flex flex-col mt-8">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="shadow-sm ring-1 ring-black ring-opacity-5">
              <FirestoreTable
                collectionRef={db.collection(companyCollectionName)}
                condensed
                columns={columns}
                rowRender={renderRow}
                rowEditHandler={onEditRow}
                rowDetailsHandler={onRowDetails}
                rowAddCompanyFeeHandler={onRowChangeFees}
                rowAddManagerHandler={onAddManager}
                rowChangeManagerHandler={onRowChangeManager}
                shouldAddEditColumn
                displayAddManagerBtn
                displayChangeManagerBtn
                displayDetails
                displayEditBtn
                displayAddCompanyFeeBtn
                paginate={{ pageIndex: 0, limit: 100, sort: "createdAt:desc" }}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showUpdateFeeModal}
        maxSize="md"
        closeModal={() => setShowUpdateFeeModal(false)}
      >
        <Form<{ applyFee: boolean; fee: string }>
          onSubmit={onSubmit}
          form={{
            resolver: yupResolver(
              yup.object().shape({
                applyFee: yup.boolean().required("Le champs est obligatoire"),
                fee: yup.string().required("Le champs est obligatoire"),
              })
            ),
          }}
          submitButtonFullWidth
          submitButtonLabel="Modifier"
        >
          <div className="space-y-4 text-black md:space-y-6">
            <FormCheckToggle name="applyFee" label="Appliquer des frais" />
            <FormInput name="fee" label="Frais" />
          </div>
        </Form>
      </Modal>

      <Modal
        isOpen={changeManager}
        maxSize="md"
        closeModal={() => setChangeManager(false)}
        modalTitle="Changer un manager"
      >
        <Form<{email: string}>
          onSubmit={onSubmitInfo}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  email: yup.string().required("Le champs est obligatoire"),
                  })
                  ),
                }}
              submitButtonFullWidth
              submitButtonLabel="Modifier"
        >
          <div className="space-y-4 text-black md:space-y-6">
            <FormInput name="email" label="Email" />
          </div>

        </Form>
           
      </Modal>
    </div>
  );
};

export default AllCompaniesPage;
