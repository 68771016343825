import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Form,
  FormInput,
  FormSelect,
} from "../../../components/FormComponents";
import {
  AgencyStaffRole,
  UserInterface,
  UserRole,
  mapsGender,
} from "../../../interfaces";
import { functions, storage } from "../../../firebase";
import { usersCollectionName } from "../../../config";
import toast from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormCheckToggle from "../../../components/FormComponents/FormCheckToggle";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { UserContext } from "../../../contexts";

type InfosUsers = Pick<
  UserInterface,
  | "lastName"
  | "email"
  | "active"
  | "city"
  | "firstName"
  | "cnibNumber"
  | "gender"
  | "phoneNumber"
  | "profession"
  | "updatedAt"
  | "cnibImageUrl"
  | "isCertify"
  | "availableZone"
  | "adresse"
>;

const CertifyUserPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentUser = location.state?.entity;
  const { user } = useContext(UserContext);

  const [imageUrl, setImageUrl] = useState<string>();

  const sankmoneyUpdateAccountFn = functions.httpsCallable(
    "sankmoneyUpdateAccountFn"
  );

  const onSubmitInfosUser = (data: any) => {
    if (currentUser) {
      const response = sankmoneyUpdateAccountFn({
        collectionName: usersCollectionName,
        documentData: {
          isCertify: data.isCertify,
          lastName: data.lastName,
          firstName: data.firstName,
          email: data.email,
          city: data.city,
          profession: data.profession,
          cnibNumber: data.cnibNumber,
          phoneNumber: data.phoneNumber,
          cnibImageUrl: imageUrl ? imageUrl : currentUser?.cnibImageUrl,
          displayName: data.lastName + " " + data.firstName,
          adresse: data.adresse,
          gender: data.gender,
        },
        documentId: currentUser.id,
      }).then((result) => {
        navigate("/users");
      });
      toast.promise(response, {
        error: "La certificationƒ a échoué",
        success: "L'utilisateur a été certifier avec succès",
        loading: "chargement...",
      });
    } else
      toast.error(
        `L'indentifiant de l'agent est incorrect veuiller réessayer de nouveau !`
      );
  };

  const handlechange = (value: any) => {
    const image = value.target.files[0];
    if (image && currentUser) {
      const imageRef = ref(
        storage,
        `users/${currentUser?.id}/cnibImage/${image.lastModified}`
      );
      uploadBytes(imageRef, image)
        .then(() => {
          getDownloadURL(imageRef)
            .then((url) => {
              setImageUrl(url);
            })
            .catch((error) =>
              toast.error(
                `Une erreur s'est produit lors du chargement de l'url du fichier ${error.message}`
              )
            );
          setImageUrl("");
        })
        .catch((error) =>
          toast.error(
            `Une erreur s'est produit lors de l'upload du fichier ${error.message}`
          )
        );
    }
  };

  return (
    <div className="relative flex justify-center bg-white isolate">
      <div className="grid grid-cols-1 mx-auto max-w-7xl lg:grid-cols-1">
        <div className="relative px-6 pt-24 pb-6 sm:pt-32 lg:static lg:px-8 lg:py-6">
          <div className="max-w-xl mx-auto lg:mx-0 lg:max-w-lg">
            <div className="">
              <svg
                className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                    width={200}
                    height={200}
                    x="100%"
                    y={-1}
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M130 200V.5M.5 .5H200" fill="none" />
                  </pattern>
                </defs>
                <rect width="100%" height="100%" strokeWidth={0} fill="white" />
                <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
                  <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                </svg>
                <rect
                  width="100%"
                  height="100%"
                  strokeWidth={0}
                  fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
                />
              </svg>
            </div>
            <div>
              <Form<InfosUsers>
                onSubmit={onSubmitInfosUser}
                form={{
                  mode: "onChange",
                  resolver: yupResolver(
                    yup.object().shape({
                      isCertify: yup
                        .boolean()
                        .equals([true], "Veuillez certifier l'utilisateur"),
                      lastName: yup
                        .string()
                        .required("Le champs est obligatoire"),
                      firstName: yup
                        .string()
                        .required("Le champs est obligatoire"),
                      email: yup.string().optional(),
                      city: yup.string().required("Le champs est obligatoire"),
                      profession: yup.string().optional(),
                      adresse: yup.string().optional(),
                      availableZone: yup.string().optional(),
                      cnibNumber: yup
                        .string()
                        .required("Le champs est obligatoire"),
                      phoneNumber: yup
                        .string()
                        .required("Le champs est obligatoire"),
                    })
                  ),
                  defaultValues: {
                    isCertify: currentUser ? currentUser.isCertify : false,
                    lastName: currentUser.lastName
                      ? currentUser.lastName
                      : currentUser.displayName
                      ? (currentUser.displayName as string).split(" ")[0]
                      : "-",
                    firstName: currentUser.firstName
                      ? currentUser.firstName
                      : currentUser.displayName
                      ? (currentUser.displayName as string).split(" ")[1]
                      : "-",
                    city: currentUser ? currentUser.city : "-",
                    profession: currentUser ? currentUser.profession : "-",
                    phoneNumber: currentUser ? currentUser.phoneNumber : "-",
                    cnibNumber: currentUser ? currentUser.cnibNumber : "-",
                    email: currentUser ? currentUser.email : "-",
                    gender: currentUser?.gender
                      ? mapsGender.get(currentUser?.gender)
                      : "",
                    adresse: currentUser ? currentUser.adresse : "-",
                  },
                }}
                submitButtonLabel={"Certifier"}
                isSubmitBtnDisabled={
                  !(
                    user?.userRole === UserRole.ADMIN ||
                    user?.userAgencySubRole === AgencyStaffRole.MANAGER ||
                    user?.userRole === UserRole.TECHNICALSUPPORT
                  ) ?? true
                }
              >
                <h2 className="absolute text-3xl font-bold tracking-tight text-gray-900 top-5">
                  Informations de l'utilisateur
                </h2>

                <div className="max-w-xl mx-auto lg:mr-0 lg:max-w-lg">
                  <div className="grid grid-cols-1 gap-x-8 sm:grid-cols-2">
                    <div>
                      <FormInput
                        name="lastName"
                        label="Nom"
                        placeholder=""
                        disabled={!(user?.userRole === UserRole.ADMIN) ?? true}
                      />
                    </div>
                    <div>
                      <FormInput
                        name="firstName"
                        label="Prénom"
                        placeholder=""
                        disabled={!(user?.userRole === UserRole.ADMIN) ?? true}
                      />
                    </div>
                    <div className="">
                      <FormInput
                        name="email"
                        label="Email"
                        placeholder=""
                        disabled={
                          !(
                            user?.userRole === UserRole.ADMIN ||
                            user?.userAgencySubRole ===
                              AgencyStaffRole.MANAGER ||
                            user?.userRole === UserRole.TECHNICALSUPPORT
                          ) ?? true
                        }
                      />
                    </div>
                    <div className="">
                      <FormInput
                        name="cnibNumber"
                        label="Numero CNIB/PASSPORT"
                        placeholder=""
                        disabled={
                          !(
                            user?.userRole === UserRole.ADMIN ||
                            user?.userAgencySubRole ===
                              AgencyStaffRole.MANAGER ||
                            user?.userRole === UserRole.TECHNICALSUPPORT
                          ) ?? true
                        }
                      />
                    </div>
                    <div className="">
                      <FormInput
                        name="phoneNumber"
                        label="Numero Téléphone"
                        placeholder=""
                        disabled={true}
                      />
                    </div>
                    <div className="">
                      <FormInput
                        name="profession"
                        label="Profession"
                        placeholder=""
                        disabled={
                          !(
                            user?.userRole === UserRole.ADMIN ||
                            user?.userAgencySubRole ===
                              AgencyStaffRole.MANAGER ||
                            user?.userRole === UserRole.TECHNICALSUPPORT
                          ) ?? true
                        }
                      />
                    </div>
                    <div className="">
                      <FormInput
                        name="city"
                        label="Ville"
                        placeholder=""
                        disabled={
                          !(
                            user?.userRole === UserRole.ADMIN ||
                            user?.userAgencySubRole ===
                              AgencyStaffRole.MANAGER ||
                            user?.userRole === UserRole.TECHNICALSUPPORT
                          ) ?? true
                        }
                      />
                    </div>
                    <div className="">
                      <FormInput
                        name="adresse"
                        label="Adresse"
                        placeholder=""
                        disabled={
                          !(
                            user?.userRole === UserRole.ADMIN ||
                            user?.userAgencySubRole ===
                              AgencyStaffRole.MANAGER ||
                            user?.userRole === UserRole.TECHNICALSUPPORT
                          ) ?? true
                        }
                      />
                    </div>

                    <div className="">
                      <FormSelect<string>
                        label={"Genre"}
                        name="gender"
                        selectedOption={
                          currentUser ? currentUser.gender : "Choisir..."
                        }
                        options={["FEMALE", "MALE", "NONBINARY", "UNKNOWN"]}
                        optionLabel={(option) =>
                          (mapsGender.get(option) as string) ?? "Choisir..."
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-center p-6">
                  <div className="mx-auto w-full max-w-[550px] bg-white">
                    <div className="pt-2 mb-4">
                      <label className="mb-5 block text-xl font-semibold text-[#07074D]">
                        <FormCheckToggle
                          name="isCertify"
                          label="Certifier le compte"
                        />
                      </label>

                      <div className="mb-4">
                        <input
                          type="file"
                          id="file"
                          className="sr-only"
                          onChange={handlechange}
                        />
                        <label
                          htmlFor="file"
                          className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                        >
                          <div>
                            {currentUser.cnibImageUrl || imageUrl ? (
                              <img
                                className="object-cover object-center mx-auto"
                                src={imageUrl || currentUser.cnibImageUrl}
                                alt={imageUrl || currentUser.displayName}
                              />
                            ) : (
                              <span className="inline-flex rounded border border-[#e0e0e0] cursor-pointer py-2 px-7 text-base font-medium text-[#07074D]">
                                Choisir une image
                              </span>
                            )}
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertifyUserPage;
