import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useContext, useState } from "react";
import {
  Form,
  FormInput,
  FormSelect,
  FormTextArea,
} from "../../../../components/FormComponents";
import * as yup from "yup";
import moment from "moment";
import { UserRole } from "../../../../interfaces";
import { functions } from "../../../../firebase";
import { UserContext } from "../../../../contexts";
import toast from "react-hot-toast";
import CodePinComponent from "../../../../components/CodePinComponent";
import Modal from "../../../../components/Modal";
import FormCheckToggle from "../../../../components/FormComponents/FormCheckToggle";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";

const userRole = [
  UserRole.AGENT,
  UserRole.DEALER,
  UserRole.MARCHAND,
  UserRole.GASSTATION,
];

const PaidCommission = () => {
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [isCodePinBtnDesabled, setIsCodePinBtnDesabled] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [operationData, setOperationData] = useState<any>();
  const { user } = useContext(UserContext);
  const [simulatePayment, setSimulatePayment] = useState<boolean>(false);
  const sankMoneyPayAgentCommissionsFn = functions.httpsCallable(
    "sankMoneyPayAgentCommissionsFn"
  );

  const onSubmit = (pincode: any) => {
    setIsCodePinBtnDesabled(true);
    setIsLoad(true);
    const paiementData = {
      senderPhoneNumber: user?.phoneNumber,
      description: operationData.description,
      startDate: operationData.startDate,
      endDate: operationData.endDate,
      pinCode: pincode,
      userRole: operationData.userRole,
      isSimulation: operationData.isSimulation,
    };
    sankMoneyPayAgentCommissionsFn(paiementData)
      .then((response) => {
        if (simulatePayment) {
          exportToXLSData(response.data.simulateData);
          setIsLoad(false);
          setShowPinCodeModal(false);
          setIsCodePinBtnDesabled(false);
        } else {
          setIsLoad(false);
          setShowPinCodeModal(false);
          setIsCodePinBtnDesabled(false);
        }
        toast.success(
          `${
            simulatePayment
              ? "La simulation de paiement a été effectuer avec succès"
              : "Le paiement a été effectuer avec succès."
          }`
        );
      })
      .catch((error) => {
        toast.error(error.message);
        setIsCodePinBtnDesabled(false);
        setIsLoad(false);
      });
  };

  const exportToXLSData = useCallback((simulateData: any) => {
    const worksheet = XLSX.utils.json_to_sheet(
      simulateData.map((val: any) => {
        return {
          "Date de création": val.createdAt,
          "Nom du destinataire": val.displayName,
          "Téléphone du destinataire": val.phoneNumber,
          Montant: val.amount,
          "Date début": val.startDate,
          "Date fin": val.endDate,
          message: val.message,
          Description: val.description ?? "----",
          "Role de l'utilisateur": val.userRole ?? "----",
        };
      })
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "export");

    XLSX.writeFile(workbook, "simulation.xlsx", { compression: true });
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-5 sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h4 className="py-2 text-xl font-semibold text-gray-900">
            Paiement des commissions
          </h4>
        </div>
      </div>
      <div className="flex flex-col mt-4">
        <div className="-mx-1 -my-2 sm:-mx-2 lg:-mx-2">
          <div className="inline-block w-full py-2 md:w-1/2">
            <div className="sm:flex-auto">
              <Form<{
                description: string;
                startDate: any;
                endDate: any;
                userRole: string;
              }>
                onSubmit={(data) => {
                  setOperationData(data);
                  setShowPinCodeModal(true);
                }}
                submitButtonLabel={
                  simulatePayment
                    ? "Simuler le paiement"
                    : "Payer les commissions"
                }
                form={{
                  mode: "onChange",
                  resolver: yupResolver(
                    yup.object().shape({
                      description: yup.string().optional(),
                      startDate: yup
                        .date()
                        .required("Veillez choisir une date de debut"),
                      isSimulation: yup.boolean().optional(),
                      endDate: yup
                        .date()
                        .required("Veillez choisir une date de fin"),
                      userRole: yup
                        .string()
                        .required("Veillez choisir un rôle"),
                    })
                  ),
                }}
              >
                <div className="px-1 bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
                  <div className="px-1 mt-1 border rounded-md">
                    <FormCheckToggle
                      type="boolean"
                      name="isSimulation"
                      label="Simuler le paiement des commissions."
                      checked={simulatePayment}
                      onValueChanged={(isChecked) =>
                        setSimulatePayment(isChecked)
                      }
                    />
                  </div>
                  <FormInput
                    type="date"
                    name="startDate"
                    label="Date de début"
                    defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  />
                  <FormInput
                    type="date"
                    name="endDate"
                    label="Date de fin"
                    defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  />
                  <FormSelect<string>
                    options={userRole}
                    selectedOption={userRole[0]}
                    name="userRole"
                    label="Role"
                  />
                  <FormTextArea name="description" label="Description." />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showPinCodeModal}
        maxSize="md"
        closeModal={() => setShowPinCodeModal(false)}
      >
        <div className="flex justify-center">
          <CodePinComponent
            onSubmit={onSubmit}
            disabled={isCodePinBtnDesabled}
            isLoad={isLoad}
          />
        </div>
      </Modal>
    </div>
  );
};

export default PaidCommission;
