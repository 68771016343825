import { classNames } from "../utils";

export type DisplayPriceProps = {
  price: number;
  money?: string;
  priceClasses?: string;
  moneyClasses?: string;
};

export const DisplayPrice = ({
  price,
  priceClasses,
  money = "Fcfa",
  moneyClasses,
}: DisplayPriceProps) => {
  return (
    <div className="flex items-baseline">
      <p
        className={classNames(
          priceClasses ?? "",
          "text-2xl font-semibold text-gray-900"
        )}
      >
        {new Intl.NumberFormat().format(parseInt(price.toString()))}
      </p>
      <p
        className={classNames(
          moneyClasses ?? "",
          "flex items-baseline ml-2 text-sm font-semibold"
        )}
      >
        {money}
      </p>
    </div>
  );
};
