/* eslint-disable jsx-a11y/alt-text */
import { ArrowSmallDownIcon, QrCodeIcon } from "@heroicons/react/24/outline";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import CodePinComponent from "../../../components/CodePinComponent";
import Modal from "../../../components/Modal";
import ReadeQrcode from "../../../components/Qrcode/ReadeQrcode";
import {
  usersCollectionName,
  accountsCollectionName,
  defaultAmount,
  membershipCollectionName,
} from "../../../config";
import { UserContext } from "../../../contexts";
import { db, functions } from "../../../firebase";
import { AccountType } from "../../../interfaces";
import { classNames, isNumeric } from "../../../utils";
import { useRouter } from "../../../hooks/useRouter";

const SankPartenairsWithdrawMoneyPage = () => {
  const [receiverData, setreceiverData] = useState<any>([]);
  const [qrcodeScannedData, setQrcodeScannedData] = useState<string[]>([]);
  const [isDataGet, setIsDataGet] = useState(false);
  const [amount, setAmount] = useState<string>("0");
  const { user, setUserAccounts } = useContext(UserContext);
  const [showQrcodeReadeModal, setShowQrcodeReadeModal] = useState(false);
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [userTypedNumber, setUserTypedNumber] = useState("");
  const [isLoad, setIsLoad] = useState(false);
  const [receiverDataGetWithAgentCode, setReceiverDataGetWithAgentCode] =
    useState<any>(null);
  const [isCodePinBtnDesabled, setIsCodePinBtnDesabled] = useState(false);
  const router = useRouter();
  const [description, setDescription] = useState("");
  const [isMember, setIsMember] = useState(false);

  const userRef = db.collection(usersCollectionName);
  const senderAccountRef = userRef
    .doc(user?.id)
    .collection(accountsCollectionName);
  const membershiptRef = db.collection(membershipCollectionName);
  const sankmoneyWithdrawPartenersMoneyFn = functions.httpsCallable(
    "sankmoneyWithdrawPartenersMoneyFn"
  );

  const handleChangeAmountValue = (e: any) => {
    e.preventDefault();
    setAmount(`${e.target.value}` ?? defaultAmount);
  };

  const handleDescriptionChange = (e: any) => {
    e.preventDefault();
    const value = e.target.value;
    setDescription(value);
  };

  const getReceiverData = async () => {
    setShowQrcodeReadeModal(false);
    if (qrcodeScannedData.length > 0 && !isDataGet) {
      const receiverSnap = await db
        .collection(usersCollectionName)
        .where("agentCode", "==", qrcodeScannedData)
        .get();
      if (!receiverSnap.empty) {
        setIsDataGet(true);
        const data = receiverSnap.docs[0].data();
        setreceiverData([data]);
        const membershipSanp = await membershiptRef
          .where("memberId", "==", receiverSnap.docs[0].id)
          .get();
        if (!membershipSanp.empty) {
          setIsMember(true);
        }
      }
    }
  };

  const getFilterdByAgentCode = async (agentCode: any) => {
    if (agentCode !== undefined) {
      if (agentCode?.length === 6) {
        setUserTypedNumber(agentCode);
        const destUserSnapshot = await db
          .collection(usersCollectionName)
          .where("agentCode", "==", agentCode)
          .get();
        if (destUserSnapshot.empty) {
          toast.error("Le code agent n'existe pas.");
          router.push("/partenaires_withdraw");
        } else {
          const destreceiverData = [];
          for (const destUserDoc of destUserSnapshot.docs) {
            const destUserdAllata = destUserDoc.data();
            const destUserAccountSnapshot = await destUserDoc.ref
              .collection(accountsCollectionName)
              .get();
            const destUserAccountData = destUserAccountSnapshot.docs
              .map((accountDoc) => accountDoc.data())
              .find((data) => data.accountType === AccountType.MAIN);
            const destUserWithAccounts = {
              account: destUserAccountData,
              ...destUserdAllata,
            };
            destreceiverData.push(destUserWithAccounts);
          }
          const membershipSanp = await membershiptRef
            .where("memberId", "==", destUserSnapshot.docs[0].id)
            .get();
          if (!membershipSanp.empty) {
            setIsMember(true);
          }
          return setReceiverDataGetWithAgentCode(destreceiverData[0]);
        }
      }
    } else {
      setReceiverDataGetWithAgentCode(null);
    }
  };

  const onSubmit = async (pinCode: string) => {
    setIsCodePinBtnDesabled(true);
    if (!isNumeric(amount)) {
      setIsCodePinBtnDesabled(false);
      setShowPinCodeModal(false);
      return toast.error(`Le montant n'est pas bien formatté`);
    }
    if (amount < defaultAmount) {
      setIsCodePinBtnDesabled(false);
      setShowPinCodeModal(false);
      return toast.error(
        `Le montant doit être suppérieur à ${defaultAmount} Fcfa`
      );
    }
    if (
      user &&
      (receiverDataGetWithAgentCode || receiverData.length > 0) &&
      isMember
    ) {
      if (user?.id === (receiverDataGetWithAgentCode || receiverData[0])?.id) {
        toast.error("Vous ne pouvez pas effectuer cet type de transaction");
        setIsCodePinBtnDesabled(false);
      } else {
        setIsLoad(true);
        await sankmoneyWithdrawPartenersMoneyFn({
          pinCode: pinCode,
          amount: amount.replace(/\s/g, "").trim(),
          senderPhoneNumber: user?.phoneNumber,
          agentCode:
            receiverData.length > 0
              ? receiverData[0]?.agentCode
              : receiverDataGetWithAgentCode &&
                receiverDataGetWithAgentCode?.agentCode,
          description: description ?? "",
          withConnection: true,
        })
          .then(async (result) => {
            setIsLoad(false);
            setShowPinCodeModal(false);
            router.push("/dashboard");
            const accounts = await senderAccountRef.get();
            setUserAccounts(accounts.docs.map((account) => account.data()));
            return toast.success("Le retrait a été effectuer avec succès");
          })
          .catch((err: { details: any; message: any }) => {
            setShowPinCodeModal(false);
            setIsCodePinBtnDesabled(false);
            setIsLoad(false);
            toast.error(err.message);
          });
      }
    } else {
      toast.error(
        "Vous ne pouvez pas effectuer un retrait auprès de cet agent."
      );
      router.push("/dashboard");
    }
  };

  const onQrcodeResult = (result: any) => {
    setShowQrcodeReadeModal(false);
    if (result) {
      setQrcodeScannedData(result);
      setShowQrcodeReadeModal(false);
      getReceiverData();
    }
  };
  const onQrcodeError = (error: any) => {
    setShowQrcodeReadeModal(false);
  };
  return (
    <div className="flex justify-center w-full">
      <div className="w-full max-w-md px-2 py-16 sm:px-0">
        <div className="flex items-center justify-center w-full font-manrope">
          <div className="box-border w-full p-4 mx-auto bg-white border">
            <div className="flex justify-end text-red-500">
              <QrCodeIcon
                className="w-6 h-6 cursor-pointer text-red-red-500"
                aria-hidden="true"
                onClick={() => {
                  setShowQrcodeReadeModal(true);
                }}
              />
            </div>
            {receiverData.length > 0 ? (
              <div></div>
            ) : (
              <div className="mt-6">
                <div>
                  <label
                    htmlFor="code"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Entrez le code l'agent.
                  </label>
                  <input
                    type="text"
                    id="code"
                    maxLength={7}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Ex: 0256878"
                    onChange={(e) => getFilterdByAgentCode(e.target.value)}
                  />

                  {!receiverDataGetWithAgentCode ? (
                    receiverData.length > 0 ? (
                      <div></div>
                    ) : (
                      userTypedNumber.length !== 7 &&
                      userTypedNumber.length > 0 && (
                        <p className="mt-1 text-xs text-red-500">
                          Numéro invalide
                        </p>
                      )
                    )
                  ) : (
                    <p className="mt-1 text-xs text-green-500">Numéro valide</p>
                  )}
                </div>
              </div>
            )}
            <div className="mt-6">
              <label
                htmlFor="amount"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Combien souhaitez-vous retirer ?
              </label>
              <input
                id="amount"
                className="mt-1 w-full rounded-[4px] border border-[#A0ABBB] p-2"
                type="number"
                placeholder="Ex: 1000"
                pattern="[0-9]*"
                min={0}
                onChange={handleChangeAmountValue}
              />
            </div>
            <div className="mt-6">
              <label
                htmlFor="message"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Entrez une descriptions de la transaction.
              </label>
              <textarea
                id="message"
                rows={4}
                onChange={handleDescriptionChange}
                maxLength={200}
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Écrivez votre description ici..."
              ></textarea>
            </div>
            <div className="mt-6">
              <div className="flex items-center gap-x-[10px] bg-neutral-100 p-3 mt-2 rounded-[4px]">
                <img
                  className="w-10 h-10 rounded-full"
                  src={
                    user?.profilImageUrl
                      ? user?.profilImageUrl
                      : `https://ui-avatars.com/api/?background=random&name=${
                          user?.displayName || "S"
                        }`
                  }
                />
                <div>
                  <div className="font-semibold">{user?.displayName}</div>
                </div>
              </div>
            </div>
            {(receiverDataGetWithAgentCode || receiverData.length > 0) && (
              <>
                <div className="flex justify-center mt-2">
                  <ArrowSmallDownIcon className="h-6 text-red-500" />
                </div>
                <div className="mt-6">
                  <div className="flex items-center gap-x-[10px] bg-neutral-100 p-3 mt-2 rounded-[4px]">
                    <img
                      className="w-10 h-10 rounded-full"
                      src={
                        (receiverDataGetWithAgentCode || receiverData[0])
                          ?.profilImageUrl
                          ? (receiverDataGetWithAgentCode || receiverData[0])
                              ?.profilImageUrl
                          : `https://ui-avatars.com/api/?background=random&name=${
                              (receiverDataGetWithAgentCode || receiverData[0])
                                ?.displayName || "S"
                            }`
                      }
                      alt=""
                    />
                    <div>
                      <div className="font-semibold">
                        {
                          (receiverDataGetWithAgentCode || receiverData[0])
                            ?.displayName
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="mt-6">
              <button
                className={classNames(
                  amount ? "bg-red-600  cursor-pointer" : "bg-gray-600",
                  "w-full rounded-[4px]  px-3 py-[6px] text-center font-semibold text-white"
                )}
                onClick={() => amount && setShowPinCodeModal(true)}
                disabled={
                  receiverDataGetWithAgentCode || receiverData[0] ? false : true
                }
              >
                Retirer:{" "}
                {new Intl.NumberFormat().format(parseInt(amount ?? "0"))} Fcfa
              </button>
            </div>
          </div>
        </div>
      </div>
      <ReadeQrcode
        onQrcodeResult={(result: any) => onQrcodeResult(result)}
        onQrcodeError={(error: any) => onQrcodeError(error)}
        showQrcodeReadeModal={showQrcodeReadeModal}
        setShowQrcodeReadeModal={setShowQrcodeReadeModal}
      />
      <Modal
        isOpen={showPinCodeModal}
        maxSize="md"
        closeModal={() => setShowPinCodeModal(false)}
      >
        <div className="flex justify-center">
          <CodePinComponent
            onSubmit={onSubmit}
            disabled={isCodePinBtnDesabled}
            isLoad={isLoad}
          />
        </div>
      </Modal>
    </div>
  );
};
export default SankPartenairsWithdrawMoneyPage;
