/* eslint-disable jsx-a11y/alt-text */
import {
  BoltIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import {
  getCountFromServer,
  collection,
  query,
  where,
} from "firebase/firestore";
import { Timestamp } from "firebase-admin/firestore";
import { useContext, useEffect, useState } from "react";
import DealerOrAgentDashboardComponent from "../../components/DashboardComponents/DashboardActionButtonComponent";
import GenerateQrcode from "../../components/Qrcode/GenerateQrcode";
import { UserContext } from "../../contexts";
import { db, functions } from "../../firebase";
import {
  amountFormater,
  classNames,
  getTimestamp,
  getTransactionLabel,
} from "../../utils";
import NoContent from "../../components/TableNoContentPage/NoContent";
import {
  cardsCollectionName,
  commissionsCollectionsName,
  gasStationMembershipCollectionName,
  sankmoneyDealerAccountRefillsAwaitingValidation,
  sankmoneyRefillsAwaitingValidation,
  transactionCollectionName,
  usersAffiliatedToAgent,
  usersCollectionName,
  referralsCollectionName,
} from "../../config";
import HeaderDashboardComponent from "../../components/DashboardComponents/HeaderDashboardComponent";
import SankMoneyIcon from "../../CFA.png";
import {
  SankMoneyReloadProcessStatusenum,
  UserInterface,
  UserRole,
  accountMap,
} from "../../interfaces";
import moment from "moment";
import Modal from "../../components/Modal";
import Moment from "react-moment";
import toast from "react-hot-toast";
import imageSrc from "../../sankQrCodeImg.png";
import CardImage from "../../340_x_200_1.png";
import { QRCodeCanvas } from "qrcode.react";
import { useNavigate } from "react-router-dom";
import { MagnifyingGlassIcon, QrCodeIcon } from "@heroicons/react/24/outline";
import ReadeQrcode from "../../components/Qrcode/ReadeQrcode";

const tableHeader = [
  "N°",
  "",
  "Nom destinataire",
  "Numéro destinataire",
  "Montant",
  "Type de transaction",
  "Compte",
  "Frais",
  "Status",
  "Date de création",
];
function Dashboard() {
  const { user, userAccounts, agencyAgent, gasStationInfo, company } =
    useContext(UserContext);
  const [transactions, setTransactions] = useState<any[]>([]);
  const [histories, setHistories] = useState<any[]>([]);
  const [commissionPerDay, setCommissionPerDay] = useState<number>(0);
  const [completedTransactions, setCompletedTransactions] = useState<any[]>([]);
  const [weeklyTransactions, setWeeklyTransactions] = useState<any[]>([]);
  const [showQrcodeGenerateModal, setShowQrcodeGenerateModal] = useState(false);
  const [showCardQrcodeReadeModal, setShowCardQrcodeReadeModal] =
    useState(false);
  const [isDataGet, setIsDataGet] = useState(false);
  const [qrcodeScannedData, setQrcodeScannedData] = useState<string[]>([]);
  const [cardId, setCardId] = useState("");
  const [usersAffiliatedToAgentCount, setUsersAffiliatedToAgentCount] =
    useState<number>(0);
  const [
    showrefillsAwaitingValidationModal,
    setShowrefillsAwaitingValidationModal,
  ] = useState(false);
  const [
    showDealerAccountrefillsAwaitingValidationModal,
    setShowDealerAccountRefillsAwaitingValidationModal,
  ] = useState(false);
  const [gasStationRelationId, setGasStationRelationId] = useState("");
  const [retrieveDailySubscriberUsers, setRetrieveDailySubscriberUsers] =
    useState<number>(0);
  const [retrieveSubscriberUsers, setRetrieveSubscriberUsers] =
    useState<number>(0);
  const [retrieveSubscriberAgents, setRetrieveSubscriberAgents] =
    useState<number>(0);
  const [retrieveSubscriberDealers, setRetrieveSubscriberDealers] =
    useState<number>(0);
  const [retrieveNumberOfDealerAgent, setRetrieveNumberOfDealerAgent] =
    useState<number>(0);
  const [retrieveNumberOfSellerUser, setRetrieveNumberOfSellerUser] =
    useState<number>(0);
  const [retrieveAllTransactions, setRetrieveAllTransactions] =
    useState<number>(0);
  const [searchWithStartDateValue] = useState<string>(
    `${moment(Date.now()).format("YYYY-MM-DD")}`
  );
  const [seachWithEndDateValue] = useState<string>(
    `${moment(Date.now()).format("YYYY-MM-DD")}`
  );
  const startTime = getTimestamp(searchWithStartDateValue, true);
  const endTime = getTimestamp(seachWithEndDateValue, false);
  const [currentUserStatus, setCurrentUserStatus] = useState(false);
  const [refillsAwaitingValidation, setRefillsAwaitingValidation] =
    useState<any>();
  const [
    dealerAccountrefillsAwaitingValidation,
    setDealerAccountRefillsAwaitingValidation,
  ] = useState<any>();
  const [showAssignCardToUserModal, setShowAssignCardToUserModal] =
    useState(false);
  const [showSnblPrepaidBalance, setShowSnblPrepaidBalance] = useState(false);
  const [snblPrepaidBalance, setSnblPrepaidBalance] = useState<any>();
  const [card, setCard] = useState<any[]>([]);
  const navigate = useNavigate();

  const imageOptions = {
    src: imageSrc,
    x: undefined,
    y: undefined,
    height: 30,
    width: 30,
    excavate: true,
  };

  const retrieveGasStationId = async () => {
    if (user?.attendantRole) {
      const membershipRef = db.collection(gasStationMembershipCollectionName);
      const membershipSnaphot = await membershipRef
        .where("memberId", "==", user?.id)
        .get();
      const membershipData = membershipSnaphot.docs[0]?.data();
      if (membershipData) {
        setGasStationRelationId(membershipData?.gasStationId);
        return membershipData?.gasStationId as string;
      }
    }
  };

  const getAllUsersTransactions = async () => {
    const gasStationId = await retrieveGasStationId();
    const transactionRef = db.collection(transactionCollectionName);
    await transactionRef
      .orderBy("createdAt", "desc")
      .where(
        "participantsIds",
        "array-contains",
        user?.userAgencySubRole
          ? agencyAgent?.id
          : user?.attendantRole
          ? gasStationId
          : company
          ? company?.id
          : user?.id
      )
      .get()
      .then((response) => {
        setTransactions(
          response.docs.map((d) => {
            return { id: d.id, data: d.data() };
          })
        );
        setCompletedTransactions(
          response.docs.filter((trans) => trans.data().isComplete === true)
        );
      });
    // Obtenir la date du jour
    const dateDay = new Date();

    // Obtenir le premier jour de la semaine
    const firstDayOfWeek = dateDay.getDate() - dateDay.getDay() + 1;
    const startDate = new Date(dateDay.setDate(firstDayOfWeek));

    // Calculer le timestamp du premier jour de la semaine
    const timestampStartWeek = getTimestamp(startDate.toDateString(), true);

    // Calculer le dernier jour de la semaine
    const lastDayOfWeek = firstDayOfWeek + 6;
    const endDate = new Date(dateDay.setDate(lastDayOfWeek));

    // Calculer le timestamp du dernier jour de la semaine
    const timestampEndWeek = getTimestamp(endDate.toDateString(), false);

    await transactionRef
      .orderBy("createdAt", "desc")
      .where(
        "participantsIds",
        "array-contains",
        user?.userAgencySubRole
          ? agencyAgent?.id
          : user?.attendantRole
          ? gasStationId
          : company
          ? company?.id
          : user?.id
      )
      .where("createdAt", ">=", timestampStartWeek)
      .where("createdAt", "<=", timestampEndWeek)
      .get()
      .then((response) => {
        setWeeklyTransactions(
          response.docs.map((d) => {
            return { id: d.id, data: d.data() };
          })
        );
      });
  };

  const getCommissionPerDay = async () => {
    const id = user?.id;
    const gasStationId = gasStationInfo && gasStationInfo?.id;
    if (
      user?.userRole === UserRole.ADMIN ||
      user?.userRole === UserRole.AGENT ||
      user?.userRole === UserRole.DEALER ||
      user?.userRole === UserRole.MARCHAND ||
      user?.userRole === UserRole.GASSTATIONSTAFF
    ) {
      await db
        .collection(commissionsCollectionsName)
        .orderBy("createdAt", "desc")
        .where("userId", "==", gasStationId ? gasStationId : id)
        .where("commissionPaid", "==", false)
        .get()
        .then((response) => {
          let sum = 0;
          response.docs.forEach((el) => {
            sum += el.data().amount;
          });
          setCommissionPerDay(sum);
        });
    }
  };

  const getAllHistories = async () => {
    const userRef = db.collection(usersCollectionName);
    const currentUserSnap = await userRef.doc(user?.id).get();
    setCurrentUserStatus(currentUserSnap.data()?.active ? false : true);
    await db
      .collection(transactionCollectionName)
      .orderBy("createdAt", "desc")
      .where(
        "participantsIds",
        "array-contains",
        company ? company.id : user?.id
      )
      .limit(5)
      .get()
      .then((response) => {
        setHistories(
          response.docs.map((d) => {
            return { id: d.id, data: d.data() };
          })
        );
      });
  };

  const getDailySubscriberUsers = async () => {
    const nbOfUsersWithRoleUser = (
      await getCountFromServer(
        query(
          collection(db, usersCollectionName),
          where("userRole", "==", UserRole.USER)
        )
      )
    ).data().count;
    setRetrieveSubscriberUsers(nbOfUsersWithRoleUser);

    const nbOfUsersWithRoleUserAndDateFilter = (
      await getCountFromServer(
        query(
          collection(db, usersCollectionName),
          where("userRole", "==", UserRole.USER),
          where("createdAt", ">=", startTime),
          where("createdAt", "<=", endTime)
        )
      )
    ).data().count;
    setRetrieveDailySubscriberUsers(nbOfUsersWithRoleUserAndDateFilter);

    const nbOfUsersWithRoleAgent = (
      await getCountFromServer(
        query(
          collection(db, usersCollectionName),
          where("userRole", "==", UserRole.AGENT)
        )
      )
    ).data().count;
    setRetrieveSubscriberAgents(nbOfUsersWithRoleAgent);

    const nbOfUsersWithRoleDealer = (
      await getCountFromServer(
        query(
          collection(db, usersCollectionName),
          where("userRole", "==", UserRole.DEALER)
        )
      )
    ).data().count;
    setRetrieveSubscriberDealers(nbOfUsersWithRoleDealer);
  };

  const retriveUsersAffiliatedToAgent = async () => {
    const usersAffiliatedAgentRef = db
      .collection(usersCollectionName)
      .doc(user?.id)
      .collection(usersAffiliatedToAgent)
      .doc(user?.id);
    const snap = await usersAffiliatedAgentRef.get();
    setUsersAffiliatedToAgentCount(snap.data()?.usersAffiliatedToAgent);
  };

  const retirveTransactions = async () => {
    const nbOfAllTransactions = (
      await getCountFromServer(
        query(
          collection(db, transactionCollectionName),
          where("createdAt", ">=", startTime),
          where("createdAt", "<=", endTime)
        )
      )
    ).data().count;

    setRetrieveAllTransactions(nbOfAllTransactions);
  };

  const getNumberOfDealerAgent = async () => {
    const nbOfDealerAgent = (
      await getCountFromServer(
        query(
          collection(db, usersCollectionName),
          where("dealerId", "==", user?.id)
        )
      )
    ).data().count;
    setRetrieveNumberOfDealerAgent(nbOfDealerAgent);
  };

  const getNumberOfSellerUser = async () => {
    const nbOfSellerUser = (
      await getCountFromServer(
        query(
          collection(db, referralsCollectionName),
          where("referralPhoneNumber", "==", user?.phoneNumber)
        )
      )
    ).data().count;
    setRetrieveNumberOfSellerUser(nbOfSellerUser);
  };

  const validateSankMoneyReloadAccountFn = functions.httpsCallable(
    "validateSankMoneyReloadAccountFn"
  );
  const validateSankMoneyDealerReloadAccountFn = functions.httpsCallable(
    "validateSankMoneyDealerReloadAccountFn"
  );
  const sankMoneyRetrieveSnblPrepaidAccountBalanceFn = functions.httpsCallable(
    "sankMoneyRetrieveSnblPrepaidAccountBalanceFn"
  );

  const retrieveSnblPrepaidBalance = () => {
    const response = sankMoneyRetrieveSnblPrepaidAccountBalanceFn({
      currentUserId: user?.id,
    }).then((val) => {
      setShowSnblPrepaidBalance(true);
      setSnblPrepaidBalance(val.data.data);
    });
    toast.promise(response, {
      error: (error: any) => error.message,
      success: "Le solde a récupéré avec succès.",
      loading: "chargement...",
    });
  };

  const validateRefillsAwaitingValidation = () => {
    setShowrefillsAwaitingValidationModal(false);
    const data: any = {};
    data[`${user?.id}`] = true;
    const response = validateSankMoneyReloadAccountFn({
      ...data,
      counter: 1,
      id: refillsAwaitingValidation.data.id,
    }).then(() => {
      setShowrefillsAwaitingValidationModal(false);
      setRefillsAwaitingValidation({
        exist: false,
        data: undefined,
      });
    });
    toast.promise(response, {
      error: (error: any) => error.message,
      success: "La transaction a été validé avec succès.",
      loading: "chargement...",
    });
  };

  const validateDealerAccountRefillsAwaitingValidation = () => {
    setShowrefillsAwaitingValidationModal(false);
    const data: any = {};
    data[`${user?.id}`] = true;
    const response = validateSankMoneyDealerReloadAccountFn({
      ...data,
      counter: 1,
      accountType: dealerAccountrefillsAwaitingValidation.data.accountType,
      dealerId: dealerAccountrefillsAwaitingValidation.data.dealerId,
      id: dealerAccountrefillsAwaitingValidation.data.id,
    }).then(() => {
      setShowDealerAccountRefillsAwaitingValidationModal(false);
      setDealerAccountRefillsAwaitingValidation({
        exist: false,
        data: undefined,
      });
    });
    toast.promise(response, {
      error: (error: any) => error.message,
      success: "La transaction a été validé avec succès.",
      loading: "chargement...",
    });
  };

  const retriveRefillsAwaitingValidationData = async () => {
    const retriverefillsAwaitingValidationCollectionRef = db.collection(
      sankmoneyRefillsAwaitingValidation
    );
    const id = user?.id;
    const retriverefillsAwaitingValidationSnap =
      await retriverefillsAwaitingValidationCollectionRef
        .where("participantIds", "array-contains", id)
        .where(`${id}`, "==", false)
        .where("status", "==", SankMoneyReloadProcessStatusenum.PENDING)
        .get();
    setRefillsAwaitingValidation({
      exist: retriverefillsAwaitingValidationSnap.empty ? false : true,
      data: retriverefillsAwaitingValidationSnap.empty
        ? undefined
        : retriverefillsAwaitingValidationSnap.docs[0].data(),
    });
  };

  const retriveDealerAccountRefillsAwaitingValidationData = async () => {
    const retriverefillsAwaitingValidationCollectionRef = db.collection(
      sankmoneyDealerAccountRefillsAwaitingValidation
    );
    const id = user?.id;
    const retriverefillsAwaitingValidationSnap =
      await retriverefillsAwaitingValidationCollectionRef
        .where("participantIds", "array-contains", id)
        .where(`${id}`, "==", false)
        .where("status", "==", SankMoneyReloadProcessStatusenum.PENDING)
        .get();
    setDealerAccountRefillsAwaitingValidation({
      exist: retriverefillsAwaitingValidationSnap.empty ? false : true,
      data: retriverefillsAwaitingValidationSnap.empty
        ? undefined
        : retriverefillsAwaitingValidationSnap.docs[0].data(),
    });
  };

  const retriveCardData = (cardId: string) => {
    db.collection(cardsCollectionName)
      .where("id", "==", cardId)
      .where("isAffected", "==", false)
      .get()
      .then((response) => {
        if (response.empty) {
          toast.error("La carte est indisponible ou déjà affecté.");
        } else {
          setCard(
            response.docs.map((doc) => {
              return { id: doc.id, data: doc.data() };
            })
          );
        }
      });
  };
  const handleChange = (value: string) => {
    setCardId(value);
  };

  const getsenderData = async () => {
    setShowCardQrcodeReadeModal(false);
    if (qrcodeScannedData.length > 0 && !isDataGet) {
      const cardId = qrcodeScannedData[0];
      await db
        .collection(cardsCollectionName)
        .doc(cardId)
        .get()
        .then(async (response) => {
          if (response.exists) {
            setIsDataGet(true);
            navigate(`/cards/${response.data()?.id}/assign-card`, {
              state: {
                entity: response.data(),
                id: response.data()?.id,
                isCardAffected: true,
              },
            });
          } else {
            toast.error("La carte est introuvable.");
          }
        });
    }
  };

  const onQrcodeResult = (result: any) => {
    setShowCardQrcodeReadeModal(false);
    if (result && !isDataGet) {
      setQrcodeScannedData(result);
      setShowCardQrcodeReadeModal(false);
      getsenderData();
    }
  };
  const onQrcodeError = (error: any) => {
    setShowCardQrcodeReadeModal(false);
  };

  useEffect(() => {
    if (user) {
      getAllHistories();
      getCommissionPerDay();
      getAllUsersTransactions();
      if (
        user?.userRole === UserRole.ADMIN ||
        user?.userRole === UserRole.SUPERVISOR
      ) {
        getDailySubscriberUsers();
        retirveTransactions();
      }
      if (user?.userRole === UserRole.AGENT) {
        retriveUsersAffiliatedToAgent();
      }
      if (user?.isSeller) {
        getNumberOfSellerUser();
      }
      if (user?.reviewer) {
        retriveRefillsAwaitingValidationData();
      }
      if (user?.dealerReviewer) {
        retriveDealerAccountRefillsAwaitingValidationData();
      }
      if (user?.userRole === UserRole.DEALER) {
        getNumberOfDealerAgent();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DealerOrAgentDashboardComponent
        user={user}
        currentUserStatus={currentUserStatus}
        setShowQrcodeGenerateModal={setShowQrcodeGenerateModal}
        transactions={transactions}
        userAccountsData={userAccounts as any}
        commissions={commissionPerDay}
        refillsAwaitingValidation={refillsAwaitingValidation}
        setShowrefillsAwaitingValidationModal={
          setShowrefillsAwaitingValidationModal
        }
        setShowDealerAccountRefillsAwaitingValidationModal={
          setShowDealerAccountRefillsAwaitingValidationModal
        }
        dealerAccountrefillsAwaitingValidation={
          dealerAccountrefillsAwaitingValidation
        }
        usersAffiliatedToAgent={usersAffiliatedToAgentCount}
        setShowAssignCardToUserModal={setShowAssignCardToUserModal}
        retrieveSnblPrepaidBalance={retrieveSnblPrepaidBalance}
      />
      <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200" />
      <HeaderDashboardComponent
        transctionCount={transactions?.length}
        completedTransactionCount={completedTransactions.length}
        transactionOnWeek={weeklyTransactions?.length}
        isAdmin={user?.userRole === UserRole.ADMIN ? true : false}
        retrieveAllTransactions={retrieveAllTransactions}
        retrieveSubscriberUsers={retrieveSubscriberUsers}
        retrieveDailySubscriberUsers={retrieveDailySubscriberUsers}
        retrieveSubscriberAgents={retrieveSubscriberAgents}
        retrieveSubscriberDealers={retrieveSubscriberDealers}
        retrieveNumberOfDealerAgent={retrieveNumberOfDealerAgent}
        retrieveNumberOfSellerUser={retrieveNumberOfSellerUser}
        currentuser={user as UserInterface}
      />

      <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200" />
      <div className="px-4 mt-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="py-2 text-xl font-semibold text-gray-900">
              Mes dernières transactions.
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Vous trouverez ici l'ensemble de vos dernières historiques de
              transactions.
            </p>
          </div>
        </div>
        <div className="flex flex-col mt-8">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                <table
                  className="min-w-full border-separate"
                  style={{ borderSpacing: 0 }}
                >
                  <thead className="bg-gray-50">
                    <tr>
                      {tableHeader.map((th, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                        >
                          {th}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {histories && histories.length > 0 ? (
                      histories.map((history: any, historyIdx: number) => (
                        <tr key={historyIdx}>
                          <td
                            className={classNames(
                              historyIdx !== histories.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {(historyIdx as number) + 1}
                          </td>
                          <td
                            className={classNames(
                              historyIdx !== histories.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {(user?.userAgencySubRole
                              ? agencyAgent?.id
                              : user?.attendantRole
                              ? gasStationRelationId
                              : company
                              ? company.id
                              : user?.id) === history.data.senderID ? (
                              <img
                                className="w-10 h-10 p-1 border rounded-full"
                                src={
                                  history.data.receiverProfilImageUrl
                                    ? history.data.receiverProfilImageUrl
                                    : `https://ui-avatars.com/api/?background=random&name=${
                                        history.data.receiverDisplayName || "S"
                                      }`
                                }
                                alt=""
                              />
                            ) : (
                              <img
                                className="w-10 h-10 p-1 border rounded-full"
                                src={
                                  history.data.senderProfilImageUrl
                                    ? history.data.senderProfilImageUrl
                                    : `https://ui-avatars.com/api/?background=random&name=${
                                        history.data.senderDisplayName || "S"
                                      }`
                                }
                                alt=""
                              />
                            )}
                          </td>
                          <td
                            className={classNames(
                              historyIdx !== histories.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {(user?.userAgencySubRole
                              ? agencyAgent?.id
                              : user?.attendantRole
                              ? gasStationRelationId
                              : company
                              ? company.id
                              : user?.id) === history.data.senderID
                              ? history.data.receiverDisplayName
                                ? history.data.receiverDisplayName
                                : "-"
                              : history.data.senderDisplayName
                              ? history.data.senderDisplayName
                              : "-"}
                          </td>
                          <td
                            className={classNames(
                              historyIdx !== histories.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {(user?.userAgencySubRole
                              ? agencyAgent?.id
                              : user?.attendantRole
                              ? gasStationRelationId
                              : company
                              ? company.id
                              : user?.id) === history.data.senderID
                              ? (history.data.receiverPhoneNumber as string)
                              : (history.data.senderPhoneNumber as string)}
                          </td>
                          <td
                            className={classNames(
                              historyIdx !== histories.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {new Intl.NumberFormat().format(
                              history.data.amount
                            )}{" "}
                            Fcfa
                          </td>
                          <td
                            className={classNames(
                              historyIdx !== histories.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {(user?.userAgencySubRole
                              ? agencyAgent?.id
                              : user?.attendantRole
                              ? gasStationRelationId
                              : company
                              ? company.id
                              : user?.id) === history.data.senderID
                              ? getTransactionLabel(
                                  (user?.userAgencySubRole
                                    ? agencyAgent?.id
                                    : user?.attendantRole
                                    ? gasStationRelationId
                                    : company
                                    ? company.id
                                    : user?.id) ?? "",
                                  history.data.transactionType,
                                  history.data.senderID
                                )
                              : getTransactionLabel(
                                  (user?.userAgencySubRole
                                    ? agencyAgent?.id
                                    : user?.attendantRole
                                    ? gasStationRelationId
                                    : company
                                    ? company.id
                                    : user?.id) ?? "",
                                  history.data.transactionType,
                                  history.data.receiverID
                                )}
                          </td>
                          <td
                            className={classNames(
                              historyIdx !== histories.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {history.data.accountType === "MAIN" ? (
                              <img
                                className="w-auto h-5"
                                src={SankMoneyIcon}
                                alt="Sank"
                              />
                            ) : (
                              // <CurrencyDollarIcon className="w-6 h-6 text-red-500" />
                              <BoltIcon className="w-6 h-6 text-red-500" />
                            )}
                          </td>
                          <td
                            className={classNames(
                              historyIdx !== histories.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {history.data.fees as string} Fcfa
                          </td>
                          <td
                            className={classNames(
                              historyIdx !== histories.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {history.data.isComplete ? (
                              <CheckCircleIcon className="w-6 h-6 text-green-500" />
                            ) : (
                              <XCircleIcon className="w-6 h-6 text-red-500" />
                            )}
                          </td>
                          <td
                            className={classNames(
                              historyIdx !== histories.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {(history.data.createdAt as Timestamp)
                              .toDate()
                              .toLocaleString()}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <NoContent />
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GenerateQrcode
        showQrcodeReadeModal={showQrcodeGenerateModal}
        setShowQrcodeReadeModal={setShowQrcodeGenerateModal}
      />
      <Modal
        isOpen={showrefillsAwaitingValidationModal}
        maxSize="md"
        closeModal={() => setShowrefillsAwaitingValidationModal(false)}
        modalTitle="Demande de Validation de Recharge du compte Sank-Money"
      >
        <div className="p-2 my-4 bg-white border rounded-lg shadow-sm">
          <div className="">
            <ul className="-mb-8">
              <li>
                <div className="pb-8 ">
                  <div className="flex ">
                    <div>
                      <span className="flex items-center justify-center w-6 h-6 rounded-full ring-8 ring-white dark:ring-slate-800"></span>
                    </div>
                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                      <div>
                        <p className="text-sm text-gray-500">
                          <span className="font-semibold text-gray-700">
                            Détails de la Recharge
                          </span>{" "}
                        </p>
                        <p className="mt-2 text-sm text-gray-500">
                          <span className="font-bold text-gray-700 dark:text-gray-400">
                            Compte :
                          </span>{" "}
                          <span className="text-sm text-gray-900 dark:text-gray-300">
                            compte principal Sank-Money
                          </span>
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          <span className="font-bold text-gray-700 dark:text-gray-400">
                            Montant de la Recharge :
                          </span>{" "}
                          <span className="text-sm text-gray-900 dark:text-gray-300">
                            {new Intl.NumberFormat().format(
                              parseInt(
                                refillsAwaitingValidation?.data?.amount ?? "0"
                              )
                            )}{" "}
                            FCFA
                          </span>
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          <span className="font-bold text-gray-700 dark:text-gray-400">
                            Date et Heure de la Demande :
                          </span>{" "}
                          <span className="text-sm text-gray-900 dark:text-gray-300">
                            <Moment
                              locale="fr"
                              format="dddd DD MMMM YYYY à HH:mm:ss"
                            >
                              {
                                refillsAwaitingValidation?.data?.createdAt.toDate() as Date
                              }
                            </Moment>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="pb-8 ">
                  <div className="flex ">
                    <div>
                      <span className="flex items-center justify-center w-6 h-6 rounded-full ring-8 ring-white dark:ring-slate-800"></span>
                    </div>
                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                      <div>
                        <p className="text-sm text-gray-500">
                          <span className="font-semibold text-gray-700">
                            Action Requise
                          </span>{" "}
                        </p>
                        <p className="mt-2 text-sm text-gray-500">
                          <span className="text-sm text-gray-900 dark:text-gray-300">
                            Nous vous prions de bien vouloir effectuer les
                            vérifications nécessaires pour vous assurer de
                            l'authenticité de cette demande de recharge. Si vous
                            constatez des anomalies ou si vous avez des raisons
                            de douter de la validité de cette recharge, veuillez
                            nous en informer immédiatement.
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex justify-end">
          {" "}
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-green-700 rounded-lg border border-green-300 bg-white hover:bg-green-50 focus:ring-4 focus:outline-none focus:ring-green-200 "
              onClick={() => {
                validateRefillsAwaitingValidation();
              }}
            >
              <span className="relative flex px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
                Valider
              </span>
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showDealerAccountrefillsAwaitingValidationModal}
        maxSize="md"
        closeModal={() =>
          setShowDealerAccountRefillsAwaitingValidationModal(false)
        }
        modalTitle="Demande de Validation de Recharge d'un compte dealer"
      >
        <div className="p-2 my-4 bg-white border rounded-lg shadow-sm">
          <div className="">
            <ul className="-mb-8">
              <li>
                <div className="pb-8 ">
                  <div className="flex ">
                    <div>
                      <span className="flex items-center justify-center w-6 h-6 rounded-full ring-8 ring-white dark:ring-slate-800"></span>
                    </div>
                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                      <div>
                        <p className="text-sm text-gray-500">
                          <span className="font-semibold text-gray-700">
                            Détails de la Recharge
                          </span>{" "}
                        </p>
                        <p className="mt-2 text-sm text-gray-500">
                          <span className="font-bold text-gray-700 dark:text-gray-400">
                            Compte :
                          </span>{" "}
                          <span className="text-sm text-gray-900 dark:text-gray-300">
                            compte principal dealer
                          </span>
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          <span className="font-bold text-gray-700 dark:text-gray-400">
                            Montant de la Recharge :
                          </span>{" "}
                          <span className="text-sm text-gray-900 dark:text-gray-300">
                            {new Intl.NumberFormat().format(
                              parseInt(
                                dealerAccountrefillsAwaitingValidation?.data
                                  ?.amount ?? "0"
                              )
                            )}{" "}
                            FCFA
                          </span>
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          <span className="font-bold text-gray-700 dark:text-gray-400">
                            Type de compte :
                          </span>{" "}
                          <span className="text-sm text-gray-900 dark:text-gray-300">
                            {accountMap.get(
                              dealerAccountrefillsAwaitingValidation?.data
                                ?.accountType
                            )}
                          </span>
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          <span className="font-bold text-gray-700 dark:text-gray-400">
                            Date et Heure de la Demande :
                          </span>{" "}
                          <span className="text-sm text-gray-900 dark:text-gray-300">
                            <Moment
                              locale="fr"
                              format="dddd DD MMMM YYYY à HH:mm:ss"
                            >
                              {
                                dealerAccountrefillsAwaitingValidation?.data?.createdAt.toDate() as Date
                              }
                            </Moment>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="pb-8 ">
                  <div className="flex ">
                    <div>
                      <span className="flex items-center justify-center w-6 h-6 rounded-full ring-8 ring-white dark:ring-slate-800"></span>
                    </div>
                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                      <div>
                        <p className="text-sm text-gray-500">
                          <span className="font-semibold text-gray-700">
                            Action Requise
                          </span>{" "}
                        </p>
                        <p className="mt-2 text-sm text-gray-500">
                          <span className="text-sm text-gray-900 dark:text-gray-300">
                            Nous vous prions de bien vouloir effectuer les
                            vérifications nécessaires pour vous assurer de
                            l'authenticité de cette demande de recharge. Si vous
                            constatez des anomalies ou si vous avez des raisons
                            de douter de la validité de cette recharge, veuillez
                            nous en informer immédiatement.
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex justify-end">
          {" "}
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-green-700 rounded-lg border border-green-300 bg-white hover:bg-green-50 focus:ring-4 focus:outline-none focus:ring-green-200 "
              onClick={() => {
                validateDealerAccountRefillsAwaitingValidation();
              }}
            >
              <span className="relative flex px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
                Valider
              </span>
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showAssignCardToUserModal}
        maxSize="md"
        closeModal={() => {
          setShowAssignCardToUserModal(false);
          setCard([]);
        }}
        modalTitle="Renseigner le numéro de la carte"
      >
        <div className="flex justify-center">
          <div className="w-full mt-10">
            <div className="">
              <div className="flex space-x-4">
                <input
                  type="text"
                  name="card"
                  id="card"
                  placeholder="3518112345678"
                  autoComplete="off"
                  autoFocus={true}
                  onChange={(e) => handleChange(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  required
                />
                <button
                  type="button"
                  className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  onClick={() => {
                    retriveCardData(cardId);
                  }}
                >
                  <MagnifyingGlassIcon
                    className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                    aria-hidden="true"
                  />
                  Rechercher
                </button>
              </div>

              <p className="my-3 font-light text-center">OU</p>

              <div className="">
                <button
                  type="submit"
                  className="relative space-x-4 inline-flex items-center justify-center  mb-2 mr-2 overflow-hidden bg-red-50 border border-red-300 text-red-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500  w-full p-2.5 dark:bg-red-700 dark:border-red-600 dark:placeholder-red-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  onClick={() => {
                    setShowAssignCardToUserModal(false);
                    setShowCardQrcodeReadeModal(true);
                  }}
                >
                  <QrCodeIcon
                    className="w-6 h-6 cursor-pointer text-red-red-500"
                    aria-hidden="true"
                  />
                  <div>Scanner le qrcode de la carte</div>
                </button>
              </div>
            </div>

            <div className="flex justify-center w-full">
              {card && card.length > 0 ? (
                <div className="w-full max-w-sm my-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <div className="flex flex-col items-center pb-10 mt-2">
                    <div
                      className="relative flex items-center justify-between w-full sm:w-[240px] sm:h-[150px] px-6 py-4 space-x-6 bg-cover rounded-md"
                      style={{
                        backgroundImage: `url(${CardImage})`,
                        width: "340px",
                        height: "200px",
                      }}
                    >
                      <div className="absolute top-0 left-0 m-2 text-sm font-semibold text-white">
                        {card[0].id}
                      </div>
                      <div className="absolute top-10 right-3">
                        <QRCodeCanvas
                          className="w-full"
                          id="qrCode"
                          value={`${card[0].id}`}
                          size={100}
                          level={"L"}
                          includeMargin={false}
                          bgColor="red"
                          fgColor="white"
                          imageSettings={imageOptions}
                        />
                      </div>
                    </div>
                    <div className="flex mt-4 space-x-3 md:mt-6">
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        onClick={() =>
                          navigate(`/cards/${card[0].data.id}/assign-card`, {
                            state: {
                              entity: card[0].data,
                              id: card[0].id,
                              isCardAffected: true,
                            },
                          })
                        }
                      >
                        Continuer
                      </div>
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                        onClick={() => {
                          setShowAssignCardToUserModal(false);
                          setCard([]);
                        }}
                      >
                        Annuler
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {card && card.length === 0 && (
                    <p className="mt-4 text-sm text-gray-400">
                      Veuillez saisir le numéro complet de la carte
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showSnblPrepaidBalance}
        maxSize="md"
        closeModal={() => {
          setShowSnblPrepaidBalance(false);
        }}
        modalTitle="Le solde SONABEL PREPAID"
      >
        <div className="p-2 my-4 bg-white border rounded-lg shadow-sm">
          <div className="">
            <ul className="-mb-8">
              <li>
                <div className="pb-8 ">
                  <div className="flex ">
                    <div>
                      <span className="flex items-center justify-center w-6 h-6 rounded-full ring-8 ring-white dark:ring-slate-800"></span>
                    </div>
                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                      <div>
                        <p className="text-sm text-gray-500">
                          <span className="font-semibold text-gray-700">
                            Détails du solde
                          </span>{" "}
                        </p>
                        <p className="mt-2 text-sm text-gray-500">
                          <span className="font-bold text-gray-700 dark:text-gray-400">
                            Compte :
                          </span>{" "}
                          <span className="text-sm text-gray-900 dark:text-gray-300">
                            {snblPrepaidBalance && snblPrepaidBalance?.username}
                          </span>
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          <span className="font-bold text-gray-700 dark:text-gray-400">
                            Solde :
                          </span>{" "}
                          <span className="text-sm text-gray-900 dark:text-gray-300">
                            {snblPrepaidBalance &&
                              amountFormater(
                                parseInt(snblPrepaidBalance?.balance)
                              )}
                            FCFA
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex justify-end">
          {" "}
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-red-200 "
              onClick={() => {
                setShowSnblPrepaidBalance(false);
              }}
            >
              <span className="relative flex px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
                Fermer
              </span>
            </button>
          </div>
        </div>
      </Modal>
      <ReadeQrcode
        onQrcodeResult={(result: any) => onQrcodeResult(result)}
        onQrcodeError={(error: any) => onQrcodeError(error)}
        showQrcodeReadeModal={showCardQrcodeReadeModal}
        setShowQrcodeReadeModal={setShowCardQrcodeReadeModal}
      />
    </>
  );
}

export default Dashboard;
