// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import "firebase/compat/app";
import "firebase/compat/storage";
import 'firebase/compat/functions';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBIhDksPHn4dL778oZd2p0f2xccRzdTMrE",
  authDomain: "sank-demo.firebaseapp.com",
  projectId: "sank-demo",
  storageBucket: "sank-demo.appspot.com",
  messagingSenderId: "383679918656",
  appId: "1:383679918656:web:949dc5ea15e201dd82a5a5"
};
// const firebaseConfig = {
//   apiKey: "AIzaSyC_jTTs5h7gW4J-jRb5h0ZPGM8fKi7SGbo",
//   authDomain: "sank-dev-d0438.firebaseapp.com",
//   projectId: "sank-dev-d0438",
//   storageBucket: "sank-dev-d0438.appspot.com",
//   messagingSenderId: "1081614760313",
//   appId: "1:1081614760313:web:2b4553e21a02d3707b17a8",
// };


// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics(app);
const auth = firebase.auth(app);
auth.languageCode = "fr";
auth.useDeviceLanguage();
const functions = app.functions('europe-west1');
const db = firebase.firestore(app);
const storage = firebase.storage(app)
// window.recaptchaVerifier = new RecaptchaVerifier(
//   document.getElementsByClassName("firebaseui-recaptcha-container"),
//   {
//     size: "invisible",
//     callback: (response) => {
//       // reCAPTCHA solved, allow signInWithPhoneNumber.
//       //onSignInSubmit();
//     },
//   },
//   app.auth()
// );


export { auth, db, analytics, functions, storage };
