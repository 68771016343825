/* eslint-disable jsx-a11y/alt-text */
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { functions, db } from "../../firebase";
import { UserContext } from "../../contexts";
import ReadeQrcode from "../../components/Qrcode/ReadeQrcode";
import Modal from "../../components/Modal";
import { ArrowSmallDownIcon, QrCodeIcon } from "@heroicons/react/24/outline";
import { classNames, isNumeric } from "../../utils";
import {
  accountsCollectionName,
  defaultAmount,
  userCardRelationCollection,
  usersCollectionName,
} from "../../config";
import CodePinComponent from "../../components/CodePinComponent";
import { useRouter } from "../../hooks/useRouter";

const WithdrawMoneyPage = () => {
  const router = useRouter();
  const [receiverData, setReceiverData] = useState<any>([]);
  const [qrcodeScannedData, setQrcodeScannedData] = useState<string[]>([]);
  const [isDataGet, setIsDataGet] = useState(false);
  const [amount, setAmount] = useState<string>(defaultAmount);
  const { user, setUserAccounts, agencyAgent } = useContext(UserContext);
  const [showQrcodeReadeModal, setShowQrcodeReadeModal] = useState(false);
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const collectionName = usersCollectionName;
  const [isCodePinBtnDesabled, setIsCodePinBtnDesabled] = useState(false);
  const [withCard, setWithCard] = useState<boolean>(false);
  const [description, setDescription] = useState("");
  const userRef = db.collection(collectionName);
  const senderAccountRef = userRef
    .doc(user?.id)
    .collection(accountsCollectionName);
  const sankMoneyWithdrawMoneyFn = functions.httpsCallable(
    "sankMoneyWithdrawMoneyFn"
  );

  const handleChangeAmountValue = (e: any) => {
    e.preventDefault();
    setAmount(`${e.target.value}` ?? defaultAmount);
  };

  const handleDescriptionChange = (e: any) => {
    e.preventDefault();
    const value = e.target.value;
    setDescription(value);
  };

  const getReceiverData = async () => {
    setShowQrcodeReadeModal(false);
    if (qrcodeScannedData.length > 0 && !isDataGet) {
      const response = await db
        .collection(usersCollectionName)
        .doc(qrcodeScannedData[0])
        .get();

      if (response.exists) {
        setIsDataGet(true);
        const data = response.data();
        setReceiverData([data]);
        return data;
      } else {
        const cardId = qrcodeScannedData[0];
        await db
          .collection(userCardRelationCollection)
          .where("cardId", "==", cardId)
          .get()
          .then(async (response) => {
            setIsDataGet(true);
            if (response.empty) {
              setReceiverData(undefined);
              return;
            } else {
              setWithCard(true);
              await db
                .collection(usersCollectionName)
                .doc(response.docs[0].data()?.userId)
                .get()
                .then((res) => {
                  setIsDataGet(true);
                  const data = res.data();
                  setReceiverData([data]);
                  return data;
                });
            }
          });
      }
    }
  };

  const onSubmit = async (pinCode: string) => {
    setIsCodePinBtnDesabled(true);
    if (!isNumeric(amount)) {
      setShowPinCodeModal(false);
      return toast.error(`Le montant n'est pas bien formatté`);
    }
    if (amount < defaultAmount)
      toast.error(`Le montant doit être suppérieur à ${defaultAmount} Fcfa`);
    if (user && receiverData.length > 0) {
      if (user?.id === receiverData[0]?.id) {
        toast.error("Vous ne pouvez pas effectuer cet type de transaction");
        setIsCodePinBtnDesabled(false);
      } else {
        setIsLoad(true);
        await sankMoneyWithdrawMoneyFn({
          pinCode: pinCode,
          amount: amount,
          senderPhoneNumber:
            receiverData.length > 0 && receiverData[0]?.phoneNumber,
          agentCode: agencyAgent ? agencyAgent.agentCode : user?.agentCode,
          isCardPayment: withCard ? true : false,
          cardId: qrcodeScannedData[0] ?? "",
          description: description ?? "",
          withConnection: true,
          appPlatform: "web",
        })
          .then(async () => {
            setWithCard(false);
            setShowPinCodeModal(false);
            setIsLoad(false);
            router.push("/dashboard");
            const accounts = await senderAccountRef.get();
            setUserAccounts(accounts.docs.map((account) => account.data()));
            return toast.success("Le retrait a été effectuer avec succès");
          })
          .catch((err: { details: any; message: any }) => {
            setShowPinCodeModal(false);
            setIsCodePinBtnDesabled(false);
            setIsLoad(false);
            setWithCard(false);
            toast.error(err.message);
          });
      }
    }
  };

  const onQrcodeResult = (result: any) => {
    setShowQrcodeReadeModal(false);
    if (result && !isDataGet) {
      setQrcodeScannedData(result);
      setShowQrcodeReadeModal(false);
      getReceiverData();
    }
  };
  const onQrcodeError = (error: any) => {
    setShowQrcodeReadeModal(false);
  };
  return (
    <div className="flex justify-center w-full">
      <div className="w-full max-w-md px-2 py-16 sm:px-0">
        <div className="flex items-center justify-center w-full font-manrope">
          <div className="box-border w-full p-4 mx-auto bg-white border">
            <div className="flex justify-end text-red-500">
              <QrCodeIcon
                className="w-6 h-6 cursor-pointer text-red-red-500"
                aria-hidden="true"
                onClick={() => {
                  setShowQrcodeReadeModal(true);
                }}
              />
            </div>
            <div className="mt-6">
              <div className="font-semibold">
                Combien souhaitez-vous retirer ?
              </div>
              <div>
                <input
                  className="mt-1 w-full rounded-[4px] border border-[#A0ABBB] p-2"
                  type="number"
                  placeholder="Ex: 1000"
                  pattern="[0-9]*"
                  min={0}
                  onChange={handleChangeAmountValue}
                />
              </div>
            </div>
            <div className="mt-6">
              <div className="font-semibold">
                Entrez une descriptions de la transaction
              </div>
              <div>
                <textarea
                  id="message"
                  rows={4}
                  onChange={handleDescriptionChange}
                  maxLength={200}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Écrivez votre description ici..."
                ></textarea>
              </div>
            </div>
            <div className="mt-6">
              <div className="flex items-center gap-x-[10px] bg-neutral-100 p-3 mt-2 rounded-[4px]">
                <img
                  className="w-10 h-10 rounded-full"
                  src={
                    user?.profilImageUrl
                      ? user?.profilImageUrl
                      : `https://ui-avatars.com/api/?background=random&name=${
                          user?.displayName || "S"
                        }`
                  }
                />
                <div>
                  <div className="font-semibold">{user?.displayName}</div>
                </div>
              </div>
            </div>
            <div className="mt-6">
              {receiverData.length > 0 && (
                <>
                  <div className="flex justify-center mt-2">
                    <ArrowSmallDownIcon className="h-6 text-red-500" />
                  </div>
                  <div className="flex items-center gap-x-[10px] bg-neutral-100 p-3 mt-2 rounded-[4px]">
                    <img
                      className="w-10 h-10 rounded-full"
                      src={
                        receiverData[0]?.profilImageUrl
                          ? receiverData[0]?.profilImageUrl
                          : `https://ui-avatars.com/api/?background=random&name=${
                              receiverData[0]?.displayName ?? "S"
                            }`
                      }
                    />
                    <div>
                      <div className="font-semibold">
                        {receiverData[0]?.displayName ?? "Utilisateur SANK"}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="mt-6">
              <div
                className={classNames(
                  amount ? "bg-red-600  cursor-pointer" : "bg-gray-600",
                  "w-full rounded-[4px]  px-3 py-[6px] text-center font-semibold text-white"
                )}
                onClick={() => amount && setShowPinCodeModal(true)}
              >
                Retirer:{" "}
                {new Intl.NumberFormat().format(parseInt(amount ?? "0"))} Fcfa
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReadeQrcode
        onQrcodeResult={(result: any) => onQrcodeResult(result)}
        onQrcodeError={(error: any) => onQrcodeError(error)}
        showQrcodeReadeModal={showQrcodeReadeModal}
        setShowQrcodeReadeModal={setShowQrcodeReadeModal}
      />
      <Modal
        isOpen={showPinCodeModal}
        maxSize="md"
        closeModal={() => setShowPinCodeModal(false)}
      >
        <div className="flex justify-center">
          <CodePinComponent
            onSubmit={onSubmit}
            disabled={isCodePinBtnDesabled}
            isLoad={isLoad}
          />
        </div>
      </Modal>
    </div>
  );
};
export default WithdrawMoneyPage;
