import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../contexts";
import { transactionCollectionName } from "../../config";
import { db } from "../../firebase";
import { BoltIcon, CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { Timestamp } from "firebase-admin/firestore";
import Pagination from "../../components/Pagination/Pagination";
import NoContent from "../../components/TableNoContentPage/NoContent";
import { classNames, getTransactionLabel } from "../../utils";
import SankMoneyIcon from "../../CFA.png";

const tableHeader = [
  "N°",
  "Nom destinataire",
  "Numéro destinataire",
  "Montant",
  "Type de transaction",
  "Compte",
  "Frais",
  "Status",
  "Date de création",
];

const MerchantTransactions = () => {
  const location = useLocation();
  const currentMerchant = location.state?.entity;
  const [histories, setHistories] = useState<any[]>([]);
  const [defaultLoadHistories, setDefaultLoadHistories] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { user } = useContext(UserContext);

  const getAllHistories = useCallback(
    async (pageLimit: number) => {
      await db
        .collection(transactionCollectionName)
        .orderBy("createdAt", "desc")
        .where("participantsIds", "array-contains", currentMerchant?.id)
        .limit(pageLimit)
        .get()
        .then((response) => {
          setDefaultLoadHistories(response?.size);
          setHistories(
            response.docs.map((d) => {
              return { id: d.id, data: d.data() };
            })
          );
          setPage(1);
        });
    },
    [currentMerchant?.id]
  );

  useEffect(() => {
    currentMerchant && getAllHistories(10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Histroiques des transactions du marchand: {" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r to-red-600 from-red-900">
              {currentMerchant?.displayName}
            </span>
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Les transactions du marchand
          </p>
        </div>
      </div>
      <div className="lg:border-t lg:border-gray-200">
        <div className="mt-3 main-form">
          <div className="flex flex-col mt-8">
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                  <table
                    className="min-w-full border-separate"
                    style={{ borderSpacing: 0 }}
                  >
                    <thead className="bg-gray-50">
                      <tr>
                        {tableHeader.map((th, index) => (
                          <th
                            key={index}
                            scope="col"
                            className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                          >
                            {th}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {histories && histories.length > 0 ? (
                        histories.map((history: any, historyIdx: number) => (
                          <tr key={historyIdx}>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {(historyIdx as number) + 1}
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {user?.id === history.data.senderID
                                ? history.data.receiverDisplayName
                                  ? history.data.receiverDisplayName
                                  : "-"
                                : history.data.senderDisplayName
                                ? history.data.senderDisplayName
                                : "-"}
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {user?.id === history.data.senderID
                                ? (history.data.receiverPhoneNumber as string)
                                : (history.data.senderPhoneNumber as string)}
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {new Intl.NumberFormat().format(
                                history.data.amount
                              )}{" "}
                              Fcfa
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {user?.id === history.data.senderID
                                ? getTransactionLabel(
                                    user?.id ?? "",
                                    history.data.transactionType,
                                    history.data.senderID
                                  )
                                : getTransactionLabel(
                                    user?.id ?? "",
                                    history.data.transactionType,
                                    history.data.receiverID
                                  )}
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {history.data.accountType === "MAIN" ? (
                                <img
                                  className="w-auto h-5"
                                  src={SankMoneyIcon}
                                  alt="Sank"
                                />
                              ) : (
                                // <CurrencyDollarIcon className="w-6 h-6 text-red-500" />
                                <BoltIcon className="w-6 h-6 text-red-500" />
                              )}
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {history.data.fees as string} Fcfa
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {history.data.isComplete ? (
                                <CheckCircleIcon className="w-6 h-6 text-green-500" />
                              ) : (
                                <XCircleIcon className="w-6 h-6 text-red-500" />
                              )}
                            </td>
                            <td
                              className={classNames(
                                historyIdx !== histories.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {(history.data.createdAt as Timestamp)
                                .toDate()
                                .toLocaleString()}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NoContent />
                      )}
                    </tbody>
                  </table>
                  {histories && histories.length > 0 && (
                    <Pagination
                      skip={defaultLoadHistories}
                      take={defaultLoadHistories}
                      total={defaultLoadHistories}
                      collectionName={transactionCollectionName}
                      isChangedPage={false}
                      onPageChange={(pageIndex: number) => pageIndex * page}
                      onPageLimitChange={(pageLimit: number) =>
                        getAllHistories(pageLimit)
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantTransactions;
