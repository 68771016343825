import { useCallback, useContext, useEffect, useState } from "react";
import {
  MagnifyingGlassIcon,
  PlusIcon,
  PencilIcon,
  ArrowPathIcon,
  TrashIcon,
  BanknotesIcon,
  DocumentIcon,
  ListBulletIcon,
  Squares2X2Icon,
  CheckCircleIcon,
  XCircleIcon,
  ChatBubbleLeftIcon,
  DevicePhoneMobileIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import {
  companyCollectionName,
  companyContactCollectionsName,
} from "../../../config";
import { UserContext } from "../../../contexts";
import { db, functions } from "../../../firebase";
import { classNames } from "../../../utils";
import NoContent from "../../../components/TableNoContentPage/NoContent";
import toast from "react-hot-toast";
import { useConfirm } from "../../../hooks/useConfirm";
import Pagination from "../../../components/Pagination/Pagination";
import { PaymentFormType } from "../Paiement";
import FirestoreTable, {
  IColumn,
  IRow,
  RowRenderFunction,
} from "../../../components/FirestoreTable";
import Moment from "react-moment";

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      if (!value) return "--";
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "displayName":
      return <span className="select-all">{value ?? "-"}</span>;
    case "phoneNumber":
      return <span className="select-all">{value ?? "-"}</span>;
    default:
      return value as string;
  }
};

const columns: IColumn[] = [
  {
    field: "createdAt",
    label: `Date de création`,
  },
  {
    field: "displayName",
    label: "Nom/Prénom(s)",
  },
  {
    field: "phoneNumber",
    label: "Téléphone",
  },
  {
    field: "email",
    label: "Adresse mail",
  },
];

const CompanyContactsList = () => {
  const { company } = useContext(UserContext);
  const [searchedValue, setSearchedValue] = useState("");
  const [companyContactsList, setCompanyContactsList] = useState<any[]>([]);
  const navigate = useNavigate();
  const { isConfirmModal } = useConfirm();
  const [selectedContacts, setSelectedContacts] = useState<any[]>([]);
  const [ContactArrayLengthLimit, setContactArrayLengthLimit] = useState(25);
  const [isTableView, setIsTableView] = useState(false);
  const getAllcontactsRef = db
    .collection(companyCollectionName)
    .doc(company?.id)
    .collection(companyContactCollectionsName);

  const sankMoneyDeleteCompanyContactFn = functions.httpsCallable(
    "sankMoneyDeleteCompanyContactFn"
  );

  const onPageLimitChange = useCallback(
    async (ContactArrayLengthLimit: number) => {
      const contactsSnap = await db
        .collection(companyCollectionName)
        .doc(company?.id)
        .collection(companyContactCollectionsName)
        .limit(ContactArrayLengthLimit)
        .get();

      if (contactsSnap.empty) {
        return setCompanyContactsList([]);
      }
      setCompanyContactsList(contactsSnap.docs.map((doc) => doc.data()));
    },
    [company?.id]
  );

  useEffect(() => {
    onPageLimitChange(ContactArrayLengthLimit);
  }, [ContactArrayLengthLimit, onPageLimitChange]);

  const onEditContact = (contact: any) => {
    if (contact) {
      navigate(`/companies/contacts/${contact.id}/edit-conatct`, {
        state: { entity: contact },
      });
    }
  };

  const handleChange = (checked: boolean, index: number, data: any) => {
    if (checked) {
      setSelectedContacts((prevContact) => [
        ...prevContact,
        { ...data, index },
      ]);
    } else {
      setSelectedContacts((prevContact) =>
        prevContact.filter((contact) => contact.index !== index)
      );
    }
  };

  const debouncedSearchResults = useCallback(
    async (val: string) => {
      setSearchedValue(val);
      const userSnap = await getAllcontactsRef.get();
      if (!userSnap.empty) {
        const data = userSnap.docs.map((doc) => doc.data());
        const filteredContacts = data.filter((value) =>
          (value.phoneNumber as string).includes(val)
        );
        setCompanyContactsList(
          filteredContacts.slice(0, ContactArrayLengthLimit)
        );
      }
    },
    [ContactArrayLengthLimit, getAllcontactsRef]
  );

  const removeContact = async (contactIds: string[]) => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes-vous sûr de vouloir retirer le contact",
      "Oui retirer",
      true
    );

    if (isConfirm) {
      const response = sankMoneyDeleteCompanyContactFn({
        companyId: company?.id,
        contactIds: contactIds,
      }).then(() =>
        getAllcontactsRef
          .orderBy("createdAt", "desc")
          .limit(10)
          .get()
          .then((response) => {
            setCompanyContactsList(response.docs.map((doc) => doc.data()));
          })
      );
      toast.promise(response, {
        error: "Une erreur s'est produite. Veuillez réessayer !",
        success: "La suppression a été effectué avec succés",
        loading: "Mise à jour...",
      });
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="py-2 border-b border-gray-200 dark:border-slate-700">
          <h3 className="text-lg font-semibold leading-6 text-gray-900 dark:text-gray-300">
            La liste de mes contacts
          </h3>
          <p className="mt-1 text-base text-gray-500">
            {
              "Gérez vos contacts depuis cette page. Vous pouvez sélectionner un contact pour lui envoyer directement une recharge."
            }
          </p>
        </div>
        <div
          className={classNames(
            !isTableView
              ? "grid grid-cols-1 lg:grid-cols-3"
              : "flex grid-cols-1 lg:grid-cols-1 ",
            "items-start justify-between my-3 gap-x-4 "
          )}
        >
          {!isTableView && (
            <div className="flex self-stretch flex-1 w-full h-10 col-span-1 border border-b border-red-200 rounded-lg lg:w-96 lg:col-span-1 gap-x-4 lg:gap-x-4 bg-gray-50 dark:bg-slate-800 dark:border-slate-700">
              <form className="relative flex flex-1" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <MagnifyingGlassIcon
                  className="absolute inset-y-0 w-5 h-full text-gray-400 pointer-events-none left-2"
                  aria-hidden="true"
                />
                <input
                  id="search-field"
                  className="flex w-full h-full py-0 pl-10 pr-2 text-gray-900 border-0 rounded-lg placeholder:text-gray-400 bg-red-50 dark:bg-slate-800 focus:ring-0 sm:text-base"
                  type="search"
                  name="search"
                  value={searchedValue}
                  onChange={(e) => debouncedSearchResults(e.target.value)}
                  placeholder={`7XXXXXXX`}
                />
              </form>
            </div>
          )}

          <div className="flex items-center justify-end col-span-2 my-2 space-x-2 max-md:flex-col max-md:space-y-4 lg:my-0">
            <span className="inline-flex rounded-md shadow-sm isolate">
              <button
                type="button"
                onClick={() => setIsTableView(true)}
                className={classNames(
                  isTableView
                    ? "bg-red-100 dark:bg-red-900 border-red-500 border"
                    : "bg-white dark:bg-slate-800",
                  "relative inline-flex items-center rounded-l-md  px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-400 ring-1 ring-inset ring-gray-300 dark:ring-slate-700 hover:bg-gray-50 focus:z-10"
                )}
              >
                <ListBulletIcon className="w-5 h-5" />
              </button>
              <button
                type="button"
                onClick={() => setIsTableView(false)}
                className={classNames(
                  !isTableView
                    ? "bg-red-100 dark:bg-red-900 border-red-500 border"
                    : "bg-white dark:bg-slate-800",
                  "relative -ml-px inline-flex items-center rounded-r-md  px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-400 ring-1 ring-inset ring-gray-300 dark:ring-slate-700 hover:bg-gray-50 focus:z-10"
                )}
              >
                <Squares2X2Icon className="w-5 h-5" />
              </button>
            </span>
            <button className="inline-flex items-center justify-center px-4 py-2 text-sm font-semibold text-blue-700 transition-all duration-200 bg-white border border-blue-700 rounded-md shadow-sm gap-x-2 dark:bg-slate-800 hover:border-blue-900 hover:text-blue-900 dark:hover:text-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
              <Link
                to={`/companies/contacts/add-conatct`}
                className="flex gap-1"
              >
                <PlusIcon className="h-5" /> Ajouter un contact
              </Link>
            </button>
            <button
              onClick={() => {
                navigate("/companies/bulk-paiement", {
                  state: { contacts: selectedContacts },
                });
              }}
              disabled={selectedContacts.length > 0 ? false : true}
              className={classNames(
                selectedContacts.length > 0
                  ? "text-green-700 border-green-700 hover:border-green-900 hover:text-green-900 dark:hover:text-green-600 focus-visible:outline-green-600"
                  : "text-gray-500 border-gray-500 hover:border-gray-600 hover:text-gray-600 dark:hover:text-gray-500 focus-visible:outline-gray-500 cursor-not-allowed",
                "inline-flex items-center cursor-pointer justify-center px-4 py-2 text-sm font-semibold transition-all duration-200 bg-white border rounded-md shadow-sm gap-x-2 dark:bg-slate-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              )}
            >
              <BanknotesIcon className="h-5" /> Paiement
            </button>
            <button
              onClick={() => {
                navigate("/companies/bulk-paiement", {
                  state: {
                    paymentFormType: PaymentFormType.BYCSVPAYMENT,
                  },
                });
              }}
              className={classNames(
                "inline-flex text-green-700 border-green-700 hover:border-green-900 hover:text-green-900 dark:hover:text-green-600 focus-visible:outline-green-600 items-center cursor-pointer justify-center px-4 py-2 text-sm font-semibold transition-all duration-200 bg-white border rounded-md shadow-sm gap-x-2 dark:bg-slate-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              )}
            >
              <DocumentIcon className="h-5" /> Uploader un fichier
            </button>
            <button
              onClick={() =>
                removeContact(selectedContacts.map((val) => val.id))
              }
              disabled={selectedContacts.length > 1 ? false : true}
              className={classNames(
                selectedContacts.length > 1
                  ? "text-red-700 border-red-700 hover:border-red-900 hover:text-red-900 dark:hover:text-red-600 focus-visible:outline-red-600"
                  : "text-gray-500 border-gray-500 hover:border-gray-600 hover:text-gray-600 dark:hover:text-gray-500 focus-visible:outline-gray-500 cursor-not-allowed",
                "inline-flex items-center cursor-pointer justify-center px-4 py-2 text-sm font-semibold transition-all duration-200 bg-white border rounded-md shadow-sm gap-x-2 dark:bg-slate-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              )}
            >
              <TrashIcon className="h-5" /> Supprimer
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col max-w-5xl 2xl:max-w-7xl">
        <div className="flex flex-col">
          {!isTableView && (
            <>
              {companyContactsList && companyContactsList.length > 0 ? (
                <>
                  <ul className="grid grid-cols-1 gap-6 my-4 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                    {companyContactsList.map((contact, contactIdx) => {
                      return (
                        <li
                          key={contactIdx}
                          className="col-span-1 bg-white border divide-y divide-gray-200 rounded-lg shadow-md dark:divide-slate-700 dark:bg-slate-800 dark:border-slate-700"
                        >
                          <div className="flex items-center justify-between w-full px-6 py-4 space-x-6">
                            <div className="flex-1 truncate">
                              <div className="flex items-center space-x-3">
                                <h3 className="text-sm font-medium text-gray-900 truncate dark:text-gray-300">
                                  {contact.displayName}
                                </h3>
                                <span
                                  title="Ce contact a été crée automatiquement par le système"
                                  className="inline-flex flex-shrink-0 items-center px-1.5 py-1 text-xs font-medium"
                                >
                                  <input
                                    id="default-checkbox"
                                    type="checkbox"
                                    checked={selectedContacts.some(
                                      (selectItem) =>
                                        selectItem.index === contactIdx
                                    )}
                                    onChange={(value) =>
                                      handleChange(
                                        value.target.checked,
                                        contactIdx,
                                        contact
                                      )
                                    }
                                    className="w-4 h-4 text-red-600 bg-gray-100 border-gray-300 rounded cursor-pointer focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                </span>
                              </div>
                              {contact.phoneNumber && (
                                <p className="mt-1 text-sm text-gray-500 truncate">
                                  {`${contact.phoneNumber}`}
                                </p>
                              )}
                              {contact.email && (
                                <p className="text-xs text-gray-500 truncate">
                                  {`${contact.email}`}
                                </p>
                              )}
                            </div>
                            <span className="inline-block w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-slate-700">
                              <svg
                                className="w-full h-full text-gray-300"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                              </svg>
                            </span>
                          </div>
                          <div>
                            <div className="flex -mt-px divide-x divide-gray-200 dark:divide-slate-700">
                              <div className="flex flex-1 w-0">
                                <div
                                  onClick={() => {
                                    navigate("/companies/bulk-paiement", {
                                      state: {
                                        contacts: [contact],
                                        paymentFormType:
                                          PaymentFormType.BYFORMPAYMENT,
                                      },
                                    });
                                  }}
                                  className="relative inline-flex items-center justify-center flex-1 w-0 py-2 -mr-px text-sm font-semibold text-gray-900 border border-transparent rounded-bl-lg cursor-pointer gap-x-2 dark:text-gray-300"
                                >
                                  <ArrowPathIcon
                                    className="w-5 h-5 text-red-400"
                                    aria-hidden="true"
                                  />
                                  Transferer
                                </div>
                              </div>
                              <div className="flex flex-1 w-0 -ml-px">
                                <span
                                  onClick={() => onEditContact(contact)}
                                  className="relative inline-flex items-center justify-center flex-1 w-0 py-2 text-sm font-semibold text-gray-900 border border-transparent rounded-br-lg cursor-pointer gap-x-2 dark:text-gray-300"
                                >
                                  <PencilIcon
                                    className="w-5 h-5 text-red-400"
                                    aria-hidden="true"
                                  />
                                  Edit
                                </span>
                              </div>
                              <div className="flex flex-1 w-0 -ml-px">
                                <span
                                  onClick={() => removeContact([contact.id])}
                                  className="relative inline-flex items-center justify-center flex-1 w-0 py-2 text-sm font-semibold text-gray-900 border border-transparent rounded-br-lg cursor-pointer gap-x-2 dark:text-gray-300"
                                >
                                  <TrashIcon
                                    className="w-5 h-5 text-red-400"
                                    aria-hidden="true"
                                  />
                                  Supprimer
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  {companyContactsList && companyContactsList.length > 0 && (
                    <Pagination
                      skip={companyContactsList.length}
                      take={companyContactsList.length}
                      total={0}
                      collectionName={companyContactCollectionsName}
                      isChangedPage={false}
                      onPageChange={(pageIndex: number) => pageIndex}
                      onPageLimitChange={(pageLimit: number) => {
                        onPageLimitChange(pageLimit);
                        setContactArrayLengthLimit(pageLimit);
                      }}
                    />
                  )}
                </>
              ) : (
                <NoContent />
              )}
            </>
          )}{" "}
          {isTableView && (
            <FirestoreTable
              collectionRef={db
                .collection(companyCollectionName)
                .doc(company?.id)
                .collection(companyContactCollectionsName)}
              condensed
              // collectionQueriesWhere={[firestoreTableQueriesWhere]}
              columns={columns}
              rowRender={renderRow}
              checkBoxCustomColumn={{
                label: "",
                render(idx, row) {
                  return (
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      checked={selectedContacts.some(
                        (selectItem) => selectItem.index === idx
                      )}
                      onChange={(value) =>
                        handleChange(value.target.checked, idx, row)
                      }
                      className="w-4 h-4 text-red-600 bg-gray-100 border-gray-300 rounded cursor-pointer focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  );
                },
              }}
              customColumns={[
                {
                  label: "Actions",
                  render(row) {
                    return (
                      <div className="flex gap-2">
                        <div
                          onClick={() => {
                            navigate("/companies/bulk-paiement", {
                              state: {
                                contacts: [row],
                                paymentFormType: PaymentFormType.BYFORMPAYMENT,
                              },
                            });
                          }}
                          className="p-1 ml-1 text-red-600 bg-red-100 border border-transparent rounded-full cursor-pointer dark:bg-red-700 hover:bg-red-500 dark:hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-red-400 dark:text-red-200 hover:text-white"
                        >
                          <ArrowPathIcon
                            className="w-5 h-5 text-red-400 hover:text-white"
                            aria-hidden="true"
                          />
                        </div>

                        <div className="flex items-center gap-1">
                          <span
                            onClick={() => onEditContact(row)}
                            className="p-1 ml-1 text-red-600 bg-red-100 border border-transparent rounded-full cursor-pointer dark:bg-red-700 hover:bg-red-500 dark:hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-red-400 dark:text-red-200 hover:text-white"
                          >
                            <PencilIcon
                              className="w-5 h-5 text-red-400 hover:text-white"
                              aria-hidden="true"
                            />
                          </span>
                          <span
                            onClick={() => removeContact([row.id])}
                            className="p-1 ml-1 text-red-600 bg-red-100 border border-transparent rounded-full cursor-pointer dark:bg-red-700 hover:bg-red-500 dark:hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-red-400 dark:text-red-200 hover:text-white"
                          >
                            <TrashIcon
                              className="w-5 h-5 text-red-400 hover:text-white"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                      </div>
                    );
                  },
                },
              ]}
              paginate={{ pageIndex: 0, limit: 100 }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CompanyContactsList;
