/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  CheckCircleIcon,
  UserIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import Moment from "react-moment";
import FirestoreTable, {
  IColumn,
  RowRenderFunction,
  IRow,
} from "../../../components/FirestoreTable";
import { usersCollectionName } from "../../../config";
import { db, functions } from "../../../firebase";
import useCrudDocument from "../../../hooks/useCrudDocument";
import { AgencyStaffRole, UserRole, mapsSubRole, mapsUserRole, userRoleMap } from "../../../interfaces";
import HasRoles from "../../../components/HasRoles";
import { useEffect, useMemo, useState } from "react";
import AgencyStaffEditPage from "./Staff/staff";


const columns: IColumn[] = [
  {
    field: "createdAt",
    label: "Date de création",
  },
  {
    field: "profilImageUrl",
    label: "Profil",
  },
  {
    field: "displayName",
    label: "Nom/prénom",
  },
  {
    field: "email",
    label: "Email",
  },
  {
    field: "phoneNumber",
    label: "Téléphone",
  },
  {
    field: "city",
    label: "Ville",
  },
  {
    field: "active",
    label: "Active",
  },
  {
    field: "userAgencySubRole",
    label: "Role",
  }
  
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "displayName":
      return <span>{value ? value : "-"}</span>;
    case "profilImageUrl":
      return (
        <span>
          {" "}
          {value ? (
            <img
              className="w-10 h-10 p-1 border rounded-full"
              src={
                value
                  ? value
                  : `https://ui-avatars.com/api/?background=random&name=${
                      value || "S"
                    }`
              }
              alt=""
            />
          ) : (
            <UserIcon className="w-10 h-10 p-1 border rounded-full" />
          )}
        </span>
      );
    case "email":
      return <span>{value ? value : "-"}</span>;
    case "city":
      return <span>{value ? value : "-"}</span>;
    case "phoneNumber":
      return <span>{value ? value : "-"}</span>;
    case "userAgencySubRole":
    case "userRole":
      return <span>{userRoleMap.get(value)}</span>;
    case "active":
      return (
        <span>
          {" "}
          {value ? (
            <CheckCircleIcon className="w-6 h-6 text-green-500" />
          ) : (
            <XCircleIcon className="w-6 h-6 text-red-500" />
          )}
        </span>
      );
    default:
      return value as string;
  }
};
const AllSankStaffPage = () => {
  const [selectedVal, setSelectedVal] = useState<UserRole>(
    UserRole.AGENCYSTAFF
  );
  let refreshDataFn: (() => void) | undefined = undefined;
  const navigate = useNavigate();
  const { deleteDocument } = useCrudDocument();

  const adminResetPasswordFn = functions.httpsCallable("adminResetPasswordFn");

  const onEditRow = (id: number, entity: any) => {
    if (entity) {
      navigate(`/sank_staff/${entity.id}/edit`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };
  const onReaffected = (id: number, entity: any) => {
    if (entity) {
      navigate(`/sank_staff/${entity.id}/edit`, {
        state: { entity: entity, id: entity.id, isReaffected: true },
      });
    }
  };
  const onRowDetails = (id: number, entity: any) => {
    if (entity) {
      navigate(`/sank_staff/${entity.id}/details`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };

  const onResetPassword = (id: number, entity: any) => {
    if (entity) {
      const response = adminResetPasswordFn({
        email: entity.email,
        phoneNumber: entity.phoneNumber,
      });
      toast.promise(response, {
        error: (err) => `${err}`,
        success: "Le mot de passe a été reinitialiser avec succès.",
        loading: "chargement...",
      });
    }
  };
  const onDeleteRow = (row: any) => {
    if (row) {
      toast.promise(
        deleteDocument(row.id, usersCollectionName).then(() => {
          if (refreshDataFn) {
            refreshDataFn();
          }
        }),
        {
          loading: "Suppression...",
          success: "Le dealer a été supprimé avec succès.",
          error: "Échec ! Veuillez réessayer.",
        }
      );
    }
  };

  useEffect(()=>{
    let index = columns.findIndex((item) => item.label === "Role");
    
    selectedVal === UserRole.AGENCYSTAFF ?
      columns[index].field = "userAgencySubRole"
    : columns[index].field = "userRole";

   
  },[selectedVal])

  const firestoreTableQueriesWhere = useMemo(() => {
    return {
      fieldPath: "userRole",
      opStr: "==",
      value: selectedVal,
    };
  }, [selectedVal]);

  return (
    <div className="px-4 mt-10 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Le personnel de sank
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Vous trouverez ci-dessous tous le personnel de sank.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-purple-200 "
            onClick={() => {
              navigate("/sank_staff/new");
            }}
          >
            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
              Ajouter un collaborateur
            </span>
          </button>
        </div>
        <HasRoles userRole={["ADMIN"]}>
          <div className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-purple-200">
            <select
              id="countries"
              className="block w-48 px-5 py-2.5 text-sm text-gray-900 border-none rounded-lg bg-gray-50"
              onChange={(e) => setSelectedVal(e.target.value as any)}
            >
              {[UserRole.AGENCYSTAFF, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.TECHNICALSUPPORT].map(
                (value, index) => (
                  <option
                    key={index}
                    selected={value === UserRole.AGENCYSTAFF && true}
                    value={value}
                  >
                    {userRoleMap.get(value)}
                  </option>
                )
              )}
            </select>
          </div>
        </HasRoles>
      </div>
      <div className="flex flex-col mt-8">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="shadow-sm ring-1 ring-black ring-opacity-5">
              <FirestoreTable
                collectionRef={db.collection(usersCollectionName)}
                collectionQueriesWhere={[firestoreTableQueriesWhere]}
                condensed
                columns={columns}
                rowRender={renderRow}
                rowEditHandler={onEditRow}
                rowDeleteHandler={onDeleteRow}
                rowChangeAgencyHandler={onReaffected}
                rowDetailsHandler={onRowDetails}
                rowResetPasswordHandler={onResetPassword}
                displayDetails
                displayChangeAgencyBtn
                shouldAddEditColumn
                displayEditBtn
                displayResetPasswordBtn
                paginate={{ pageIndex: 0, limit: 100, sort: "createdAt:desc" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllSankStaffPage;
