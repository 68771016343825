import { ArrowSmallDownIcon, QrCodeIcon } from "@heroicons/react/24/outline";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import CodePinComponent from "../../../components/CodePinComponent";
import Modal from "../../../components/Modal";
import ReadeQrcode from "../../../components/Qrcode/ReadeQrcode";
import {
  usersCollectionName,
  accountsCollectionName,
  defaultAmount,
  agencyCollectionsName,
  feesAccountId,
  sankmoneyFeesAccount,
} from "../../../config";
import { UserContext } from "../../../contexts";
import { db, functions } from "../../../firebase";
import { useRouter } from "../../../hooks/useRouter";
import { AccountType, TransactionType, UserRole } from "../../../interfaces";
import { classNames, isNumeric } from "../../../utils";

const ReloadAgencyAccount = () => {
  const [receiverData, setReceiverData] = useState<any>([]);
  const [qrcodeScannedData, setQrcodeScannedData] = useState<string[]>([]);
  const [isDataGet, setIsDataGet] = useState(false);
  const [amount, setAmount] = useState<string>(defaultAmount);
  const { user, setUserAccounts } = useContext(UserContext);
  const [showQrcodeReadeModal, setShowQrcodeReadeModal] = useState(false);
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [retriveAgency, setRetriveAgency] = useState<any>(null);
  const router = useRouter();
  const [description, setDescription] = useState("");
  const [isCodePinBtnDesabled, setIsCodePinBtnDesabled] = useState(false);
  const [agencyIdentificationCode, setAgencyIdentificationCode] = useState("");
  const [isLoad, setIsLoad] = useState(false);

  const senderAccountRef = db
    .collection(sankmoneyFeesAccount)
    .doc(feesAccountId);
  const sankMoneyReloadAgencyAccountFn = functions.httpsCallable(
    "sankMoneyReloadAgencyAccountFn"
  );

  const handleChangeAmountValue = (e: any) => {
    e.preventDefault();
    setAmount(`${e.target.value}` ?? defaultAmount);
  };

  const handleDescriptionChange = (e: any) => {
    e.preventDefault();
    const value = e.target.value;
    setDescription(value);
  };

  const getReceiverData = async () => {
    setShowQrcodeReadeModal(false);
    if (qrcodeScannedData.length > 0 && !isDataGet) {
      await db
        .collection(usersCollectionName)
        .doc(qrcodeScannedData[0])
        .get()
        .then((doc) => {
          setIsDataGet(true);
          const data = doc.data();
          setReceiverData([data]);
          return data;
        });
    }
  };

  const onSubmit = async (pinCode: string) => {
    setIsCodePinBtnDesabled(true);
    if (!isNumeric(amount)) {
      setShowPinCodeModal(false);
      return toast.error(`Le montant n'est pas bien formatté`);
    }
    if (amount < defaultAmount) {
      setShowPinCodeModal(false);
      return toast.error(
        `Le montant doit être suppérieur à ${defaultAmount} Fcfa`
      );
    }
    if (user && (retriveAgency || receiverData.length > 0)) {
      if (user?.id === (retriveAgency || receiverData[0])?.id) {
        toast.error("Vous ne pouvez pas effectuer cet type de transaction");
        setIsCodePinBtnDesabled(false);
      } else {
        setIsLoad(true);
        await sankMoneyReloadAgencyAccountFn({
          pinCode: pinCode,
          amount: amount.replace(/\s/g, "").trim(),
          senderId: user?.id,
          receiverId:
            receiverData.length > 0
              ? receiverData[0]?.id
              : retriveAgency && retriveAgency?.agentId,
          description: description ?? "",
          withConnection: true,
        })
          .then(async () => {
            setShowPinCodeModal(false);
            setIsLoad(false);
            router.push("/dashboard");
            const accountSnapshot = await senderAccountRef.get();
            setUserAccounts([accountSnapshot.data()]);
            return toast.success("La recharge a été effectuer avec succès");
          })
          .catch((err: { details: any; message: any }) => {
            setShowPinCodeModal(false);
            setIsCodePinBtnDesabled(false);
            setIsLoad(false);
            toast.error(err.message);
          });
      }
    }
  };

  const onQrcodeResult = (result: any) => {
    setShowQrcodeReadeModal(false);
    if (result) {
      setQrcodeScannedData(result);
      setShowQrcodeReadeModal(false);
      getReceiverData();
    }
  };
  const onQrcodeError = (error: any) => {
    setShowQrcodeReadeModal(false);
  };

  const handlechange = (value: string) => {
    if (value) {
      setAgencyIdentificationCode(value);
    }
  };

  const retriveAgencyData = async () => {
    if (agencyIdentificationCode) {
      const destUserSnapshot = await db
        .collection(agencyCollectionsName)
        .where("identificationCode", "==", agencyIdentificationCode)
        .get();
      const userRef = db.collection(usersCollectionName);
      if (destUserSnapshot.empty) {
        toast.error("L'agence est introuvable.");
        router.push("/transfer/reload_agency");
      } else {
        const agencyAgentData = await userRef
          .where("userRole", "==", UserRole.AGENT)
          .where("agencyId", "==", destUserSnapshot.docs[0].id)
          .get();
        if (!agencyAgentData.empty) {
          const userAccountSnapShot = await userRef
            .doc(agencyAgentData.docs[0]?.id)
            .collection(accountsCollectionName)
            .get();
          const destreceiverData = [];
          for (const destUserDoc of destUserSnapshot.docs) {
            const destUserdAllata = destUserDoc.data();
            const destUserAccountData = userAccountSnapShot.docs
              .map((accountDoc) => accountDoc.data())
              .find((data) => data.accountType === AccountType.MAIN);
            const destUserWithAccounts = {
              account: destUserAccountData,
              agentId: agencyAgentData.docs[0]?.id,
              ...destUserdAllata,
            };
            destreceiverData.push(destUserWithAccounts);
          }
          return setRetriveAgency(destreceiverData[0]);
        }
      }
    }
  };
  return (
    <div className="flex justify-center w-full">
      <div className="w-full max-w-md px-2 py-16 sm:px-0">
        <div className="flex items-center justify-center w-full font-manrope">
          <div className="box-border w-full p-4 mx-auto bg-white border">
            <div className="flex justify-end text-red-500">
              <QrCodeIcon
                className="w-6 h-6 cursor-pointer text-red-red-500"
                aria-hidden="true"
                onClick={() => {
                  setShowQrcodeReadeModal(true);
                }}
              />
            </div>
            {receiverData.length > 0 ? (
              <div></div>
            ) : (
              <div className="mt-6">
                <div className="font-semibold">
                  Recherche par le code d'identification
                </div>
                <div>
                  <div className="items-center justify-between flex-1 max-w-sm">
                    <label
                      htmlFor="search"
                      className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                    >
                      Rechercher
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          className="w-4 h-4 text-gray-500 dark:text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </div>
                      <input
                        type="search"
                        id="search"
                        className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                        placeholder="000001SANK2023"
                        onChange={(e) => handlechange(e.target.value)}
                      />
                      <button
                        type="submit"
                        className="text-white absolute right-2.5 bottom-2.5 bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        onClick={() => retriveAgencyData()}
                      >
                        Rechercher
                      </button>
                    </div>
                  </div>

                  {!retriveAgency ? (
                    receiverData.length > 0 ? (
                      <div></div>
                    ) : (
                      <p className="mt-1 text-xs text-red-500">Nom invalide</p>
                    )
                  ) : (
                    <p className="mt-1 text-xs text-green-500">Nom valide</p>
                  )}
                </div>
              </div>
            )}
            <div className="mt-6">
              <div className="font-semibold">
                Combien souhaitez-vous envoyer ?
              </div>
              <div>
                <input
                  className="mt-1 w-full rounded-[4px] border border-[#A0ABBB] p-2"
                  type="text"
                  placeholder="Ex: 1000"
                  pattern="[0-9]{9}"
                  min={0}
                  onChange={handleChangeAmountValue}
                />
              </div>
            </div>
            <div className="mt-6">
              <div className="font-semibold">
                Entrez une descriptions de la transaction
              </div>
              <div>
                <textarea
                  id="message"
                  rows={4}
                  onChange={handleDescriptionChange}
                  maxLength={200}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  placeholder="Écrivez votre description ici..."
                ></textarea>
              </div>
            </div>
            <div className="mt-6">
              <div className="flex items-center gap-x-[10px] bg-neutral-100 p-3 mt-2 rounded-[4px]">
                <img
                  className="w-10 h-10 rounded-full"
                  src={
                    user?.profilImageUrl
                      ? user?.profilImageUrl
                      : `https://ui-avatars.com/api/?background=random&name=${
                          user?.displayName || "S"
                        }`
                  }
                  alt=""
                />
                <div>
                  <div className="font-semibold">{user?.displayName}</div>
                </div>
              </div>
            </div>
            {(retriveAgency || receiverData.length > 0) && (
              <>
                <div className="flex justify-center mt-2">
                  <ArrowSmallDownIcon className="h-6 text-red-500" />
                </div>
                <div className="mt-2">
                  <div className="flex items-center gap-x-[10px] bg-neutral-100 p-3 mt-2 rounded-[4px]">
                    <img
                      className="w-10 h-10 rounded-full"
                      src={
                        (retriveAgency || receiverData[0])?.profilImageUrl
                          ? (retriveAgency || receiverData[0])?.profilImageUrl
                          : `https://ui-avatars.com/api/?background=random&name=${
                              (retriveAgency || receiverData[0])?.displayName ||
                              "S"
                            }`
                      }
                      alt=""
                    />
                    <div>
                      <div className="font-semibold">
                        {(retriveAgency || receiverData[0])?.displayName}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="mt-6">
              <div
                className={classNames(
                  amount ? "bg-red-600  cursor-pointer" : "bg-gray-600",
                  "w-full rounded-[4px]  px-3 py-[6px] text-center font-semibold text-white"
                )}
                onClick={() => amount && setShowPinCodeModal(true)}
              >
                Recharger:{" "}
                {new Intl.NumberFormat().format(parseInt(amount ?? "0"))} Fcfa
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReadeQrcode
        onQrcodeResult={(result: any) => onQrcodeResult(result)}
        onQrcodeError={(error: any) => onQrcodeError(error)}
        showQrcodeReadeModal={showQrcodeReadeModal}
        setShowQrcodeReadeModal={setShowQrcodeReadeModal}
      />
      <Modal
        isOpen={showPinCodeModal}
        maxSize="md"
        closeModal={() => setShowPinCodeModal(false)}
      >
        <div className="flex justify-center">
          <CodePinComponent
            onSubmit={onSubmit}
            disabled={isCodePinBtnDesabled}
            isLoad={isLoad}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ReloadAgencyAccount;
