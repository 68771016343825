import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { FormInput, Form } from "../../../components/FormComponents";
import Modal from "../../../components/Modal";
import ReadeQrcode from "../../../components/Qrcode/ReadeQrcode";
import { UserContext } from "../../../contexts";
import { db, functions } from "../../../firebase";
import { AccountType, UserRole } from "../../../interfaces";
import { classNames, isNumeric } from "../../../utils";
import * as yup from "yup";
import { QrCodeIcon } from "@heroicons/react/24/outline";
import {
  defaultAmount,
  usersCollectionName,
  accountsCollectionName,
  gasStationCollectionName,
} from "../../../config";
import "react-phone-number-input/style.css";
import { useRouter } from "../../../hooks/useRouter";

const GasStationPayment = () => {
  const [isCodePinBtnDesabled, setIsCodePinBtnDesabled] = useState(false);
  const { user, setUserAccounts } = useContext(UserContext);
  const [description, setDescription] = useState("");
  const [isDataGet, setIsDataGet] = useState(false);
  const [qrcodeScannedData, setQrcodeScannedData] = useState<string[]>([]);
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [userTypedNumber, setUserTypedNumber] = useState("");
  const [receiverDataGetWithPhoneNumber, setreceiverDataGetWithPhoneNumber] =
    useState<any>(null);
  const [receiverData, setreceiverData] = useState<any>([]);
  const [amount, setAmount] = useState<string>(defaultAmount);
  const router = useRouter();
  const [showQrcodeReadeModal, setShowQrcodeReadeModal] = useState(false);
  const sankMoneyGasStationPaymentProductsFn = functions.httpsCallable(
    "sankMoneyGasStationPaymentProductsFn"
  );
  const accountsRef = db
    .collection(usersCollectionName)
    .doc(user?.id)
    .collection(accountsCollectionName);

  const onSubmit = async (data: any) => {
    setIsCodePinBtnDesabled(true);
    if (user) {
      if (!isNumeric(amount)) {
        setIsCodePinBtnDesabled(false);
        setShowPinCodeModal(false);
        return toast.error(`Le montant n'est pas bien formatté`);
      }
      if (amount < defaultAmount) {
        setIsCodePinBtnDesabled(false);
        setShowPinCodeModal(false);
        return toast.error(
          `Le montant doit être suppérieur à ${defaultAmount} Fcfa`
        );
      }
      await sankMoneyGasStationPaymentProductsFn({
        pinCode: data.pinCode,
        amount: amount.replace(/\s/g, "").trim(),
        senderPhoneNumber: user?.phoneNumber,
        receiverPhoneNumber:
          receiverData.length > 0
            ? receiverData[0]?.gasStationCode
            : receiverDataGetWithPhoneNumber &&
              receiverDataGetWithPhoneNumber?.gasStationCode,
        orderId: qrcodeScannedData[2] ?? "",
        description: description ?? "",
        appPlatform: "web",
        withConnection: true,
      })
        .then(async (result) => {
          setShowPinCodeModal(false);
          const accounts = await accountsRef.get();
          setUserAccounts(accounts.docs.map((account) => account.data()));
          router.push("/dashboard");
          return toast.success(
            "Le compte de la station-service a été crédité avec succès"
          );
        })
        .catch((err: { details: any; message: any }) => {
          setShowPinCodeModal(false);
          toast.error(err.message);
        });
    }
  };

  const getFilterdByGasStationCode = async (gasStationCode: any) => {
    if (gasStationCode.length === 5) {
      setUserTypedNumber(gasStationCode);
      const destUserSnapshot = await db
        .collection(gasStationCollectionName)
        .where("gasStationCode", "==", gasStationCode)
        .where("userRole", "==", UserRole.GASSTATION)
        .get();

      if (destUserSnapshot.empty) {
        toast.error("Le code est erroné");
        router.push("/payment/gas-station");
        setIsCodePinBtnDesabled(false);
      } else {
        setIsCodePinBtnDesabled(false);
        toast.success(
          "Les informations de la station-service ont été avec succès"
        );
        const destreceiverData = [];
        for (const destUserDoc of destUserSnapshot.docs) {
          const destUserdAllata = destUserDoc.data();
          const destUserAccountSnapshot = await destUserDoc.ref
            .collection(accountsCollectionName)
            .get();
          const destUserAccountData = destUserAccountSnapshot.docs
            .map((accountDoc) => accountDoc.data())
            .find((data) => data.accountType === AccountType.FUEL);
          const destUserWithAccounts = {
            account: destUserAccountData,
            ...destUserdAllata,
          };
          destreceiverData.push(destUserWithAccounts);
        }
        return setreceiverDataGetWithPhoneNumber(destreceiverData[0]);
      }
    } else {
      setreceiverDataGetWithPhoneNumber(null);
    }
  };
  const getReceiverData = async () => {
    setShowQrcodeReadeModal(false);
    if (qrcodeScannedData.length > 0 && !isDataGet) {
      await db
        .collection(gasStationCollectionName)
        .doc(qrcodeScannedData[0])
        .get()
        .then((doc) => {
          setIsDataGet(true);
          const data = doc.data();
          setreceiverData([data]);
          return data;
        });
    }
  };

  const handleDescriptionChange = (e: any) => {
    e.preventDefault();
    const value = e.target.value;
    setDescription(value);
  };

  const handleChangeAmountValue = (e: any) => {
    e.preventDefault();
    setAmount(`${e.target.value}` ?? defaultAmount);
  };

  const onQrcodeResult = (result: any) => {
    setShowQrcodeReadeModal(false);
    if (result && !isDataGet) {
      setQrcodeScannedData(result);
      setShowQrcodeReadeModal(false);
      getReceiverData();
    }
  };
  const onQrcodeError = (error: any) => {
    setShowQrcodeReadeModal(false);
  };
  return (
    <div className="flex justify-center w-full">
      <div className="w-full max-w-md px-2 py-16 sm:px-0">
        <div className="flex items-center justify-center w-full font-manrope">
          <div className="box-border w-full p-4 mx-auto bg-white border">
            <div className="flex justify-end text-red-500">
              <QrCodeIcon
                className="w-6 h-6 cursor-pointer text-red-red-500"
                aria-hidden="true"
                onClick={() => {
                  setShowQrcodeReadeModal(true);
                }}
              />
            </div>
            {receiverData.length > 0 ? (
              <div></div>
            ) : (
              <div className="mt-6">
                <div className="font-semibold">
                  Entrez le code de la station-service
                </div>
                <div>
                  <input
                    type="text"
                    id="code"
                    maxLength={5}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Ex: 12345"
                    onChange={(e) => getFilterdByGasStationCode(e.target.value)}
                  />

                  {!receiverDataGetWithPhoneNumber ? (
                    receiverData.length > 0 ? (
                      <div></div>
                    ) : (
                      userTypedNumber.length !== 5 &&
                      userTypedNumber.length > 0 && (
                        <p className="mt-1 text-xs text-red-500">
                          Numéro invalide
                        </p>
                      )
                    )
                  ) : (
                    <p className="mt-1 text-xs text-green-500">Numéro valide</p>
                  )}
                </div>
              </div>
            )}
            <div className="mt-6">
              <div className="font-semibold">
                Combien souhaitez-vous payer ?
              </div>
              <div>
                <input
                  className="mt-1 w-full rounded-[4px] border border-[#A0ABBB] p-2"
                  type="number"
                  placeholder="Ex: 1000"
                  pattern="[0-9]{9}"
                  minLength={3}
                  onChange={handleChangeAmountValue}
                />
              </div>
            </div>
            <div className="mt-6">
              <div className="font-semibold">
                Entrez une description de la transaction
              </div>
              <div>
                <textarea
                  id="message"
                  rows={4}
                  onChange={handleDescriptionChange}
                  maxLength={200}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  placeholder="Écrivez votre description ici..."
                ></textarea>
              </div>
            </div>
            <div className="mt-6">
              <div className="flex items-center gap-x-[10px] bg-neutral-100 p-3 mt-2 rounded-[4px]">
                <img
                  className="w-10 h-10 rounded-full"
                  src={
                    user?.profilImageUrl
                      ? user?.profilImageUrl
                      : `https://ui-avatars.com/api/?background=random&name=${
                          user?.displayName || "S"
                        }`
                  }
                  alt=""
                />
                <div>
                  <div className="font-semibold">{user?.displayName}</div>
                </div>
              </div>
            </div>
            {(receiverDataGetWithPhoneNumber || receiverData.length > 0) && (
              <div className="mt-6">
                <div className="flex items-center gap-x-[10px] bg-neutral-100 p-3 mt-2 rounded-[4px]">
                  <img
                    className="w-10 h-10 rounded-full"
                    src={
                      (receiverDataGetWithPhoneNumber || receiverData[0])
                        ?.profilImageUrl
                        ? (receiverDataGetWithPhoneNumber || receiverData[0])
                            ?.profilImageUrl
                        : `https://ui-avatars.com/api/?background=random&name=${
                            (receiverDataGetWithPhoneNumber || receiverData[0])
                              ?.displayName || "S"
                          }`
                    }
                    alt=""
                  />
                  <div>
                    <div className="font-semibold">
                      {
                        (receiverDataGetWithPhoneNumber || receiverData[0])
                          ?.displayName
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="mt-6">
              <div
                className={classNames(
                  amount ? "bg-red-600  cursor-pointer" : "bg-gray-600",
                  "w-full rounded-[4px]  px-3 py-[6px] text-center font-semibold text-white"
                )}
                onClick={() => amount && setShowPinCodeModal(true)}
              >
                Retirer:{" "}
                {new Intl.NumberFormat().format(parseInt(amount ?? "0"))} Fcfa
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showPinCodeModal}
        maxSize="md"
        closeModal={() => {
          setShowPinCodeModal(false);
          setIsCodePinBtnDesabled(false);
        }}
      >
        <div className="flex justify-center">
          <Form<{ pinCode: string }>
            onSubmit={onSubmit}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  pinCode: yup
                    .string()
                    .required("Le champ est obligatoire")
                    .min(4, "Le champ doit contenir plus de 4 caractères"),
                })
              ),
            }}
            submitButtonFullWidth
            submitButtonLabel={`Valider la transaction`}
            isSubmitBtnDisabled={isCodePinBtnDesabled}
          >
            <div className="flex flex-col content-center w-full px-5 mt-5 align-top md:flex-row">
              <div className="w-full mx-auto ">
                <FormInput
                  type="password"
                  name="pinCode"
                  maxLength={4}
                  autoFocus={false}
                  label="Entrez votre code pin"
                  helpLabel="Veuillez entrer un code pin de quatre (04) chiffres"
                />
              </div>
            </div>
          </Form>
        </div>
      </Modal>
      <ReadeQrcode
        onQrcodeResult={(result: any) => onQrcodeResult(result)}
        onQrcodeError={(error: any) => onQrcodeError(error)}
        showQrcodeReadeModal={showQrcodeReadeModal}
        setShowQrcodeReadeModal={setShowQrcodeReadeModal}
      />
    </div>
  );
};

export default GasStationPayment;
