import { Menu, Transition } from "@headlessui/react";
import {
  FunnelIcon,
  ArrowDownTrayIcon,
  BoltIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { Timestamp } from "firebase-admin/firestore";
import { user } from "firebase-functions/v1/auth";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CSVLink } from "react-csv";
import HasRoles from "../../components/HasRoles";
import Pagination from "../../components/Pagination/Pagination";
import NoContent from "../../components/TableNoContentPage/NoContent";
import { TransactionType, transactionMap } from "../../interfaces";
import { classNames, getTimestamp } from "../../utils";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { transactionCollectionName } from "../../config";
import { UserContext } from "../../contexts";
import { db } from "../../firebase";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";
import SankMoneyIcon from "../../CFA.png";

const tableHeader = [
  "N°",
  "",
  "Nom destinataire",
  "Numéro destinataire",
  "Montant",
  "Type de transaction",
  "Compte",
  "Frais",
  "Status",
  "Date de création",
];

const SuperMerchantsAllTransactions = () => {
  const [transactions, setTransactions] = useState<any[]>([]);
  const collectionName = transactionCollectionName;
  const [paginatdlistData, setPaginatdListData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const { user, superMerchantAffiliatedMerchants } = useContext(UserContext);
  const [defaultLoadHistories, setDefaultLoadHistories] = useState<number>(10);
  const [participantId, setParticipantId] = useState(user?.id);
  const [transactionTotalSum, setTransactionTotalSum] = useState<number>(0);
  const [seachWithStartDateValue, setSetSeachWithStartDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
  const [seachWithEndDateValue, setSetSeachWithEndDateValue] = useState<string>(
    `${moment(Date.now()).format("YYYY-MM-DD")}`
  );
  const getAlltransactionRef = db.collection(transactionCollectionName);

  const handleChangeStartDateValue = (value: any) => {
    value.preventDefault();
    const date = new Date(value.target.value);

    function subtractMonths(date: Date, months: number) {
      date.setMonth(date.getMonth() - months);

      return date;
    }

    const limitDate = subtractMonths(new Date(), 12);

    if (date.getTime() < limitDate.getTime()) {
      return setSetSeachWithStartDateValue(
        moment(limitDate).format("YYYY-MM-DD")
      );
    }

    setSetSeachWithStartDateValue(moment(date).format("YYYY-MM-DD"));
  };
  const handleChangeEndDtaeValue = (value: any) => {
    value.preventDefault();
    const date = new Date(value.target.value);

    function subtractMonths(date: Date, months: number) {
      date.setMonth(date.getMonth() - months);
      return date;
    }

    const limitDate = subtractMonths(new Date(), 12);

    if (date.getTime() < limitDate.getTime()) {
      return setSetSeachWithEndDateValue(
        moment(limitDate).format("YYYY-MM-DD")
      );
    } else if (date.getTime() > new Date().getTime()) {
      return setSetSeachWithEndDateValue(
        moment(new Date()).format("YYYY-MM-DD")
      );
    }

    setSetSeachWithEndDateValue(moment(date).format("YYYY-MM-DD"));
  };
  const getFilteredByDate = async () => {
    // const startTime = new Date(seachWithStartDateValue).getTime();
    const startTime = getTimestamp(seachWithStartDateValue, true);
    const endTime = getTimestamp(seachWithEndDateValue, false);
    getAlltransactionRef
      .orderBy("createdAt", "desc")
      .where("participantsIds", "array-contains", participantId)
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<=", endTime)
      .limit(25)
      .get()
      .then((response) => {
        const histories = response.docs.map((d) => {
          return { id: d.id, data: d.data() };
        });
        setDefaultLoadHistories(histories.length);
        setTransactions(
          response.docs.map((d) => {
            return { id: d.id, data: d.data() };
          })
        );
        let sum = 0;
        const amount = response.docs.reduce(
          (accumulator, currentValue) =>
            accumulator + parseInt(currentValue.data().amount),
          sum
        );
        setTransactionTotalSum(amount);
      });
  };

  const getUserAllHistories = useCallback(async () => {
    await db
      .collection(transactionCollectionName)
      .orderBy("createdAt", "desc")
      .where("participantsIds", "array-contains", participantId)
      .limit(25)
      .get()
      .then((response) => {
        const histories = response.docs.map((d) => {
          return { id: d.id, data: d.data() };
        });
        setDefaultLoadHistories(histories.length);
        setTransactions(
          response.docs.map((d) => {
            return { id: d.id, data: d.data() };
          })
        );
        let sum = 0;
        const amount = response.docs.reduce(
          (accumulator, currentValue) =>
            accumulator + parseInt(currentValue.data().amount),
          sum
        );
        setTransactionTotalSum(amount);
      });
  }, [participantId]);

  const filterBySelect = async (participantId: string) => {
    await db
      .collection(transactionCollectionName)
      .orderBy("createdAt", "desc")
      .where("participantsIds", "array-contains", participantId)
      .limit(25)
      .get()
      .then((response) => {
        const histories = response.docs.map((d) => {
          return { id: d.id, data: d.data() };
        });
        setDefaultLoadHistories(histories.length);
        setTransactions(
          response.docs.map((d) => {
            return { id: d.id, data: d.data() };
          })
        );
        let sum = 0;
        const amount = response.docs.reduce(
          (accumulator, currentValue) =>
            accumulator + parseInt(currentValue.data().amount),
          sum
        );
        setTransactionTotalSum(amount);
      });
  };

  useEffect(() => {
    if (user) {
      getUserAllHistories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageLimitChange = async (pageLimit: number) => {
    setDefaultLoadHistories(pageLimit);
    db.collection(collectionName)
      .orderBy("createdAt", "desc")
      .where("participantsIds", "array-contains", participantId)
      .limit(pageLimit)
      .get()
      .then((response) => {
        const data = response.docs.map((d) => {
          return { id: d.id, data: d.data() };
        });
        setDefaultLoadHistories(data.length);
        setPaginatdListData(data);
        setPage(page + 1);
        let sum = 0;
        const amount = response.docs.reduce(
          (accumulator, currentValue) =>
            accumulator + parseInt(currentValue.data().amount),
          sum
        );
        setTransactionTotalSum(amount);
      });
  };

  const getExportCSVData = useMemo(() => {
    const header = [
      { label: "Expéditeur", key: "senderPhoneNumber" },
      { label: "Destinataire", key: "receiverPhoneNumber" },
      { label: "Montant", key: "amount" },
      { label: "Type de Transaction", key: "transactionType" },
      { label: "Frais", key: "fees" },
      { label: "Type de compte", key: "accountType" },
      { label: "Statut", key: "status" },
      { label: "Date", key: "createdAt" },
    ];

    const data = transactions.map((transaction, index) => {
      const createdAt = new Date(
        parseInt(transaction.data.createdAt.seconds) * 1000
      );

      return {
        ...transaction.data,
        createdAt: createdAt.toISOString(),
        status: transaction.data.status ?? "---",
      };
    });

    return { header, data };
  }, [transactions]);

  const exportToXLSData = useCallback(() => {
    const worksheet = XLSX.utils.json_to_sheet(
      transactions.map((transaction) => {
        return {
          Expéditeur: transaction.data.senderPhoneNumber,
          Destinataire: transaction.data.receiverPhoneNumber,
          Montant: transaction.data.amount,
          "Type de Transaction": transaction.data.transactionType,
          Frais: transaction.data.fees,
          "Type de compte": transaction.data.accountType,
          Statut: transaction.data.status ?? "---",
          Date: new Date(
            parseInt(transaction.data.createdAt.seconds) * 1000
          ).toISOString(),
        };
      })
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "export");

    XLSX.writeFile(workbook, "export.xlsx", { compression: true });
  }, [transactions]);

  const exportToPDFData = useCallback(() => {
    let data = (
      Object.keys(TransactionType) as Array<keyof typeof TransactionType>
    ).map((key) => {
      return {
        transactionType: key,
        data: transactions
          .filter((transaction) => transaction.data.transactionType === key)
          .map((transaction) => transaction.data),
      };
    });

    const finalData = data.map((element) => {
      let total_amount = 0;
      let total_seed = 0;
      let first_date = new Date();
      let last_date = new Date();

      element.data.forEach((el) => {
        total_amount += parseInt(el.amount);
        total_seed += parseInt(el.seed);

        const createdAt = new Date(parseInt(el.createdAt.seconds) * 1000);

        if (first_date.getTime() > createdAt.getTime()) {
          first_date = createdAt;
        }
      });

      return {
        "Type de transaction": element.transactionType.toString(),
        "Montant total": isNaN(total_amount) ? "0" : total_amount.toString(),
        "Frais total": isNaN(total_seed) ? "0" : total_seed.toString(),
        Début: first_date.toLocaleDateString(),
        Fin: last_date.toLocaleDateString(),
      };
    });

    var headers = [
      "Type de transaction",
      "Début",
      "Fin",
      "Montant total",
      "Frais total",
    ];

    var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "landscape" });

    doc.setFontSize(32);
    doc.text("Résumer de l'historique de transaction", 35, 25);

    doc.table(20, 40, finalData, headers, { autoSize: true });
    doc.save("export.pdf");
  }, [transactions]);

  return (
    <>
      <div className="px-4 mt-10 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="">
            <h1 className="py-2 text-xl font-semibold text-gray-900">
              Mes historiques de transactions{" "}
              <span className="hidden text-xs font-medium text-red-500 md:inline-block">
                (
                {`${new Intl.NumberFormat().format(transactionTotalSum)}
                            Fcfa`}
                )
              </span>
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Vous trouverez ici l'ensemble de vos historiques de transactions.
            </p>
          </div>
          <div className="h-8" />
          <div className="flex self-end justify-between max-md:flex-col">
            {superMerchantAffiliatedMerchants &&
              superMerchantAffiliatedMerchants.length > 0 && (
                <div className="mr-1 sm:mr-1 lg:mr-1">
                  <label
                    htmlFor="transType"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Marchand
                  </label>
                  <select
                    id="transType"
                    name="currency"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                    onChange={(e) => {
                      filterBySelect(e.target.value);
                    }}
                  >
                    {superMerchantAffiliatedMerchants &&
                      superMerchantAffiliatedMerchants.map((opt, index) => (
                        <option key={index} value={opt.id}>
                          {opt.displayName}
                        </option>
                      ))}
                  </select>
                </div>
              )}

            <div className="px-1 bg-white border border-gray-200 rounded-lg sm:flex-wrap md:flex-wrap lg:flex dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
              <div className="mr-1">
                <label
                  htmlFor="dateDebut"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Date début
                </label>
                <input
                  type="date"
                  id="dateDebut"
                  value={seachWithStartDateValue}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  // defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  onChange={handleChangeStartDateValue}
                />
              </div>
              <div className="mr-1">
                <label
                  htmlFor="dateFin"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Date fin
                </label>
                <input
                  type="date"
                  id="dateFin"
                  value={seachWithEndDateValue}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  // defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  onChange={handleChangeEndDtaeValue}
                />
              </div>
              <div className="flex items-center mt-5 h-14">
                <button
                  type="button"
                  className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  onClick={() => {
                    getFilteredByDate();
                  }}
                >
                  <FunnelIcon
                    className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                    aria-hidden="true"
                  />
                  Filtrer
                </button>
              </div>
            </div>
            <HasRoles userRole={["SUPERMERCHANT"]}>
              <div className="mx-2">
                <label
                  htmlFor="dateFin"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Exporter
                </label>
                <Menu as="div" className="relative flex-shrink-0">
                  <div>
                    <Menu.Button className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
                      <ArrowDownTrayIcon
                        className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-20 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {
                        <Menu.Item>
                          <>
                            <div
                              className={classNames(
                                "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                              )}
                            >
                              <CSVLink
                                headers={getExportCSVData.header}
                                data={getExportCSVData.data}
                              >
                                CSV
                              </CSVLink>
                            </div>
                            <hr className="h-px bg-gray-200 border-0 dark:bg-gray-200" />
                            <div
                              className={classNames(
                                "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                              )}
                              onClick={() => {
                                exportToPDFData();
                              }}
                            >
                              PDF
                            </div>
                            <hr className="h-px bg-gray-200 border-0 dark:bg-gray-200" />
                            <div
                              className={classNames(
                                "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                              )}
                              onClick={() => {
                                exportToXLSData();
                              }}
                            >
                              XLS
                            </div>
                          </>
                        </Menu.Item>
                      }
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </HasRoles>
          </div>
        </div>
        <div className="flex flex-col mt-8">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                <table
                  className="min-w-full border-separate"
                  style={{ borderSpacing: 0 }}
                >
                  <thead className="bg-gray-50">
                    <tr>
                      {tableHeader.map((th, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                        >
                          {th}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {transactions && transactions.length > 0 ? (
                      (paginatdlistData.length > 0
                        ? paginatdlistData
                        : transactions
                      ).map((transaction: any, transactionIdx: number) => (
                        <tr key={transactionIdx} className="cursor-pointer">
                          <td
                            className={classNames(
                              transactionIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {(transactionIdx as number) + 1}
                          </td>
                          <td
                            className={classNames(
                              transactionIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {participantId === transaction.data.senderID ? (
                              <img
                                className="w-10 h-10 p-1 border rounded-full"
                                src={
                                  transaction.data.receiverProfilImageUrl
                                    ? transaction.data.receiverProfilImageUrl
                                    : `https://ui-avatars.com/api/?background=random&name=${
                                        transaction.data.receiverDisplayName ||
                                        "S"
                                      }`
                                }
                                alt=""
                              />
                            ) : (
                              <img
                                className="w-10 h-10 p-1 border rounded-full lg:w-10"
                                src={
                                  transaction.data.senderProfilImageUrl
                                    ? transaction.data.senderProfilImageUrl
                                    : `https://ui-avatars.com/api/?background=random&name=${
                                        transaction.data.senderDisplayName ||
                                        "S"
                                      }`
                                }
                                alt=""
                              />
                            )}
                          </td>
                          <td
                            className={classNames(
                              transactionIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {participantId === transaction.data.senderID
                              ? transaction.data.receiverDisplayName
                                ? transaction.data.receiverDisplayName
                                : "-"
                              : transaction.data.senderDisplayName
                              ? transaction.data.senderDisplayName
                              : "-"}
                          </td>
                          <td
                            className={classNames(
                              transactionIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {participantId === transaction.data.senderID
                              ? (transaction.data.receiverPhoneNumber as string)
                              : (transaction.data.senderPhoneNumber as string)}
                          </td>
                          <td
                            className={classNames(
                              transactionIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {new Intl.NumberFormat().format(
                              transaction.data.amount
                            )}{" "}
                            Fcfa
                          </td>
                          <td
                            className={classNames(
                              transactionIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {transactionMap.get(
                              transaction.data.transactionType
                            )}
                          </td>
                          <td
                            className={classNames(
                              transactionIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {transaction.data.accountType === "MAIN" ? (
                              <img
                                className="w-auto h-5"
                                src={SankMoneyIcon}
                                alt="Sank"
                              />
                            ) : (
                              <BoltIcon className="w-6 h-6 text-red-500" />
                            )}
                          </td>
                          <td
                            className={classNames(
                              transactionIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {transaction.data.fees as string} Fcfa
                          </td>
                          <td
                            className={classNames(
                              transactionIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {transaction.data.isComplete ? (
                              <CheckCircleIcon className="w-6 h-6 text-green-500" />
                            ) : (
                              <XCircleIcon className="w-6 h-6 text-red-500" />
                            )}
                          </td>
                          <td
                            className={classNames(
                              transactionIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {(transaction.data.createdAt as Timestamp)
                              .toDate()
                              .toLocaleString()}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <NoContent />
                    )}
                  </tbody>
                </table>
                {transactions && transactions.length > 0 && (
                  <Pagination
                    skip={defaultLoadHistories}
                    take={defaultLoadHistories}
                    total={defaultLoadHistories}
                    collectionName={collectionName}
                    isChangedPage={false}
                    onPageChange={(pageIndex: number) => pageIndex * page}
                    onPageLimitChange={(pageLimit: number) =>
                      onPageLimitChange(pageLimit)
                    }
                    balance={transactionTotalSum}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuperMerchantsAllTransactions;
