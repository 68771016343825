import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts";
import { db } from "../../firebase";
import { UserNotificationInterface, UserRole } from "../../interfaces";
import FirestoreTable, {
  IColumn,
  IRow,
  RowRenderFunction,
} from "../../components/FirestoreTable";
import Moment from "react-moment";
import {
  agencyCollectionsName,
  companyCollectionName,
  gasStationCollectionName,
  gasStationMembershipCollectionName,
  notificationsCollectionName,
  usersCollectionName,
} from "../../config";

const collectionName = notificationsCollectionName;
const columns: IColumn[] = [
  {
    field: "createdAt",
    label: "Date de création",
  },
  {
    field: "title",
    label: "Titre",
  },
  {
    field: "message",
    label: "Message",
  },
];
const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "title":
      return <span>{value}</span>;
    case "message":
      return <span>{value}</span>;
    default:
      return value as string;
  }
};
const NotificationsPage = () => {
  const { user, userNotification, setUserNotification, agencyAgent, company } =
    useContext(UserContext);
  const [gasStationRelationId, setGasStationRelationId] = useState("");

  const readAllNotification = async () => {
    let collectionName: string;
    switch (user?.userRole) {
      case UserRole.USER:
        collectionName = usersCollectionName;
        break;
      case UserRole.GASSTATIONSTAFF:
        collectionName = gasStationCollectionName;

        break;
      case UserRole.AGENCYSTAFF:
        collectionName = agencyCollectionsName;

        break;
      case UserRole.COMPANYSTAFF:
        collectionName = companyCollectionName;

        break;

      default:
        collectionName = usersCollectionName;
        break;
    }
    const unReadedNotification = userNotification?.filter(
      (notify) => notify.isReaded === false
    );

    if ((unReadedNotification as UserNotificationInterface[])?.length > 0) {
      const gasStationId = await retriveGasStationId(user?.id as string);
      const id =
        user?.userRole === UserRole.AGENCYSTAFF
          ? agencyAgent?.id
          : user?.userRole === UserRole.GASSTATIONSTAFF
          ? gasStationId
          : user?.userRole === UserRole.COMPANYSTAFF
          ? company?.id
          : user?.id;
      console.log(id);
      console.log(collectionName);
      const notifcationRef = db
        .collection(collectionName)
        .doc(id)
        .collection(notificationsCollectionName);

      for (const notif of unReadedNotification as UserNotificationInterface[]) {
        await notifcationRef.doc(notif.id).update({ isReaded: true });
      }
      const notifications = await notifcationRef.get();
      if (!notifications.empty) {
        setUserNotification(notifications.docs.map((notif) => notif.data()));
      }
    }
  };

  const retriveGasStationId = async (userId: string) => {
    const membershipRef = db.collection(gasStationMembershipCollectionName);
    const membershipSnaphot = await membershipRef
      .where("memberId", "==", userId)
      .get();
    if (membershipSnaphot.empty) {
      return undefined;
    }
    const membershipData = membershipSnaphot.docs[0].data();
    if (membershipData) {
      setGasStationRelationId(membershipData?.gasStationId);
      return membershipData?.gasStationId as string;
    }
  };

  useEffect(() => {
    user && readAllNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="px-4 mt-10 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="py-2 text-xl font-semibold text-gray-900">
              Mes notifications
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Vous trouverez ici l'ensemble de vos notifications.
            </p>
          </div>
        </div>
        <div className="flex flex-col mt-8">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                <FirestoreTable
                  collectionRef={db
                    .collection(
                      gasStationRelationId
                        ? gasStationCollectionName
                        : company
                        ? companyCollectionName
                        : usersCollectionName
                    )
                    .doc(
                      agencyAgent
                        ? agencyAgent?.id
                        : gasStationRelationId
                        ? gasStationRelationId
                        : company
                        ? company.id
                        : user?.id
                    )
                    .collection(collectionName)}
                  condensed
                  columns={columns}
                  rowRender={renderRow}
                  paginate={{
                    pageIndex: 0,
                    limit: 100,
                    sort: "createdAt:desc",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationsPage;
